export default () => [{
  id: 1,
  name: 'Luis Sanchez',
  address: '154 SW Alvi St APT 114',
  city: 'Miami Beach',
  lastOpened: '2 days',
},
{
  id: 2,
  name: 'Maria de los Angeles Hurtado',
  address: '1635 Neil Armstrong St',
  city: 'Hialeh Gardens',
  lastOpened: '10 days',
},
{
  id: 3,
  name: 'Pancrasio Conchincho',
  address: '4780 Temple Blvd - SUITE 154',
  city: 'Doral',
  lastOpened: '152 days',
}]
