import axios from 'axios';

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/address';

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}


class Pet {

    fetchAddreses() {
        return axios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchAddresesByCustomer(cust) {
        return axios.get(USER_API_BASE_URL+'/listAll/'+cust);
    }

    fetchAddressById(cityId) {
        return axios.get(USER_API_BASE_URL + '/' + cityId);
    }

    deleteAddress(cityId) {
        return axios.put(USER_API_BASE_URL + '/update',cityId);
    }

    addAddress(city) {
        return axios.post(USER_API_BASE_URL+'/add', city, config);
    }

    editAddress(city) {
        return axios.put(USER_API_BASE_URL + '/update', city);
    }


}

export default new Pet();