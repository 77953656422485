import React, { Component } from 'react'
import ApiBranch from "../../api/Branch";
import ListCombo from  "../county/ListCombo"
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect  } from "shards-react";


class EditBranchComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            name : '',
            description : '',

        }
        this.saveBranch = this.saveBranch.bind(this);
        this.loadBranch = this.loadBranch.bind(this);
    }

    componentDidMount() {
        this.loadBranch();

    }

    
    

    loadBranch() {
        
        ApiBranch.fetchBranchById(window.localStorage.getItem("BranchId"))
            .then((res) => {
                let branch = res.data.result;
                console.log("Branch TO EDIT " , res);
                this.setState({
                    id: branch.id,
                    name : branch.name,
                    description : branch.description,         
                })
            });

            console.log("Branch STATE : " , this.state )
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    saveBranch = (e) => {
        e.preventDefault();
        let branch = {id : this.state.id , name: this.state.name, description : this.state.description};
        ApiBranch.editBranch(branch)
            .then(res => {
                  this.props.history.push('/branch');
            });


    }

    cancelAdd() {
        this.props.history.push('/branch');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit Branch</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Branch Name</label>
                                    <FormInput
                                    id="txtName"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="6" className="form-group">
                                    <label htmlFor="txtZipCode">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder=""
                                    value={this.state.description} onChange={this.onChange}
                                    />
                                </Col> 
                          
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveBranch}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditBranchComponent;