import React, { Component } from 'react'
import Zone from "../../api/Zone";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormFeedback } from "shards-react";

class AddZoneComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
            description: '',
            description_fail : false,
            location: {}
        }
        this.saveZone = this.saveZone.bind(this);
    }

    saveZone = (e) => {
        e.preventDefault();
        let zone = {description: this.state.description, state_id: 1};
        let valid = true

        if (this.state.description === ''){
            valid = false
            this.setState({description_fail  : true})
        }

        if (valid){

            Zone.addZone(zone)
                .then(res => {
                    this.setState({message : 'Zone Added successfully.'});
                    this.props.history.push('/zones');
                });

        }    
    }

    cancelAdd() {
        this.props.history.push('/zones');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ [e.target.name + "_fail"]: false });
    }    

    render() {
        return(
            <div>
                <h6 className="text-center">New Zone</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder="Zone Description"
                                    value={this.state.description} onChange={this.onChange}
                                    invalid = {this.state.description_fail}
                                    />
                                    <FormFeedback>Please, Fill this Field</FormFeedback>
                                </Col>

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveZone}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddZoneComponent;