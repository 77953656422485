import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PageTitle from "../components/common/PageTitle";
import ListCustomerComponent from "../components/customer/ListCustomerComponent";
import AppStep1Component from "../components/appoiment/AppStep1Component";
import AppStep2Component from "../components/appoiment/AppStep2Component";
import AppStep3Component from "../components/appoiment/AppStep3Component";
import EditProfileClientComponent from "../components/customer/EditProfileClientComponent";

const ListCustomerView = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="List Customers" subtitle="Details" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}
      <Col>
        
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Details</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <Router>
                    <Switch>
                        
                         <Route path="/list-customers" component={ListCustomerComponent} />
                         <Route path="/appoiment/step1" component={AppStep1Component} />
                         <Route path="/appoiment/step2" component={AppStep2Component} />
                         <Route path="/appoiment/step3" component={AppStep3Component} />
                         <Route path="/edit-user-profile" component={EditProfileClientComponent} />
                         
                  
                        
    
                    </Switch>

            </Router>
            
          </CardBody>
        </Card>


      </Col>

      {/* Sidebar Widgets */}

    </Row>
  </Container>
);

export default ListCustomerView;