import React, { Component } from 'react'
import ApiTruck from "../../api/Truck";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect} from "shards-react";
import ApiBranch from "../../api/Branch";
import getTruckTypes from "../../data/tableValues";

class AddTruckComponent extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            id : '',
            name : '',
            description : '',
            branches : [],
            branchId : '',
            errors : {name : false, dob_blank : false, dob_g : false, h : false, w : false, Truck_type : false, race : false}, 
            
        }
        this.saveTruck = this.saveTruck.bind(this);
        this.loadTruckTypes = this.loadTruckTypes.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {
        this.loadTruckTypes();
        this.setState({customer_id : sessionStorage.getItem("idCustomer")})
        this.branchList();
        
    } 

    loadTruckTypes() {
        const types = getTruckTypes();
        this.state.TruckTypes = types;

    }
   

    branchList() {
        ApiBranch.fetchBranchs()
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({branches: res.data.result});

            });
    } 
    

    saveTruck = (e) => {

        e.preventDefault();
        let d = new Date().getDate();
        let formIsValid = true; 
        let name_f = this.state.name;
        let description_f = this.state.description;
        let branch_f = this.state.branchId;

        if (typeof name_f === "undefined"){
            formIsValid = false;
            this.setState.errors({name : true})
        }  
        
        if (typeof description_f === "undefined"){
            formIsValid = false;
            this.setState.errors({description : true})
        }    

        if (typeof branch_f === "undefined"){
            formIsValid = false;
            this.setState.errors({branchId : true})
        }    

    if (formIsValid){
                
            let Truck = {name: this.state.name, description : this.state.description, branch_id : this.state.branchId };
            console.log("Truck TO SAVE : " ,  Truck);
            ApiTruck.addTruck(Truck)
                .then(res => {
                    
                    this.setState({message : 'Truck Added successfully.'});
                    this.props.history.push('/truck');
                });

        }    
    }

    cancelAdd() {
        this.props.history.push('/truck');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });


    }   

    render() {
        return(
            <div>
                <h6 className="text-center">Add New Truck</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Truck Name</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                </Col>
                                <Col md="6" className="form-group">
                                    <label htmlFor="txtZipCode">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder=""
                                    value={this.state.description} onChange={this.onChange}
                                    />
                                </Col> 
                                <Col md="9" className="form-group">
                                   <label htmlFor="cboRace">Select Branch</label>
                                   <FormSelect value={this.state.branchId} name="branchId" onChange={this.onChange}>
                                        <option key="xx" value=""></option>
                                        {
                                            this.state.branches.map(branch =>
                                                <option key={branch.id} value={branch.id} >{branch.name}</option>
                                                )        
                                        }
                                </FormSelect>  
                            
                                </Col>

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveTruck}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddTruckComponent;