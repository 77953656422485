import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}


class Authentication {

    logout() {
        return connAxios.post(USER_API_BASE_URL+'/logout');
    }
  
}

export default new Authentication();