import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/branch';

let config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      }
}


class Branch {

    fetchBranchs() {
        return connAxios.get(USER_API_BASE_URL+'/listAll');
    }


    fetchBranchById(id) {
        return connAxios.get(USER_API_BASE_URL + '/' + id);
    }

    deleteBranch(id) {
        return connAxios.put(USER_API_BASE_URL + '/update', id);
    }

    addBranch(branch) {
        return connAxios.post(USER_API_BASE_URL+'/add', branch);
    }

    editBranch(branch) {
        return connAxios.put(USER_API_BASE_URL + '/update', branch);
    }


}

export default new Branch();