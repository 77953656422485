/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import RegisterComponent from "../components/register/RegisterComponent";
import VerifyAccountComponent from "../components/register/VerifyAccountComponent";
import LoginComponent from "../components/register/LoginComponent";
import LogoutComponent from "../components/register/LogoutComponent";
import AppStep1Component from "../components/appoiment/AppStep1Component";
import AuthenticateRoute from  "../components/authentication/AuthenticateRoute.jsx";

import {Row, Col} from "shards-react";
import {
  Container
} from "shards-react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

const Register = () => (
  <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
           <Col lg="12" md="5" className="auth-form mx-auto my-auto">
             <LoginComponent />
            </Col> 
      </Row>
  </Container>
);

export default Register;
