export default () => [
  {
    id: 'default',
    name: ' '
  },

  {
    id: 'dog',
    name: 'dog'
  },

  {
    id: 'cat',
    name: 'cat'
  },

  {
    id: 'other',
    name: 'other'
  },

  
  
];
