import React, { Component } from 'react'
import Script from 'react-load-script';
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormGroup,FormTextarea} from "shards-react";
import ApiAddress from "../../api/Address";

class AddAddressComponent extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            search_address: '',
            street_address: '',
            city: '',
            state: '',
            zip_code: '',
            customer_id : '',
            AddressRaces : [],
            notes : '',
            aptsuite : '',
            name : ''
        }
        this.saveAddress = this.saveAddress.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {

    } 


    saveAddress = (e) => {
        e.preventDefault();
        let add = {
            address: this.state.search_address,
            street: this.state.street_address,
            city: this.state.city,
            state: this.state.state,
            zip_code: this.state.zip_code,  
            aptsuite : this.state.aptsuite,
            customer_id : this.state.customer_id,
            address_name : this.state.name        
        }
        ApiAddress.addAddress(add)
            .then(res => {
                
                this.setState({message : 'Address Added successfully.'});
                this.props.history.push('/Addresss');
            });
    }

    
    handleScriptLoad = () => { 
        // Declare Options For Autocomplete 
        var options = { types: '(address)' , administrative_area_level_1 : '(FL)' }; 
        
        // Initialize Google Autocomplete 
        /*global google*/
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'),options );
        // Avoid paying for data that you don't need by restricting the 
        // set of place fields that are returned to just the address
        // components and formatted address
        this.autocomplete.setFields(['address_components',   
                                     'formatted_address']);
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed',
                                      this.handlePlaceSelect); 
        
        console.log('SCRIPT LOADED');                              
        }


    handlePlaceSelect = () => {

        // Extract City From Address Object
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;

        // Check if address is valid
        if (address) {
        console.log('ADDRESS SELECT  : ' , address )  
        // Set State
        let postal = null;
        if (address[6].types[0] === 'postal_code') {
                postal =  address[6].short_name
        }

        if (address[7].types[0] === 'postal_code') {
                postal =  address[7].short_name
        }

        this.setState({
            search_address: address[0].long_name + ' ' + address[1].long_name +  '  ' +  address[3].long_name + ', ' +  address[5].short_name + ' ' + postal ,
            street_address: address[0].long_name + ' ' + address[1].long_name,
            city: address[3].long_name,
            state: address[5].short_name,
            zip_code: postal 
            
        });
        }
    }

    cancelAdd() {
        this.props.history.push('/customer/Addresss');
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    render() {
        return(
            <div>
                <Script url="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDbrusHU90vO41gh6AJiiFb4LNxzpPQDJk" onLoad={this.handleScriptLoad} />
                <h6 className="text-center">Add New Address</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                
                                <FormGroup>
                                <input id="autocomplete" 
                                    placeholder="Search Address" 
                                    className="form-control" 
                                    name = "search_address"
                                    value={this.state.search_address}  
                                    onChange={this.onChange}
                                />

                                 </FormGroup>
                                <Row form>
                                    <Col md="10" className="form-group">
                                    <label htmlFor="stName">Street Name</label>
                                        <FormInput
                                        id="stName"
                                        placeholder="Street Name"
                                        name = "street_address"
                                        value = {this.state.street_address}
                                        disabled
                                        onChange={this.onChange}
                                        />  
                                    </Col> 
                                    <Col md="2" className="form-group">
                                    <label htmlFor="aptsuite">Apt / Suite</label>
                                        <FormInput
                                        id="sptsuite"
                                        placeholder="Apt / Suite"
                                        value = {this.state.aptsuite}
                                        name = "aptsuite"
                                        onChange={this.onChange}
                                        />  
                                    </Col>                                                                              
                                </Row>
                                <Row form>
                                    {/* City */}
                                    <Col md="6" className="form-group">
                                    <label htmlFor="feCity">City</label>
                                    <FormInput
                                        id="feCity"
                                        placeholder="City"
                                        name = "city"
                                        value = {this.state.city}
                                        onChange={this.onChange}
                                        disabled
                                    />
                                    </Col>
                                    {/* State */}
                                    <Col md="4" className="form-group">
                                    <label htmlFor="feInputState">State</label>
                                    <FormInput
                                        id="feState"
                                        placeholder="State"
                                        name = "state"
                                        value = {this.state.state}
                                        onChange={this.onChange}
                                        disabled
                                    />  
                                    </Col>
                                    {/* Zip Code */}
                                    <Col md="2" className="form-group">
                                    <label htmlFor="feZipCode">Zip</label>
                                    <FormInput
                                        id="feZipCode"
                                        name = "zip_code"
                                        placeholder="Zip"
                                        value = {this.state.zip_code}
                                        disabled
                                        onChange={this.onChange}
                                    />
                                    </Col>
                                    <Col md="9" className="form-group">
                                    <label htmlFor="feCity">Name</label>
                                    <FormInput
                                        id="feCity"
                                        placeholder="Addres Name , Ex : Home, Office.."
                                        name = "name"
                                        value = {this.state.name}
                                        onChange={this.onChange}
                                        enabled
                                    />
                                    </Col>

                                    {/* User Bio */}
                                        <Col md="9" className="form-group">
                                            <label htmlFor="userBio">Notes</label>
                                            <FormTextarea
                                            style={{ minHeight: "87px" }}
                                            id="userBio"
                                            onChange={() => {}}
                                            />
                                        </Col>                                                              

                             
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveAddress}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddAddressComponent;