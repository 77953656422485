import React, { Component } from 'react'
import ApiService from "../../api/Service";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form } from "shards-react";

class EditserviceComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            id : '',
            descService     : '',
            name : '',
            location_id: '',
            baseCost : ''
        }
        this.saveservice = this.saveService.bind(this);
        this.loadservice = this.loadService.bind(this);
    }

    componentDidMount() {
        this.loadService();
    }

    loadService() {
        ApiService.fetchServiceById(window.localStorage.getItem("serviceId"))
            .then((res) => {
                let service = res.data.result;
                console.log("service TO EDIT " , res);
                this.setState({
                id: service.id,
                name : service.name,
                descService: service.name,
                baseCost : service.baseCost
                })
            });
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    saveService = (e) => {
        e.preventDefault();
        let service = {id : this.state.id, name: this.state.name, description: this.state.description , base_cost : this.state.baseCost };
        console.log('SERVICE TO SAVE :',service);
        ApiService.editService(service)
            .then(res => {
                 
                  this.props.history.push('/services');
            });


    }

    cancelAdd() {
        this.props.history.push('/services');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit Service</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "descService"
                                    placeholder="Service Description"
                                    value={this.state.descService} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="6" className="form-group">
                                    <label htmlFor="feEmailAddress">Base Cost</label>
                                    <FormInput
                                    id="txtCost"
                                    type="number"
                                    name = "baseCost"
                                    placeholder="Base Cost"
                                    value={this.state.baseCost} onChange={this.onChange}
                                    />
                                </Col>

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveService}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditserviceComponent;