import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form } from "shards-react";
import ApiAddress from "../../api/Address";

class ListAddressComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            addressList: [],
            message: null,
            modalDelete : false,
            delAddress : {},
            name: '',
            street_address: '',
            city: '',
            state: '',
            zip_code: ''
        }
        this.delete = this.deleteAddress.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.addAddress = this.addAddress.bind(this);
        this.reloadAddressList = this.reloadAddressList.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    componentDidMount() {
        this.reloadAddressList();
    }



    reloadAddressList(cust) {
        ApiAddress.fetchAddresesByCustomer(cust)
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({addressList: res.data.result});

            });
    }

    loadAddress(Addressid) {
        this.setState({modalDelete : !this.state.modalDelete});
        ApiAddress.fetchAddressById(Addressid)
            .then((res) => {
                let Address = res.data.result;
                console.log("Address TO EDIT " , res);
                this.setState({delAddress : Address})
            });

            console.log("Address STATE : " , this.state )
    }    


    deleteAddress() {
                                                                   
        let dAddress = {id : this.state.delAddress.id, name: this.state.delAddress.name, 
            street_address : this.state.delAddress.street_address,city : this.state.delAddress.city, 
            state : this.state.delAddress.state, zip_code : this.state.delAddress.zip_code,
            customer_id : this.state.delAddress.customer_id, status : 'INACTIVE'};
        ApiAddress.deleteAddress(dAddress)
           .then(res => {
               this.setState({message : 'Address deleted successfully.'});
               this.setState({addressList: this.state.addressList.filter(Address => Address.id !== dAddress.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    }    

    editAddress(id) {
        window.localStorage.setItem("AddressId", id);
        this.props.history.push('/customer/edit-address');
    }

    addAddress() {
        window.localStorage.removeItem("AddressId");
        this.props.history.push('/customer/add-address');
    }

    render() {
        const deleteMd = this.state.modalDelete;
        //console.log('Size cities : ' + this.state.cities.length);
        return (
            <div>

                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deleteAddress()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div> 



              <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addAddress()}>
                    <i className="material-icons">add</i> New Address
                    </Button>
              </Col> 


              <div>
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">Name</th>
                            <th scope="col" className="border-0">Street</th>
                            <th scope="col" className="border-0">Apt/Suite</th>
                            <th scope="col" className="border-0">City</th>
                            <th scope="col" className="border-0">State</th>
                            <th scope="col" className="border-0">Zip_code</th>
                            <th scope="col" className="border-0">Action</th>
                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        {
                            this.state.addressList.map(
                        Address =>
                                    <tr key={Address.id}>
                                        <td>{Address.name}</td>
                                        <td>{Address.street}</td>
                                        <td>{Address.aptsuite}</td>
                                        <td>{Address.city}</td>
                                        <td>{Address.state}</td>
                                        <td>{Address.zip_code}</td>
                                         <td>
                                            
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.loadAddress(Address.id)}>
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>                                            
                                            
                                            <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editAddress(Address.id)}>
                                                <i className="material-icons">&#xE254;</i>
                                            </Button>                                           
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                  
                </table>
                </div>

            </div>
        );
    }

}

export default ListAddressComponent;