import React, { Component } from 'react'
import { Button, Col } from "shards-react";
import ApiEmployee from "../../api/Employee";

class ListEmpComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            employees: [],
            typeEmployees: [],
            message: null,
            empty : true
            
        }
        this.deleteEmployee = this.deleteEmployee.bind(this);
        this.editEmployee = this.editEmployee.bind(this);
        this.addEmployee = this.addEmployee.bind(this);
        this.reloadEmployeeList = this.reloadEmployeeList.bind(this);
    }

    componentDidMount() {
        this.reloadEmployeeList();

    }



    reloadEmployeeList() {
    
        ApiEmployee.fetchEmployees()
            .then((res) => {
                console.log('Size REsult : ', res);
                this.setState({employees: res.data.result});
                 

            });
    }

  


    deleteEmployee(employeeId) {
        ApiEmployee.deleteemployee(employeeId)
           .then(res => {
               this.setState({message : 'employee deleted successfully.'});
               this.setState({employees: this.state.employees.filter(employee => employee.id !== employeeId)});
           })

    }

    editEmployee(id) {
        window.localStorage.setItem("employeeId", id);
        this.props.history.push('/edit-employee');
    }

    addEmployee() {
        window.localStorage.removeItem("employeeId");
        this.props.history.push('/add-employee');
    }

    render() {
        //console.log('Size employees : ' + this.state.employees.length);
        const vempty = this.state.empty;
        return (
            <div>
                
                <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addEmployee()}>
                    <i className="material-icons">add</i> New Employee
                    </Button>
                </Col> 


            {vempty &&

            <div>

                
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">Sure Name</th>
                            <th scope="col" className="border-0">Last Name</th>
                            <th scope="col" className="border-0">Admission Date</th>
                            <th scope="col" className="border-0">Employee Type</th>
                            <th scope="col" className="border-0">Action</th>
                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        {
                            this.state.employees.map(
                        employee =>
                                    <tr key={employee.id}>
                                        <td>{employee.sureName}</td>
                                        <td>{employee.lastName}</td>
                                        <td>{employee.admissionDate}</td>
                                        <td>{employee.employeeType.description}</td>
                                         <td>
                                            
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.deleteEmployee(employee.id)}>
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>                                            
                                            
                                            <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editEmployee(employee.id)}>
                                                <i className="material-icons">&#xE254;</i>
                                            </Button>                                           
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                  
                </table>
            </div>   

            }

            </div>
        );
    }

}

export default ListEmpComponent;