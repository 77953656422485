import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/product';

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}

class Service {

    fetchServices() {
        return connAxios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchServicesbyAppoiment(idApp) {
        return connAxios.get(USER_API_BASE_URL+'/listAll' + idApp);
    }    

    findOneServiceAppoiment(idServiceApp) {
        return connAxios.get(USER_API_BASE_URL+'/findOneServiceApp' + idServiceApp);
    }        

    fetchAvialablebyAppoiment(appId){
        return connAxios.get(USER_API_BASE_URL+'/listAll/'+ appId );
    }

    fetchServiceById(serviceId) {
        return connAxios.get(USER_API_BASE_URL + '/' + serviceId);
    }

    deleteService(service) {
        return connAxios.put(USER_API_BASE_URL + '/update', service);
    }

    deleteServieAppoiment(ServiceApp){
        return connAxios.delete(USER_API_BASE_URL + '/delete', ServiceApp);
    }

    addService(service) {
        return connAxios.post(USER_API_BASE_URL+'/add', service, config);
    }

    editService(service) {
        return connAxios.put(USER_API_BASE_URL + '/update', service);
    }

}

export default new Service();