import React, { Component } from 'react'
import { Button, Col, Row, FormInput, Form, Card,CardBody,CardFooter,FormGroup,FormCheckbox,FormFeedback } from "shards-react";
import { Link, Redirect } from "react-router-dom";
import AuthenticationService from "../authentication/AuthenticationService";

class LoginComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
            description: '',
            redirect : false,
            email : '',
            password : '',
            showSuccessMessage: true,
            hasLoginFailed : false

          
        }
        this.registerAccount = this.registerAccount.bind(this);
    }

    componentDidMount() {
        AuthenticationService.logout();
    } 

    setRedirect = () => {
        this.setState({
          redirect: true
        })
      }


    registerAccount = () => {
        if (this.state.redirect) {
            //return <Redirect to="/customer-client-profile" />
            return <Redirect to="/appoiment/step1" />
        }    
        //this.props.history.push('/customer-client-profile');
    }

    loginClicked() {
        console.log('USER : ' , this.state.email );
        console.log('PASSWORD', this.state.password);
        AuthenticationService.executeBasicAuthenticationService(this.state.email, this.state.password)
            .then((res) => {
                AuthenticationService.registerSuccessfulLogin(this.state.email, this.state.password, res)
               this.props.history.push('/appoiment/step1');
               console.log('Login Sucess : ' , res)
               
            /*    this.setState({
                    redirect: true
                  })     */
               // this.registerAccount();         
            }).catch(() => {
                this.setState({ showSuccessMessage: false })
                this.setState({ hasLoginFailed: true })
                console.log('Login Error');
            })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ hasLoginFailed: false });
    }    

    render() {
        const nview = this.state.redirect
        return(
            <div>

            
           

             {!nview && 
            <div>
            <Row noGutters className="h-100">
            <Col lg="3" md="5" className="auth-form mx-auto my-auto">
                <Card>
                <CardBody>
                    {/* Logo */}
                    <img
                    className="auth-form__logo d-table mx-auto mb-3"
                    src = {require("./../../images/Logo.jpeg")}
                    alt="Pet Care App - Login"
                    />

                    {/* Title */}
                    <h5 className="auth-form__title text-center mb-4">
                    Access Your Account
                    </h5>

                    {/* Form Fields */}
                    <Form>
                    <FormGroup>
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <FormInput
                        type="email"
                        id="exampleInputEmail1"
                        placeholder="Enter email"
                        name = "email"
                        value = {this.state.email} onChange={this.onChange}
                        invalid = {this.state.hasLoginFailed}
                        autoComplete="email"
                        />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <FormInput
                        type="password"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        name = "password"
                        value = {this.state.password} onChange={this.onChange}
                        invalid = {this.state.hasLoginFailed}
                        autoComplete="current-password"
                        />
                        <FormFeedback>Credentials are incorrect, please be sure you are entering the accurate login</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <FormCheckbox>Remember me for 30 days.</FormCheckbox>
                    </FormGroup>
                    <Button
                        pill
                        theme="accent"
                        className="d-table mx-auto"
                        type="button"
                        onClick={() => this.loginClicked()}
                    >
                        Access Account
                    </Button>
                    </Form>
                </CardBody>

                {/* Social Icons */}
                <CardFooter>
                    <ul className="auth-form__social-icons d-table mx-auto">
                    <li>
                        <a href="#">
                        <i className="fab fa-facebook-f" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                        <i className="fab fa-twitter" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                        <i className="fab fa-github" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                        <i className="fab fa-google-plus-g" />
                        </a>
                    </li>
                    </ul>
                </CardFooter>
                </Card>

                {/* Meta Details */}
                <div className="auth-form__meta d-flex mt-4">
                <Link to="/forgot-password">Forgot your password?</Link>
                <Link to="/register" className="ml-auto">
                    Create a new account?
                </Link>
                </div>
            </Col>
            </Row>

            
            </div>
            }
        </div>   
           
        );
    }
}

export default LoginComponent;