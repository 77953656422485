import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PageTitle from "../components/common/PageTitle";
import ListCityComponent from "../components/city/ListCityComponent";
import AddCityComponent from "../components/city/AddCityComponent";
import EditCityComponent from "../components/city/EditCityComponent";

const CityView = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Cities" subtitle="City details" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}
      <Col>
        
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Details</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <Router>
                    <Switch>
                        
                          <Route path="/cities" component={ListCityComponent} />
                          <Route path="/add-city" component={AddCityComponent} />
                          <Route path="/edit-city" component={EditCityComponent} />
                          
                        
    
                    </Switch>

            </Router>
          </CardBody>
        </Card>


      </Col>

      {/* Sidebar Widgets */}

    </Row>
  </Container>
);

export default CityView;