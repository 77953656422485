export default () => [{
    id: 0,
    title: '(3 Spaces) 7:30 AM - 9:00 AM',
    start: new Date(2020, 1, 19,7,30,0),
    end: new Date(2020, 1, 19,9,0,0),
  },

  {
    id: 0,
    title: '(1 Space) 9:00 AM - 10:30 AM',
    start: new Date(2019, 9, 10,9,0,0),
    end: new Date(2019, 9, 10,10,30,0),
  },

  {
    id: 0,
    title: '2 Spaces Available ',
    start: new Date(2019, 9, 10,10,30,0),
    end: new Date(2019, 9, 10,12,0,0),
  },

  {
    id: 0,
    title: '2 Spaces Available',
    start: new Date(2019, 9, 10,13,0,0),
    end: new Date(2019, 9, 10,14,30,0),
  },

  {
    id: 5,
    title: 'Yuser Spacee',
    start: new Date(2019, 9, 13),
    end: new Date(2019, 9, 13),
    desc: 'Just for Yuserman Race',
  },
  {
    id: 8,
    title: '1 Space Available',
    start: new Date(2019, 9, 12, 14, 14, 0, 0),
    end: new Date(2019, 9, 12, 16, 16, 0, 0),
  },
  {
    id: 14,
    title: '1 Space Available',
    start: new Date(new Date().setHours(new Date().getHours() - 1)),
    end: new Date(new Date().setHours(new Date().getHours() + 1)),
  },
];
