import React, { Component } from 'react'
import ApiZone from "../../api/Zone";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormFeedback  } from "shards-react";

class EditZoneComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            id : '',
            description: '',
            description_fail : false,
            location_id : '',
            location: {}
        }
        this.saveZone = this.saveZone.bind(this);
        this.loadZone = this.loadZone.bind(this);
    }

    componentDidMount() {
        this.loadZone();
    }

    loadZone() {
        ApiZone.fetchZoneById(window.localStorage.getItem("zoneId"))
            .then((res) => {
                let zone = res.data.result;
                console.log("ZONE TO EDIT " , res);
                this.setState({
                id: zone.id,
                description: zone.description
                })
            });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ [e.target.name + "_fail"]: false });
    }    

    saveZone = (e) => {
        e.preventDefault();
        let zone = {id : this.state.id, description: this.state.description, location_id: this.state.location_id};
        let valid = true

        if (this.state.description === ''){
            valid = false
            this.setState({description_fail  : true})
        }

        if (valid){

            ApiZone.editZone(zone)
            .then(res => {
                  this.props.history.push('/zones');
            });
        }



    }

    cancelAdd() {
        this.props.history.push('/zones');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit Zone</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="txtDescription">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder="Zone Description"
                                    invalid = {this.state.description_fail}
                                    value={this.state.description} onChange={this.onChange}
                                    />
                                    <FormFeedback>Please, Fill this Field</FormFeedback>
                                </Col>

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveZone}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditZoneComponent;