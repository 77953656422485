import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import ProfileClientComponent from "../components/customer/ProfileClientComponent";

const CreateCustomerView = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Create Customer" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <Row>

      <Col lg="12" md={{ size: 4, offset: 1 }}>
        <ProfileClientComponent />
      </Col>
    </Row>
  </Container>
);

export default CreateCustomerView;
