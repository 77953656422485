import React, { Component } from 'react'
import Pet from "../../api/Pet";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect,Card,CardHeader,FormTextarea,CardBody,referralData,CardFooter, Modal, ModalHeader, ModalFooter ,ModalBody} from "shards-react";
import ApiRes from "../../api/Reservation";
import ApiPet from "../../api/Pet";
import ApiService from "../../api/Service"; 
import ApiTruck from "../../api/Truck"; 
import ApiBranch from "../../api/Branch"; 
import getDataServices from "../../data/servicesAppData";
import ApiEmployee from "../../api/Employee"; 
import ApiCustomer from "../../api/Customer"
import ApiZone from "../../api/Zone";
import { set } from 'react-ga';

class EditAppComponent extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            address : '',
            blockDesc : '',
            date : '',
            name : '',
            date_of_birth : '',
            race_id : '',
            customer_id : '',
            servicesAv : [],
            pets : [],
            modalService : false,
            pet_id : '',
            trucks : [],
            branchs : [],
            brach_id : '',
            truck_id : '',
            employee_id : '',
            employees : [],
            modalDelService : false,
            serviceAppDel : {},
            currReserv : {},
            selectservices : [],
            services_zone : [],
            reserv_id : '',
            service_id : ''

        }

        this.savePet = this.savePet.bind(this);
        this.getAvalilabitlity = this.getAvalilabitlity.bind(this);
        this.appoimentDetails = this.appoimentDetails.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {
        //

        console.log("Appoiment Id :" , window.localStorage.getItem("appoiment_id"));
    
        this.loadBranchList();
        this.loadTruckList(1);
        this.loadEmployeesList();
        this.appoimentDetails(window.localStorage.getItem("appoiment_id"));
        //this.servicesAvList(window.localStorage.getItem("appoiment_id"));
        
        

   
        console.log("STATE ACTUAL : " , this.state );



    } 

    componentWillMount() {



    }  

    appoimentDetails(id){
    
        ApiRes.findOnebyid(id)
        .then((res) => {
            console.log('Reservatio Found: ',res);
            let app = res.data.result;
            this.setState({currReserv: app});   
            this.setState({date : app.appointmentDate});
            this.setState({blockDesc : app.customer.sureName + " " + app.customer.lastName});
            this.setState({address : app.customerAddress.address}) 
            this.setState({pet_id : app.pet.id});
            this.loadServicesAppoiment(app.id);
            this.loadpetsbyCustomer(app.customer.id);
            this.setState({reserv_id : app.id });
            this.loadServicesbyZone(2);
            
      
        });

        
    }

    loadServicesbyZone(zone_id) {
            
        ApiZone.fetchServicesByZone(zone_id)
         .then((res) => {
             console.log('Size SERVICES ZONE RESULT : ',res);
             this.setState({services_zone: res.data.result});

         });
    }      

    loadServicesAppoiment(idApp) {
        ApiRes.fecthServicesAppoiment(idApp)
            .then((res) => {
                console.log('Services Appoiment ################ : ',res);
                this.setState({selectservices: res.data.result});

            });
    } 
        


    loadEmployeesList(){


        ApiEmployee.fecthEmployeeByType('PELUQUERO')
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({employees: res.data.result});

        });

    }

    loadBranchList() {

        ApiBranch.fetchBranchs()
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({branchs: res.data.result});

        });
    }




    loadTruckList(idBranch) {

        ApiTruck.fetchTruckByBranch(idBranch)
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({trucks: res.data.result});

        });
    }

    loadpetsbyCustomer(customerId) {
        ApiCustomer.fecthPetsByCustomer(customerId)
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({pets: res.data.result});

        });



    }

    loadOneServiceAppoiment(idServiceApp){
        this.setState({modalDelService : !this.state.modalDelService});
        this.setState({serviceAppDel: idServiceApp});
       
       /* ApiService.findOneServiceAppoiment(idServiceApp)
            .then((res) => {
                //console.log('Size REsult : ',res);
                
                this.setState({serviceAppDel: res.data.result});

            }); */
    }

    processDeleteService() {
        
       //let serviceDel = {id : this.state.serviceAppDel.id, Status : 'INACTIVE'}
        ApiRes.deleteServiceReservation(this.state.reserv_id, this.state.serviceAppDel )
        .then((res) => {
            //console.log('Size REsult : ',res);
            this.loadServicesAppoiment(this.state.reserv_id);

        });

    }
   

    loadServicesbyZone(zone_id) {
            
        ApiZone.fetchServicesByZone(zone_id)
         .then((res) => {
             console.log('Size SERVICES ZONE RESULT : ',res);
             this.setState({servicesAv: res.data.result});

         });
    } 


    
    addServiceList() {

        let id_product = this.state.service_id;


        
        
        if ((this.state.service_id !== '') && (this.state.service_id !== 'XX')) {



    
            const addArr = this.state.servicesAv;
            //console.log('ARR_VALUE ===> ', addArr);

            for (const [index, value] of addArr.entries()) {
                
    
                if (value.product.id == id_product){
                    //console.log("I Found It ********* " , value )
                    //this.setState({product_price : value.baseCost})

                    ApiRes.addServiceReservation(this.state.reserv_id, id_product )
                    .then((res) => {
                        //console.log('Size REsult : ',res);
                        this.loadServicesAppoiment(this.state.reserv_id);
        
                    });


                    this.setState(prevState => ({
                        selectservices: [...prevState.selectservices,value]
                    }))

                   // this.setState({modalService : !this.state.modalService});
                   this.setState({service_id: ''});
                   this.setState({product_price : ''});


                    
                }
            }

        }

    }
    

    savePet = (e) => {
        e.preventDefault();
        let pet = {name: this.state.name, date_of_birth : this.state.date_of_birth, height : this.state.height, weight : this.state.weight, race_id : this.state.race_id, customer_id : this.state.customer_id };
        Pet.addPet(pet)
            .then(res => {
                
                this.setState({message : 'Pet Added successfully.'});
                this.props.history.push('/pets');
            });
    }

    cancelAddService(){
        this.setState({modalService : !this.state.modalService});
    }

    cancelDelService(){
        this.setState({modalDelService : !this.state.modalDelService});
    }

    loadServiceAvailable() {

        this.setState({modalService : !this.state.modalService});

    }

    getAvalilabitlity() {
        this.props.history.push('/appoiment/step2');
    }

    cancelAdd() {
        this.props.history.push('/customer/pets');
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    render() {
        const referralData = getDataServices();
        const vService =  this.state.modalService;
        const vShowDelService =  this.state.modalDelService;
        return(

           

            <div>
                

                {/********************************************** * *****************  ****************************************************/}
                {/********************************************** * MODAL ADD SERVICE  ****************************************************/}
                {/********************************************** * *****************  ****************************************************/}

                <div>
                    <Modal open={vService} toggle={this.addService}>
                        <ModalHeader>Complete</ModalHeader>
                        <ModalBody>      
     
                                            <Form>
                                                    <Row>
                                                   
                                                    <Col md="9" className="form-group">
                                                        <label htmlFor="cboRace">Services</label>
                                                        
                                                            <FormSelect value={this.state.service_id} name="service_id" onChange={this.onChange}>
                                                                    <option key="XX"  value="" ></option>
                                                                    {
                                                                
                                                                            this.state.servicesAv.map(
                                                                        p =>

                                                                                <option key={p.product.id} value={p.product.id} >{p.product.name + ' -   Price : ' +  p.product.baseCost}</option>

                                                                            )
                                                                            
                                                                    }
                                                            </FormSelect>    
                                                
                                                    </Col> 

                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="txtPrice">Price</label>
                                                            <FormInput
                                                            id="txtProductPrice"
                                                            type="text"
                                                            name = "product_price"
                                                            value={this.state.product_price} onChange={this.onChange}
                                                         />
                                                    </Col>                                                    


                                                    <Col md="12" className="form-group">
                                                        <label htmlFor="feDescription">Notes</label>
                                                        <FormTextarea id="feDescription" rows="5" />
                                                    </Col>            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.addServiceList()}>
                                                            Save
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAddService()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                              

                        </ModalBody>
                    </Modal>
                </div> 

                {/********************************************** * *****************  ****************************************************/}
                {/********************************************** * MODAL DELETE SERVICE  ****************************************************/}
                {/********************************************** * *****************  ****************************************************/}

                <div>
                    <Modal open={vShowDelService} toggle={this.processDeleteService}>
                        <ModalHeader>Delete Service</ModalHeader>
                        <ModalBody>      
                    
                                        <div>      
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to Mark this Appoiment as Complete..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.processDeleteService()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelService()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                                      </div>
   
                        </ModalBody>
                    </Modal>
                </div> 




                {/****************** *PANELS ***************************************************************************/}


                <h6 className="text-center">Select Service Details</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>

                        <Col lg="8" md="12" sm="12" className="mb-4">
                            <Card small>
                                <CardHeader className="border-bottom">
                                <h6 className="m-0">Schedule and Address</h6>
                                <div className="block-handle" />
                                </CardHeader>

                                <CardBody className="p-0">
                                     <Col sm="9" md={{ size: 9, offset: 3 }} >
                                            <Form>
                                            <Row form>

                                     
                                            <Col md="6" className="form-group">
                                                <label htmlFor="txtZipCode">Date Selected</label>
                                                <FormInput
                                                id="txtDate"
                                                type="text"
                                                name = "date"
                                                placeholder=""
                                                disabled
                                                value={this.state.date} onChange={this.onChange}
                                                />
                                            </Col> 

                                            
                                            <Col md="9" className="form-group">
                                                <label htmlFor="txtZipCode">Customer Name</label>
                                                <FormInput
                                                id="txtBlock"
                                                type="text"
                                                name = "blockDesc"
                                                placeholder=""
                                                disabled
                                                value={this.state.blockDesc} onChange={this.onChange}
                                                />
                                            </Col>

                                            <Col md="9" className="form-group">
                                                <label htmlFor="txtZipCode">Addres Selected</label>
                                                <FormInput
                                                id="txtAddress"
                                                type="text"
                                                name = "address"
                                                placeholder=""
                                                disabled
                                                value={this.state.address} onChange={this.onChange}
                                                />
                                            </Col>
                                                            

                                            <Col md="9" className="form-group">
                                            <label htmlFor="cboRace">Select Pet</label>
                                                
                                                <FormSelect value={this.state.pet_id} name="pet_id" onChange={this.onChange}>
                                                        {
                                                    
                                                                this.state.pets.map(
                                                            p =>

                                                                    <option key={p.id} value={p.id} >{p.name}</option>

                                                                )
                                                                
                                                        }
                                                </FormSelect>    
                                                
                                            </Col>

                                            <Col md="4" className="form-group">
                                            <label htmlFor="cboRace">Select Branch</label>
                                                
                                                <FormSelect value={this.state.branch_id} name="branch_id" onChange={this.onChange}>
                                                        {
                                                    
                                                                this.state.branchs.map(
                                                            p =>

                                                                    <option key={p.id} value={p.id} >{p.name}</option>

                                                                )
                                                                
                                                        }
                                                </FormSelect>    
                                                
                                            </Col>    

                                            <Col md="5" className="form-group">
                                            <label htmlFor="cboRace">Select Truck</label>
                                                
                                                <FormSelect value={this.state.truck_id} name="truck_id" onChange={this.onChange}>
                                                        {
                                                    
                                                                this.state.trucks.map(
                                                            p =>

                                                                    <option key={p.id} value={p.id} >{p.name}</option>

                                                                )
                                                                
                                                        }
                                                </FormSelect>    
                                                
                                            </Col>                                                                                     


                                            <Col md="5" className="form-group">
                                            <label htmlFor="cboRace">Select Employee</label>
                                                
                                                <FormSelect value={this.state.employee_id} name="employee_id" onChange={this.onChange}>
                                                        {
                                                    
                                                                this.state.employees.map(
                                                            p =>

                                                                    <option key={p.id} value={p.id} >{p.sureName + ' ' + p.lastName  }</option>

                                                                )
                                                                
                                                        }
                                                </FormSelect>    
                                                
                                            </Col>  
                                                       
                            
                                        

                                        </Row>

 
                                        </Form>
                                    </Col>                                 

                                </CardBody>

                                <CardFooter className="border-top">
                                <Row>
                                    {/* Time Span */}
                                    <Col>
                                            <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.getAvalilabitlity}>
                                                Update
                                            </Button>  
                                            <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                                Cancel
                                            </Button> 
                                    </Col>

                                    {/* View Full Report */}
                                    <Col className="text-right view-report">
                                    {/* eslint-disable-next-line */}
                                    <a href="#">Details &rarr;</a>
                                    </Col>
                                </Row>
                                </CardFooter>
                            </Card>                           

                         </Col>

                         <Col lg="4" md="6" sm="6" className="mb-4">

                            <Card small>
                                <CardHeader className="border-bottom">
                                <h6 className="m-0">Services Added</h6>
                                <div className="block-handle" />
                                </CardHeader>

                                <CardBody className="p-0">
                                <ListGroup small flush className="list-group-small">
                                    {this.state.selectservices.map((item, idx) => (
                                    <ListGroupItem key={idx} className="d-flex px-3">
                                        <span className="text-semibold text-fiord-blue">{item.product.name}</span>
                                        <span className="ml-auto text-right text-semibold text-reagent-gray">
                                        {item.value}
                                        </span>
                                        <span className="text-semibold text-fiord-blue">  <Button theme="white" onClick={() => this.loadOneServiceAppoiment(item.id)}  >
                                                    <i className="material-icons">&#xE872;</i>
                                                </Button></span>
                                    </ListGroupItem>
                                    ))}
                                </ListGroup>
                                </CardBody>

                                <CardFooter className="border-top">
                                <Row>
                                    {/* Time Span */}
                                    <Col>

                                        <Col>
                                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.loadServiceAvailable()}>
                                                    Add Service
                                                </Button>  

                                        </Col>

                                    </Col>

                                    {/* View Full Report */}
                                    <Col className="text-right view-report">
                                    {/* eslint-disable-next-line */}
                                    <a href="#">Full report &rarr;</a>
                                    </Col>
                                </Row>
                                </CardFooter>
                            </Card>
                         </Col>



                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default EditAppComponent;