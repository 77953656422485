import React from "react";
import TagsInput from "react-tagsinput";
import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormInput,
  FormSelect,
  FormCheckbox,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

import FormSectionTitle from "../components/edit-user-profile/FormSectionTitle";
import ProfileBackgroundPhoto from "../components/edit-user-profile/ProfileBackgroundPhoto";
import EditProfileClientComponent from "../components/customer/EditProfileClientComponent";

class EditUserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [
        "User Experience",
        "UI Design",
        "React JS",
        "HTML & CSS",
        "JavaScript",
        "Bootstrap 4"
      ]
    };

    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleTagsChange(tags) {
    this.setState({ tags });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    return (
      <div>
        <Container fluid className="px-0">

        </Container>
        <Container fluid className="main-content-container px-4">
             <EditProfileClientComponent />
        </Container>
      </div>
    );
  }
}

export default EditUserProfile;
