import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/city';

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}


class City {

    fetchCities() {
        return connAxios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchCityById(cityId) {
        return connAxios.get(USER_API_BASE_URL + '/' + cityId);
    }

    deleteCity(cityId) {
        return connAxios.put(USER_API_BASE_URL + '/update',cityId);
    }

    addCity(city) {
        return connAxios.post(USER_API_BASE_URL+'/add', city);
    }

    editCity(city) {
        return connAxios.put(USER_API_BASE_URL + '/update', city);
    }


}

export default new City();