import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PageTitle from "../components/common/PageTitle";
import ListZoneComponent from "../components/zone/ListZoneComponent";
import AddZoneComponent from "../components/zone/AddZoneComponent";
import EditZoneComponent from "../components/zone/EditZoneComponent";
import SetupZoneComponent from "../components/zone/SetupZoneComponent";

const ZoneView = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Zones" subtitle="zone details" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}
      <Col>
        
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Details</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <Router>
                    <Switch>
                        
                        <Route path="/zones" component={ListZoneComponent} />
                        <Route path="/add-zone" component={AddZoneComponent} />
                        <Route path="/edit-zone" component={EditZoneComponent} />
                        <Route path="/zonesetup" component={SetupZoneComponent} />
                        
    
                    </Switch>

            </Router>
          </CardBody>
        </Card>


      </Col>

      {/* Sidebar Widgets */}

    </Row>
  </Container>
);

export default ZoneView;