import axios from 'axios';

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/appoiment';

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}


class Appoiment {

    fetchAllAppoiments() {
        return axios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchAppoimentsByCustomer() {
        return axios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchAppoimentById(cityId) {
        return axios.get(USER_API_BASE_URL + '/' + cityId);
    }

    deleteAppoiment(cityId) {
        return axios.put(USER_API_BASE_URL + '/update',cityId);
    }

    addAppoiment(city) {
        return axios.post(USER_API_BASE_URL+'/add', city, config);
    }

    editAppoiment(city) {
        return axios.put(USER_API_BASE_URL + '/update', city);
    }

    


}

export default new Appoiment();