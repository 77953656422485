import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form } from "shards-react";
import ApiService from "../../api/Service";
import dateFormat from "dateformat";

class ListServiceComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            services: [],
            message: null,
            total : 0,
            modalDelete : false,
            delServ : {}
        }
        this.deleteService = this.deleteService.bind(this);
        this.editService = this.editService.bind(this);
        this.addService = this.addService.bind(this);
        this.reloadServiceList = this.reloadServiceList.bind(this);
    }

    componentDidMount() {
        this.reloadServiceList();
    }



    reloadServiceList() {
        this.setState({services : []});
        ApiService.fetchServices()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({services: res.data.result, total : this.state.services.length});

            });
    }

    loadService(serviceId) {
        this.setState({modalDelete : !this.state.modalDelete});
        ApiService.fetchServiceById(serviceId)
            .then((res) => {
                let serv = res.data.result;
                console.log("Service to Delete " , res);
                this.setState({delServ : serv})
            });

            console.log("CITY STATE : " , this.state );
            
    }   


    deleteService() {

        let dService = {id : this.state.delServ.id , name : this.state.delServ.name, description : this.state.delServ.description,
                        base_cost : this.state.delServ.baseCost, status : 'DELETE' }
        
                  

        ApiService.deleteService(dService)
           .then(res => {
               this.setState({message : 'Service deleted successfully.'});
               this.setState({services: this.state.services.filter(service => service.id !== dService.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    } 

    editService(id) {
        window.localStorage.setItem("serviceId", id);
        this.props.history.push('/edit-service');
    }

    addService() {
        window.localStorage.removeItem("serviceId");
        this.props.history.push('/add-service');
    }

    render() {
        //console.log('Size Services : ' + this.state.Services.length);
        const deleteMd = this.state.modalDelete;
        return (
            <div>


                 {/* --------------------------- MODAL WINDOW TO DELETE SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deleteService()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div>                 
                

              <div>

                <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addService()}>
                    <i className="material-icons">add</i> New Service
                    </Button>
                </Col>                 
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">Service Description</th>
                            <th scope="col" className="border-0">Created Date</th>
                            <th scope="col" className="border-0">Price</th>
                            <th scope="col" className="border-0">Action</th> 
                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        
                        {
         
                                this.state.services.map(
                                service =>
                                            <tr key={service.id}>
                                                <td>{service.description}</td>
                                                <td>{dateFormat(new Date(service.createdAt), "dddd, mmmm dS, yyyy")}</td>
                                                <td>{service.baseCost}</td>
                                                <td>
                                                    
                                                    <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.loadService(service.id)}>
                                                        <i className="material-icons">&#xE872;</i>
                                                    </Button>                                            
                                                    
                                                    <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editService(service.id)}>
                                                        <i className="material-icons">&#xE254;</i>
                                                    </Button>                                           
                                                </td>
                                            </tr>
                                )
                        }        
                        
                    </tbody>
                  
                </table>
                </div>

            </div>
        );
    }

}

export default ListServiceComponent;