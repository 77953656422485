import React, { Component } from 'react'
import ApiPetRace from "../../api/PetRace";
import getPetTypes from "../../data/tableValues";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect, FormFeedback } from "shards-react";

class AddPetRaceComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
            description: '',
            description_fail : '',
            petType_id : '',
            petType_fail : '',
            petTypes : [], 
            status : ''            
        }
        this.savePetRace = this.savePetRace.bind(this);
        this.loadpetTypes = this.loadpetTypes.bind(this);
    }


    componentDidMount() {

        this.loadpetTypes();
        this.setState({petType_id : 'default'});
        console.log('ADD MOUNTING ' , this.state);
    }

    loadpetTypes() {
        const types = getPetTypes();
        this.state.petTypes = types;

    }


    savePetRace = (e) => {
        e.preventDefault();
        let petRace = {description: this.state.description, name : this.state.description, pet_type: this.state.petType_id, status : 'active'};
        let valid = true;

        if (this.state.description === ''){
            valid = false;
            this.setState({description_fail : true});
        }

        if (this.state.petType_id === ''){
            valid = false;
            this.setState({petType_fail : false})
        }

        if (valid){

            ApiPetRace.addPetRace(petRace)
            .then(res => {
                this.setState({message : 'petRace Added successfully.'});
                this.props.history.push('/petrace');
            });

        }


    }

    cancelAdd() {
        this.props.history.push('/petRaces');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({[e.target.name + '_fail'] : false})
    }    

    render() {
        return(
            <div>
                <h6 className="text-center">New Pet Race</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder="petRace Description"
                                    invalid = {this.state.description_fail}
                                    value={this.state.description} onChange={this.onChange}
                                    />
                                     <FormFeedback>Please, Fill this Field</FormFeedback>
                                </Col>

                                <Col md="9" className="form-group">
                                    <label htmlFor="cboRace">Pet Type</label>                                              
                                    <FormSelect value={this.state.petType_id} name="petType_id" onChange={this.onChange} invalid={this.state.petType_fail} >
                                       
                                            {
                                        
                                        this.state.petTypes.map(
                                                petType =>

                                                        <option key={petType.id} value={petType.name} >{petType.name}</option>

                                                    )
                                                    
                                            }
                                    </FormSelect> 
                                    <FormFeedback>Please, Fill this Field</FormFeedback> 

                                </Col>                                       

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.savePetRace}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddPetRaceComponent;