import axios from 'axios';

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/user';
const USER_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}

class Employee {

    fetchEmployees() {
        return axios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchTypeEmployees() {
        return axios.get(USER_BASE_URL+'/employee-type/listAll');
    }   

    fetchEmployeeById(empId) {
        return axios.get(USER_API_BASE_URL + '/' + empId);
    }

    deleteEmployee(emp) {
        return axios.delete(USER_API_BASE_URL + '/delete', emp);
    }

    addUser(user) {
        return axios.post(USER_API_BASE_URL+'/registerCustomer', user, config);
    }

    verifyAccount(code) {
        return axios.post(USER_API_BASE_URL+'/verificationCode', code, config);
    }

    editEmployee(emp) {
        return axios.put(USER_API_BASE_URL + '/update', emp);
    }

}

export default new Employee();