import React, { Component } from 'react'
import BigCalendar from "react-big-calendar";
import moment from "moment";
import { Button, Col, Row,Card, CardBody, Modal, ModalBody, ModalHeader, FormSelect, FormInput} from "shards-react";
import ApiAppoiment from "../../api/Appoiment";
import dateFormat from "dateformat";
import PageTitle from "../common/PageTitle";
import getCalendarEventsData from "../../data/calendar-events-data";
import ApiZone from "../../api/Zone";


class AppStep2Component extends Component{

    constructor(props){
        super(props);

        
        this.state ={
            events: null,
            calendarDates : [],
            address_select : {},
            zone_sel : null,
            times : [],
            mdTime : false,
            time_id : '',
            date_select : '',
            time_select : '',
            address_id : '',
        }

        //this.countiesList = this.countiesList.bind(this);
        this.getCalendarZoneDays = this.getCalendarZoneDays.bind(this)
        this.selectTime = this.selectTime.bind(this);
        
    }

    componentDidMount() {
   
        this.setState({zone_sel :  window.localStorage.getItem('seletedZone')});
        console.log('Zone' ,window.localStorage.getItem('seletedZone'));
        console.log('Address Select' ,window.localStorage.getItem('seletedAdd'));
        
        this.getCalendarZoneDays(window.localStorage.getItem('seletedZone'));
        
    }

    componentWillMount() {
        const events = getCalendarEventsData();
        this.setState({
          ...this.state,
          events
        });
      } 

    
    onSelectSpace(event)  {
        window.localStorage.setItem("dateSel", event.start );
        window.localStorage.setItem("blockSel", event.id );
        //alert( event.start + " " + event.end)  
        this.setState({mdTime : true})
        this.getTimesByDate(window.localStorage.getItem('seletedZone'), event.start);
        //this.props.history.push('/appoiment/step3');      
    }



    selectTime() {
        this.setState({mdTime : false});

        console.log("No SELECT **********", this.state.time_id);
        
        if (this.state.time_id !== ''){
            window.localStorage.setItem("blockSel", this.state.time_id );
            this.props.history.push('/appoiment/step3');
        }
    }

    getTimesByDate(zone_id, date) {
        
        console.log("## Date requested  ## :", dateFormat(date, "yyyy-mm-dd")); 

        ApiZone.fetchTimesByZone(zone_id, dateFormat(date, "yyyy-mm-dd"))
        .then((res) => {
            console.log('Times Size REsult : ',res);
            this.setState({times : res.data.result});
            this.setState({date_select : dateFormat(date, "yyyy-mm-dd")});

        });

    }

    getCalendarZoneDays(zone_id){

        ApiZone.getCalendarData(zone_id)
        .then((res) => {
            console.log('Calendar Size REsult : ',res);
            this.setState({calendarDates : res.data.result})

        });

    }

   
    cancelAdd() {
        this.props.history.push('/customer/pets');
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    render() {

        const mapToRBCFormat = e => Object.assign({}, e, {
            start: new Date(e.start ),
            end: new Date(e.end)
        });
        

        //const { events } = this.state
        const events = this.state.calendarDates;  
        const allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);
        const localizer = BigCalendar.momentLocalizer(moment);
        const timeMd = this.state.mdTime;

        return(
            <div>

                 {/* --------------------------- MODAL WINDOW TO SELECT TIME ---------------------------------------------------------------- */}
                 
                 <div>
                        <Modal open={timeMd} toggle={this.selectTime}>
                        <ModalHeader>Select Shcedule</ModalHeader>
                        <ModalBody>                 <Row noGutters className="page-header py-4">
    

                                                    
                                                        <label htmlFor="baseCost">Date</label>
                                                         <FormInput
                                                         id="txtdate"
                                                         type="date_select"
                                                         name = "date_select"
                                                         placeholder=""
                                                         value={this.state.date_select} onChange={this.onChange} disabled
                                                    />
                                                                                                         
                                                        <label htmlFor="baseCost">Time</label>
                                                        <FormSelect value={this.state.time_id} name="time_id" onChange={this.onChange}>
                                                                <option key="x" value="0" ></option>
                                                                {                                                      
                                                                        this.state.times.map(c =>

                                                                            <option key={c} value={c} >{c}</option>

                                                                        )
                                                                }
                                                        </FormSelect>

                                                    
                                                    
                                                    </Row>
                                                    <Row>
                                                        <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={this.selectTime}>
                                                            <i className="material-icons">add</i> Continue
                                                        </Button> 
                                                    </Row>                                                  


                        </ModalBody>
                    </Modal>
                </div>


                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}





            <Row>
      {/* Editor */}
                <Col sm={{ size: 10, order: 1, offset: 1 }}>

                <Row noGutters className="page-header py-4">
                <PageTitle sm="4" subtitle="Choose your desire date" className="text-sm-left mb-3" />
                <Col sm="4" className="d-flex ml-auto my-auto">
                </Col>
                </Row>
                <Card className="p-0">
                <CardBody className="py-4">
                    <BigCalendar
                        events={events.map(mapToRBCFormat)}
                        views={['month','day']}
                        step={60}
                        showMultiDayTimes
                        defaultDate={new Date()}
                        onSelectEvent={event => this.onSelectSpace(event)}
                        localizer={localizer} />
                </CardBody>
                </Card>

                </Col>
            </Row>    
   
            </div>
        );
    }
}

export default AppStep2Component;