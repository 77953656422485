import React, { Component } from 'react'
import { FormSelect } from "shards-react";
import County from "../../api/County";

class ListCombo extends Component {

    constructor(props) {
        super(props)
        this.state = {
             counties: []
        }

        this.reloadCountiesList = this.reloadCountiesList.bind(this);
        
    }

    componentDidMount() {
        this.reloadCountiesList();
    }



    reloadCountiesList() {
        County.fetchCounties()
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({counties: res.data.counties});

            });
    }



    render() {
        //console.log('Size Zones : ' + this.state.zones.length);
        return (

            <FormSelect name={this.props.name}>
                {
             
                        this.state.counties.map(
                    county =>

                            <option key={county.id} value={county.id} >{county.name}</option>

                        )
                        
                }
           </FormSelect>

        );
    }

}

export default ListCombo;