import React, { Component } from 'react'
import ApiPet from "../../api/Pet";
import ListCombo from  "../county/ListCombo"
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect  } from "shards-react";
import ApiPetRace from "../../api/PetRace";

class EditPetComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            name : '',
            date_of_birth : '',
            height : '',
            weight : '',
            race_id : '',
            customer_id : '',
            petRaces : []
        }
        this.savePet = this.savePet.bind(this);
        this.loadPet = this.loadPet.bind(this);
    }

    componentDidMount() {
        this.loadPet();
        this.racesList();
    }

    
    racesList() {
        ApiPetRace.fetchPetRaces
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({petRaces: res.data.result});

            });
    } 
    

    loadPet() {
        
        ApiPet.fetchPetById(window.localStorage.getItem("PetId"))
            .then((res) => {
                let pet = res.data.result;
                console.log("Pet TO EDIT " , res);
                this.setState({
                    name : pet.name,
                    date_of_birth : pet.dateOfBirth,
                    height : pet.height,
                    weight : pet.weight,
                    race_id : pet.race.id,
                    customer_id : pet.customer.id,         
                })
            });

            console.log("Pet STATE : " , this.state )
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    savePet = (e) => {
        e.preventDefault();
        let pet = {id : this.state.id , name: this.state.name, date_of_birth : this.state.date_of_birth, height : this.state.height, weight : this.state.weight, race_id : this.state.race_id, customer_id : this.state.customer_id };
        ApiPet.editPet(pet)
            .then(res => {
                  this.props.history.push('/pets');
            });


    }

    cancelAdd() {
        this.props.history.push('/pets');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit Pet</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Pet Name</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="6" className="form-group">
                                    <label htmlFor="txtState">Date of Birth</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="date"
                                    name = "date_of_birth"
                                    placeholder=""
                                    value = {this.state.date_of_birth}
                                    onChange={this.onChange}
                                    />
                                </Col>    

                                <Col md="3" className="form-group">
                                    <label htmlFor="txtZipCode">Height</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "height"
                                    placeholder=""
                                    value={this.state.height} onChange={this.onChange}
                                    />
                                </Col> 

                                
                                <Col md="3" className="form-group">
                                    <label htmlFor="txtZipCode">Weight</label>
                                    <FormInput
                                    id="txtWeight"
                                    type="text"
                                    name = "weight"
                                    placeholder=""
                                    value={this.state.weight} onChange={this.onChange}
                                    />
                                </Col>                                                           
                                
                                <Col md="9" className="form-group">
                                   <label htmlFor="cboRace">Select Race</label>
                                    <ListCombo id="r_county" name="form1" ref={this.form1} />                              
                                </Col>

 
                                <FormSelect value={this.state.race_id} name="race_id" onChange={this.onChange}>
                                        {
                                    
                                                this.state.petRaces.map(
                                            race =>

                                                    <option key={race.id} value={race.id} >{race.name}</option>

                                                )
                                                
                                        }
                                </FormSelect>                                 
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.savePet}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditPetComponent;