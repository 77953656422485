import React, { Component } from 'react'
import { FormSelect } from "shards-react";
import County from "../../api/County";

class ListComboCounty extends Component {

    constructor(props) {
        super(props)
        this.state = {
            county_id : '',
            counties: [],
            message: null
        }

        this.reloadCountiesList = this.reloadCountiesList.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.reloadCountiesList();
    }



    reloadCountiesList() {
        County.fetchCounties()
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({counties: res.data.counties});

            });
    }

    onChange = (e) => {
          this.setState({ [e.target.name]: e.target.value });
          console.log('Hice Change : ', { [e.target.name]: e.target.value });

    }      


    render() {
        //console.log('Size Zones : ' + this.state.zones.length);
        return (

            <FormSelect value={this.state.county_id} name="county_id" onChange={this.onChange}>
                {
             
                        this.state.counties.map(
                    county =>

                            <option key={county.id} value={county.id} >{county.name}</option>

                        )
                        
                }
           </FormSelect>

        );
    }

}

export default ListComboCounty;