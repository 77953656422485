import axios from 'axios'
import React, { Component } from 'react'
import auth from  "../../api/Authentication";
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT
export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const ID_CUSTOMER_SESSION_ATTRIBUTE_NAME = 'idCustomer'
export const ID_USER_SESSION_ATTRIBUTE_NAME = 'idUser'
export const USER_ROLE = 'Role'

const connAxios = axios.create({ baseUrl : "localhost:8080"})

class AuthenticationService {

    

    executeBasicAuthenticationService(username, password) { 
        let rs = connAxios.get(USER_API_BASE_URL + '/basicauth',
            { headers: { authorization: this.createBasicAuthToken(username, password) } });
        
        return rs;    
    }
   
    createBasicAuthToken(username, password) {
        return 'Basic ' + window.btoa(username + ":" + password) 
    }

    
    registerSuccessfulLogin(username, password, details) {

        console.log('1 - registerSuccessfulLogin', details);

        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
        sessionStorage.setItem(ID_CUSTOMER_SESSION_ATTRIBUTE_NAME, details.data.userId);
        sessionStorage.setItem(USER_ROLE , details.data.role);  //Admin //Customer
        sessionStorage.setItem("authToken", details.config.headers.authorization);
        console.log('2 - DETAIL : ' , details.config.headers.authorization );
        //this.setupAxiosInterceptors(this.createBasicAuthToken(username, password));
        this.newAxiosInterceptor(details.config.headers.authorization);

    }

    
    logout() {
        
        if (this.isUserLoggedIn) {
            auth.logout()
                .then(res => {
                    
                    window.localStorage.setItem("Logged Out");
                    //this.props.history.push('/blog-overview');
            });
        }

        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(ID_CUSTOMER_SESSION_ATTRIBUTE_NAME);
    }


    isUserLoggedIn() {
        console.log("fffff");
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        
        console.log("USER LOGIN : " , user);
        if (user === null) return false
        return true
    }

    
    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }


    setupAxiosInterceptors(token) {
        
        connAxios.interceptors.request.use(
            (config) => {
                console.log('3 - ENter to Interceptor')
                if (this.isUserLoggedIn()) {
                    console.log('Getting Interceptor')
                    config.headers.authorization = token
                }
                return config
            }
        )
    }


    newAxiosInterceptor(token) {
        
        console.log('3 - NEW AXIOS', connAxios);
        connAxios.interceptors.request.use(config => {
            // perform a task before the request is sent
            console.log('4 - Request was sent, Im INNN');
            config.headers.authorization = token
            return config;
          }, error => {
            console.log('4 - ERROR AXIOS');
            return Promise.reject(error);
          });
          console.log('5 - Good Bye New Interceptor');
    }


}


export default new AuthenticationService()