import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/county';


class County {

    fetchCounties() {
        return connAxios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchCountyById(userId) {
        return connAxios.get(USER_API_BASE_URL + '/' + userId);
    }

    deleteCounty(userId) {
        return connAxios.delete(USER_API_BASE_URL + '/' + userId);
    }

    addCounty(county) {
        return connAxios.post(USER_API_BASE_URL+'/save', county);
    }

    editCounty(county) {
        return connAxios.put(USER_API_BASE_URL + '/' + county.id, county);
    }

}

export default new County();