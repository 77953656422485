import React, { Component } from 'react'
import Pet from "../../api/Pet";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect} from "shards-react";
import ApiAddress from "../../api/Address";
import ApiCustomer from "../../api/Customer";

class AppStep1Component extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            name : '',
            address_id : '',
            customer_id : '',
            currCustomerId : '',
            addresses : [],
            appOrigin : '',
            address : {},
            zone_id : '',
            city_id : ''
            

        }
        
        this.getAvalilabitlity = this.getAvalilabitlity.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {
        
        console.log('**** ACTUAL CUSTOMER : ******* ' , window.localStorage.getItem("appCustomerId") );
        this.setState({currCustomerId : window.localStorage.getItem("appCustomerId")});
        this.setState({currCustomerId : window.localStorage.getItem("idCustomer")});  // To use in another components - Like Add Pet
        //this.customeraddressList(this.state.currCustomerId);
        this.setState({appOrigin : window.localStorage.getItem("appOrigin")});
        this.loadCustomer(window.localStorage.getItem("appCustomerId"));
    } 
   

    customeraddressList(custid) {
        //console.log('ACTUAL CUSTOMER : ' , window.localStorage.getItem("appCustomerId") );
        ApiAddress.fetchAddresesByCustomer(custid)
            .then((res) => {
                console.log('Customer Addresess Rewsult : ',res);
                this.setState({addresses: res.data.result});

            });
    } 
    
    loadCustomer(id){

            ApiCustomer.fetchCustomerById(id)
                .then((res) => {
                    let customer = res.data.result;
                    console.log("Customer : " , customer );
                    this.setState({addresses : customer.addresses});

                });
    
                


    }
    
    getAvalilabitlity() {
        if (this.state.address_id != "0"){
            this.getAddresData();
          this.props.history.push('/appoiment/step2');
         
          
          
          
        }  

    }

    getAddresData() {
        //window.localStorage.setItem('seletedAdd', this.state.addresses.filter(add => add.id == this.state.address_id));
        const addArr = this.state.addresses;
        this.setState({addresses : this.state.addresses.filter(add => add.id === this.state.address_id)});
        console.log('Addres Id Step 1' , this.state.addresses);
        

        for (const [index, value] of addArr.entries()) {
            
            console.log('ARRAY ' , value);
            console.log('ADDRES TO COMPARE ', this.state.address_id);
            window.localStorage.setItem('seletedZone', 2);   // **********   Pending Change thiz Zone  *********************
            if (value.id == this.state.address_id){

                window.localStorage.setItem('appDescCustomerAdd',value.address);
                window.localStorage.setItem('appCityId',value.city.id);
                window.localStorage.setItem('appIdCustomerAdd', this.state.address_id );
                
            }
        }

    }

    cancelAdd() {

        this.props.history.push('/customer/pets');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        window.localStorage.setItem('appCustomerAdd', e.target.value);
        
    }    
        

    render() {
        return(
            <div>
                <h6 className="text-center">Select Address to see availability</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>


                                                 

                                <Col md="9" className="form-group">
                                   <label htmlFor="cboAddress">Select Address</label>
                                    
                                    <FormSelect value={this.state.address_id} name="address_id" onChange={this.onChange}>
                                            <option key="0" value="0" ></option>
                                            {
                                        
                                                    this.state.addresses.map(
                                                add =>

                                                        <option key={add.id} value={add.id} >{add.address}</option>

                                                    )
                                                    
                                            }
                                    </FormSelect>    
                                     
                                </Col>


                 
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.getAvalilabitlity}>
                                    Continue
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AppStep1Component;