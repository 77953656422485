import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, HeaderNavigation, IconSidebar } from "./layouts";

// Route Views
import Analytics from "./views/Analytics";
import OnlineStore from "./views/OnlineStore";
import BlogOverview from "./views/BlogOverview";
import UserProfile from "./views/UserProfile";
import EditUserProfileView from "./views/EditUserProfileView";
import Login from "./views/Login";
import RegisterView from "./views/RegisterView";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import FileManagerList from "./views/FileManagerList";
import FileManagerCards from "./views/FileManagerCards";
import AppoimentsView from "./views/AppoimentsView";
import Calendar from "./views/Calendar";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import HeaderNav from "./views/HeaderNavigation";
import IconSidebarView from "./views/IconSidebar";
import ZoneView from "./views/ZoneView";
import CityView from "./views/CityView";
import ServicesView from "./views/ServicesView";
import EmployeeView from "./views/EmployeeView";
import PetraceView from "./views/PetraceView";
import ZoneSetupView from "./views/ZoneSetupView";
import CreateCustomerView from "./views/CreateCustomerView";
import ListCustomersView from "./views/ListCustomersView";
import CustomerProfileView from "./views/CustomerProfileView";
import EditCustomerView from "./views/EditCustomerView";
import BookAppoimentView from "./views/BookAppoimentView";
import CustomerClientProfileView from "./views/CustomerClientProfileView";
import PetView from "./views/PetView";
import AppoimentView from "./views/AppoimentView";
import AddressView from "./views/AddressView";
import MyreservationsView from "./views/MyreservationsView";
import MybillsView from "./views/MybillsView";
import SalesView from "./views/SalesView";
import BranchView from "./views/BranchView";
import TruckView from "./views/TruckView";
import SchedulesView from "./views/SchedulesView";
import VerifyAccountComponent from "./components/register/VerifyAccountComponent";
import AuthenticateRoute from './components/authentication/AuthenticateRoute';
import RegisterComponent from "./components/register/RegisterComponent";


const BlankIconSidebarLayout = ({ children }) => (
  <IconSidebar noSidebar noNavbar noFooter>
    {children}
  </IconSidebar>
);

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <AuthenticateRoute />
  },
  {
    path: "/analytics",
    layout: DefaultLayout,
    component: () => <AuthenticateRoute component={Analytics}/>
  },
  {
    path: "/ecommerce",
    layout: DefaultLayout,
    component: OnlineStore
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/create-customer",
    layout: DefaultLayout,
    component: CreateCustomerView
  },
  {
    path: "/customer-client-profile",
    layout: DefaultLayout,
    component: CustomerClientProfileView
  },
  {
    path: "/book-appoiment",
    layout: DefaultLayout,
    component: AppoimentView
  },
  {
    path: "/appoiment/step1",
    layout: DefaultLayout,
    component: AppoimentView
  },
  {
    path: "/edit-user-profile",
    layout: DefaultLayout,
    component: EditUserProfileView
  },
  {
    path: "/login",
    layout: BlankIconSidebarLayout,
    component: RegisterView
  },
  {
    path: "/register",
    layout: BlankIconSidebarLayout,
    component: RegisterComponent
  },
  {
    path: "/verify-account",
    layout: BlankIconSidebarLayout,
    component: VerifyAccountComponent
  },
  {
    path: "/forgot-password",
    layout: BlankIconSidebarLayout,
    component: ForgotPassword
  },
  {
    path: "/change-password",
    layout: BlankIconSidebarLayout,
    component: ChangePassword
  },
  {
    path: "/file-manager-list",
    layout: DefaultLayout,
    component: FileManagerList
  },
  {
    path: "/list-customers",
    layout: DefaultLayout,
    component: ListCustomersView
  },
  {
    path: "/view-customer-profile",
    layout: DefaultLayout,
    component: CustomerProfileView
  },
  {
    path: "/edit-customer-profile",
    layout: DefaultLayout,
    component: EditCustomerView
  },
  {
    path: "/file-manager-cards",
    layout: DefaultLayout,
    component: FileManagerCards
  },
  {
    path: "/pending-appoiments",
    layout: DefaultLayout,
    component: AppoimentsView
  },
  {
    path: "/errorsss",
    layout: BlankIconSidebarLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/customer/address",
    layout: DefaultLayout,
    component: AddressView
  },
  {
    path: "/customer/my-reservations",
    layout: DefaultLayout,
    component: MyreservationsView
  },  
  {
    path: "/customer/my-bills",
    layout: DefaultLayout,
    component: MybillsView
  },
  {
    path: "/appoiments",
    layout: DefaultLayout,
    component: MyreservationsView
  },
  {
    path: "/sales",
    layout: DefaultLayout,
    component: SalesView
  },
  {
    path: "/bills",
    layout: DefaultLayout,
    component: MybillsView
  },
  {
    path: "/zones",
    layout: DefaultLayout,
    component: ZoneView
  },{
    path: "/services",
    layout: DefaultLayout,
    component: ServicesView
  },{
    path: "/cities",
    layout: DefaultLayout,
    component: CityView
  },{
    path: "/employees",
    layout: DefaultLayout,
    component: EmployeeView
  },{
    path: "/petrace",
    layout: DefaultLayout,
    component: PetraceView
  },{
    path: "/customer/pets",
    layout: DefaultLayout,
    component: PetView
  },{
    path: "/zonesetup",
    layout: DefaultLayout,
    component: ZoneSetupView
  },{
    path: "/branch",
    layout: DefaultLayout,
    component: BranchView
  },{
    path: "/truck",
    layout: DefaultLayout,
    component: TruckView
  },
  {
    path: "/add-zone",
    layout: DefaultLayout,
    component: ZoneView
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/header-navigation",
    layout: HeaderNavigation,
    component: HeaderNav
  },
  {
    path: "/icon-sidebar-nav",
    layout: IconSidebar,
    component: IconSidebarView
  }  
];
