import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PageTitle from "../components/common/PageTitle";
import ListPetComponent from "../components/pet/ListPetComponent";
import AddPetComponent from "../components/pet/AddPetComponent";
import EditPetComponent from "../components/pet/EditPetComponent";
import Errors from "./Errors"

const PetView = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Manage Pets" subtitle="Details" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}
      <Col>
        
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Details</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <Router>
                    <Switch>
                        
                          <Route path="/customer/pets" component={ListPetComponent} />
                          <Route path="/add-pet" component={AddPetComponent} />
                          <Route path="/edit-pet" component={EditPetComponent} />
                          <Route path="/errors" component={Errors} />
                          
                        
    
                    </Switch>

            </Router>
          </CardBody>
        </Card>


      </Col>

      {/* Sidebar Widgets */}

    </Row>
  </Container>
);

export default PetView;