import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form } from "shards-react";
import ApiTruck from "../../api/Truck";
import ApiBranch from "../../api/Branch";

class ListTruckComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Trucks: [],
            message: null,
            modalDelete : false,
            delTruck : {},
            branches : []
        }
        this.delete = this.deleteTruck.bind(this);
        this.editTruck = this.editTruck.bind(this);
        this.addTruck = this.addTruck.bind(this);
        this.reloadTruckList = this.reloadTruckList.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    componentDidMount() {
        this.reloadTruckList();
    }



    reloadTruckList() {
        ApiTruck.fetchTrucks()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({Trucks: res.data.result});

            });
    }

    reloadBranchList() {
        ApiBranch.fetchBranchs()
            .then((res) => {
                console.log('Size REsult Branchs: ',res);
                this.setState({Branches: res.data.result});

            });
    }    

    loadTruck(Truckid) {
        this.setState({modalDelete : !this.state.modalDelete});
        ApiTruck.fetchTruckById(Truckid)
            .then((res) => {
                let Truck = res.data.result;
                console.log("Truck TO EDIT " , res);
                this.setState({delTruck : Truck})
            });

            console.log("Truck STATE : " , this.state )
    }    


    deleteTruck() {
                                                                   
        let dTruck = {id : this.state.delTruck.id, name: this.state.delTruck.name, description: this.state.delTruck.name, status : 'INACTIVE'};
        ApiTruck.deleteTruck(dTruck)
           .then(res => {
               this.setState({message : 'Truck deleted successfully.'});
               this.setState({Trucks: this.state.Trucks.filter(Truck => Truck.id !== dTruck.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    }    

    editTruck(id) {
        window.localStorage.setItem("TruckId", id);
        this.props.history.push('/edit-Truck');
    }

    addTruck() {
        window.localStorage.removeItem("TruckId");
        this.props.history.push('/add-Truck');
    }

    render() {
        const deleteMd = this.state.modalDelete;
        //console.log('Size cities : ' + this.state.cities.length);
        return (
            <div>

                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deleteTruck()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div> 



              <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addTruck()}>
                    <i className="material-icons">add</i> New Truck
                    </Button>
              </Col> 


              <div>
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">Name</th>
                            <th scope="col" className="border-0">Description</th>
                            <th scope="col" className="border-0">Branch</th>
                            <th scope="col" className="border-0">Action</th>
                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        {
                            this.state.Trucks.map(
                        Truck =>
                                    <tr key={Truck.id}>
                                        <td>{Truck.name}</td>
                                        <td>{Truck.description}</td>
                                        <td>{Truck.branch.name}</td>
                                         <td>
                                            
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.loadTruck(Truck.id)}>
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>                                            
                                            
                                            <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editTruck(Truck.id)}>
                                                <i className="material-icons">&#xE254;</i>
                                            </Button>                                           
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                  
                </table>
                </div>

            </div>
        );
    }

}

export default ListTruckComponent;