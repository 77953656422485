import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/race';


let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}

class PetRace {

    fetchPetRaces() {
        return connAxios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchPetRaceById(petRaceId) {
        return connAxios.get(USER_API_BASE_URL + '/' + petRaceId);
    }

    deletePetRace(petRace) {
        return connAxios.put(USER_API_BASE_URL + '/update', petRace);
    }

    addPetRace(petRace) {
        return connAxios.post(USER_API_BASE_URL+'/add', petRace, config);
    }

    editpetRace(petRace) {
        return connAxios.put(USER_API_BASE_URL + '/update', petRace);
    }

}

export default new PetRace();