import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/customer';
const USER_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}

class Customer {

    fetchCustomer() {
        return connAxios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchTypeCustomer() {
        return connAxios.get(USER_BASE_URL+'/employee-type/listAll');
    }   

    fetchCustomerById(empId) {
        return connAxios.get(USER_API_BASE_URL + '/' + empId);
    }

    deleteCustomer(emp) {
        return connAxios.delete(USER_API_BASE_URL + '/delete', emp);
    }

    addCustomer(cus) {
        return connAxios.post(USER_API_BASE_URL+'/add', cus, config);
    }

    editCustomer(emp) {
        return connAxios.put(USER_API_BASE_URL + '/update', emp);
    }

    fecthPetsByCustomer(id){
        return connAxios.get(USER_API_BASE_URL + '/' + id +'/pets');
    }

}

export default new Customer();