import React, { Component } from 'react'
import Pet from "../../api/Pet";
import { FormTextarea, Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect,Card,CardHeader,Badge,CardBody,referralData,CardFooter} from "shards-react";
import ApiAppoiment from "../../api/Appoiment";
import ApiPetRace from "../../api/PetRace";
import getDataServices from "../../data/servicesAppData";
import getEventTypes from "../../data/tableEvents";

class AppStep3Component extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            address : '',
            blockDesc : '',
            date : '',
            name : '',
            date_of_birth : '',
            event_id : '',
            customer_id : '',
            petRaces : [],
            eventTypes : [],
            appoiment_id : '',
            customer_name : ''
        }



        
        this.savePet = this.savePet.bind(this);
        this.getAvalilabitlity = this.getAvalilabitlity.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {
        this.getEventTypes();
        this.setState({date : window.localStorage.getItem("dateSel"), blockDesc : window.localStorage.getItem("blockSel") })
    } 
   

    getEventTypes() {
        const types = getEventTypes();
        this.state.eventTypes = types;

    }
    

    savePet = (e) => {
        e.preventDefault();
        let pet = {name: this.state.name, date_of_birth : this.state.date_of_birth, height : this.state.height, weight : this.state.weight, race_id : this.state.race_id, customer_id : this.state.customer_id };
        Pet.addPet(pet)
            .then(res => {
                
                this.setState({message : 'Pet Added successfully.'});
                this.props.history.push('/pets');
            });
    }

    getAvalilabitlity() {
        this.props.history.push('/appoiment/step2');
    }

    cancelAdd() {
        this.props.history.push('/customer/pets');
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    render() {
        const referralData = getDataServices();
        return(
            <div>
                <h6 className="text-center">Events Details</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>

                        <Col lg="6" md="12" sm="12" className="mb-4">
                            <Card small>
                                <CardHeader className="border-bottom">
                                <h6 className="m-0">Event Descripcion</h6>
                                <div className="block-handle" />
                                </CardHeader>

                                <CardBody className="p-0">
                                     <Col sm="9" md={{ size: 9, offset: 1 }} >
                                            <Form>
                                            <Row form>

                                            <Col md="3" className="form-group">
                                                <label htmlFor="txtAppoiment">Appoiment</label>
                                                <FormInput
                                                id="txtAppoiment"
                                                type="text"
                                                name = "appoiment_id"
                                                placeholder=""
                                                disabled
                                                value={this.state.appoiment_id} onChange={this.onChange}
                                                />
                                            </Col>    

                                            
                                            <Col md="9" className="form-group">
                                                <label htmlFor="txtZipCode">Customer Name</label>
                                                <FormInput
                                                id="txtBlock"
                                                type="text"
                                                name = "blockDesc"
                                                placeholder=""
                                                disabled
                                                value={this.state.customer_name} onChange={this.onChange}
                                                />
                                            </Col>

                                            <Col md="9" className="form-group">
                                            <label htmlFor="cboRace">Select Event Type</label>
                                                
                                                <FormSelect value={this.state.event_id} name="event_id" onChange={this.onChange}>
                                                        {
                                                    
                                                                this.state.eventTypes.map(
                                                            ev =>

                                                                    <option key={ev.id} value={ev.id} >{ev.name}</option>

                                                                )
                                                                
                                                        }
                                                </FormSelect>    
                                                
                                            </Col>                                            


                                            <Col md="12" className="form-group">
                                                <label htmlFor="feDescription">Description</label>
                                                <FormTextarea id="feDescription" rows="5" />
                                            </Col>
                                                            




                                                       
                            
                                        

                                        </Row>

 
                                        </Form>
                                    </Col>                                 

                                </CardBody>

                                <CardFooter className="border-top">
                                <Row>
                                    {/* Time Span */}
                                    <Col>
                                            <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.getAvalilabitlity}>
                                                Add
                                            </Button>  
                                            <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                                Cancel
                                            </Button> 
                                    </Col>

                                    {/* View Full Report */}
                                    <Col className="text-right view-report">
                                    {/* eslint-disable-next-line */}
                                    <a href="#">Details &rarr;</a>
                                    </Col>
                                </Row>
                                </CardFooter>
                            </Card>                           

                         </Col>

                         <Col lg="6" md="6" sm="6" className="mb-4">

                         <Card small className="user-activity mb-4">
                            <CardHeader className="border-bottom">
                            <h6 className="m-0">Events Log</h6>
                            <div className="block-handle" />
                            </CardHeader>
                            <CardBody className="p-0">

                            {/* User Activity Icon */}
                            <div className="user-activity__item pr-3 py-3">
                                <div className="user-activity__item__icon">
                                <i className="material-icons">&#xE5CA;</i>
                                </div>
                                <div className="user-activity__item__content">
                                <span className="text-light">25 Minutes Ago - System / Luck Skywalker</span>
                                <p>
                                    Appoiment was  <Badge outline pill theme="success">
                                    Invoiced
                                    </Badge>{" "}
                                    inside the <a href="#">reservation module</a>.
                                </p>
                                </div>
                                <div className="user-activity__item__action ml-auto">
                                <button className="ml-auto btn btn-sm btn-white">View Bill</button>
                                </div>                                
                            </div>
                            {/* User Activity Icon */}
                            <div className="user-activity__item pr-3 py-3">
                                <div className="user-activity__item__icon">
                                <i className="material-icons">&#xE5CA;</i>
                                </div>
                                <div className="user-activity__item__content">
                                <span className="text-light">30 Minutes Ago - System / Luck Skywalker</span>
                                <p>
                                    Appoiment Marked as{" "}
                                    <Badge outline pill theme="success">
                                    Complete
                                    </Badge>{" "}
                                    inside the <a href="#">Appoiments module</a>.
                                </p>
                                </div>
                            </div>                            
                            {/* User Activity Icon */}
                            <div className="user-activity__item pr-3 py-3">
                                <div className="user-activity__item__icon">
                                <i className="material-icons">add_alert</i>
                                </div>
                                <div className="user-activity__item__content">
                                <span className="text-light">1 Hour Ago - System / Luck Skywalker</span>
                                <p>
                                    Added <a href="#">Service</a> : Banado Extra
                                </p>
                                </div>

                            </div>
                            {/* User Activity Icon */}
                            <div className="user-activity__item pr-3 py-3">
                                <div className="user-activity__item__icon">
                                <i className="material-icons">&#xE868;</i>
                                </div>
                                <div className="user-activity__item__content">
                                <span className="text-light">3 Hours ago - Luck Skywalker</span>
                                <p>
                                    Report <a href="#">1 issues</a> in <a href="#">appoiment</a> : El Cliento dijo que le dejaron el perro con pulgas.
                                </p>
                                </div>
                                <div className="user-activity__item__action ml-auto">
                                <button className="ml-auto btn btn-sm btn-white">View Picture</button>
                                </div>
                            </div>

                            {/* User Activity Icon */}
                            <div className="user-activity__item pr-3 py-3">
                                <div className="user-activity__item__icon">
                                <i className="material-icons">settings_cell</i>
                                </div>
                                <div className="user-activity__item__content">
                                <span className="text-light">4 Hours Ago - Luck Skywalker</span>
                                <p>
                                    Text Message Sent to <a href="#">323-9264952</a> El Conductor va en camino.
                                </p>
                                </div>

                            </div>                            

                            {/* User Activity Icon */}
                            <div className="user-activity__item pr-3 py-3">
                                <div className="user-activity__item__icon">
                                <i className="material-icons">&#xE5CD;</i>
                                </div>
                                <div className="user-activity__item__content">
                                <span className="text-light">2 Days ago - Luck Skywalker</span>
                                <p>
                                    Service <a href="#">marked</a> as{" "}
                                    <Badge outline pill theme="danger">
                                    delete
                                    </Badge>{" "}
                                    inside the <a href="#">Appoiments</a> module.
                                </p>
                                </div>
                            </div>
                            {/* User Activity Icon */}
                            <div className="user-activity__item pr-3 py-3">
                                <div className="user-activity__item__icon">
                                <i className="material-icons">&#xE5CA;</i>
                                </div>
                                <div className="user-activity__item__content">
                                <span className="text-light">3 Day Ago - System</span>
                                <p>
                                    Request  <a href="#">Appoiment</a> as{" "}
                                    <Badge outline pill theme="success">
                                    Reserve
                                    </Badge>{" "}
                                    inside the <a href="#">reservation module</a>.
                                </p>
                                </div>
                            </div>                            
                            </CardBody>
                            <CardFooter className="border-top">
                            <Button size="sm" theme="white" className="d-table mx-auto">
                                Load More
                            </Button>
                            </CardFooter>
                        </Card>
                        </Col>



                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AppStep3Component;