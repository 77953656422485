import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form, InputGroupText,CardBody,InputGroup,InputGroupAddon,FormInput,Container ,
    CardHeader,FormSelect, Card, ButtonGroup, FormTextarea} from "shards-react";
import FuzzySearch from "fuzzy-search";
import ApiRes from "../../api/Reservation";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import getTransactionHistoryData from "../../data/transaction-history-data";
import getPaymentTypes from "../../data/tablePayment";
import FormatCurrency from 'react-format-currency';

class ListreservationComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reservations: [],
            message: null,
            modalDelete : false,
            delreservation : {},
            pageSizeOptions: [5, 10, 15, 20, 25, 30],
            pageSize: 10,
            tableData: [],
            confirmed : false,
            modalComplete : false,
            completeYes : false,
            modalInvoice : false,
            invoiceYes : false,
            paymentTypes : [],
            payment_date : '',
            paymenttype_id : '',
            reservations : [],
            currReserv : {}
        }
        this.delete = this.deletereservation.bind(this);
        this.editreservation = this.editreservation.bind(this);
        this.addreservation = this.addreservation.bind(this);
        this.reloadreservationList = this.reloadreservationList.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);

        this.searcher = null;

        this.getStatusClass = this.getStatusClass.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleFilterSearch = this.handleFilterSearch.bind(this);
        this.handleItemEdit = this.handleItemEdit.bind(this);
        this.handleItemEvent = this.handleItemEvent.bind(this);
        this.handleItemDelete = this.handleItemDelete.bind(this);
        this.handleItemConfirm = this.handleItemConfirm.bind(this);
        this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
        this.getButtonStatusClass = this.getButtonStatusClass.bind(this);
    }

    componentDidMount() {
       //this.reloadreservationList('PENDING_APPROVAL');
        this.getPaymentTypes();


        const tableData = this.reloadreservationList('PENDING_APPROVAL');

        this.setState({
          ...this.state,
          tableData
        });

        console.log('TABLE DATA : ' , this.state.tableData);
    
        // Initialize the fuzzy searcher.
        this.searcher = new FuzzySearch(tableData, ["customer", "status"], {
          caseSensitive: false
        });
    }


    getPaymentTypes() {
      const types = getPaymentTypes();
      this.state.paymentTypes = types;

    }

    /*reloadreservationListByCustomer() {
        ApiRes.fetchAppoimentsByCustomer()
            .then((res) => {
                console.log('Size REsult Reservations ************ : ',res);
                this.setState({tableData: res.data.result});

            });
    }*/


  reloadreservationList(status) {
      ApiRes.fetchAllAppoiments(status)
          .then((res) => {
              console.log('Size REsult Reservations : ',res);
              this.setState({reservations: res.data.result});

          });
  }

    loadreservation(reservationid) {
      this.setState({completeYes : false});
      this.setState({invoiceYes : false});
        ApiRes.fetchreservationById(reservationid)
            .then((res) => {
                let reservation = res.data.result;
                console.log("reservation TO EDIT " , res);
                this.setState({delreservation : reservation})
            });

            console.log("reservation STATE : " , this.state )
    }    


    deletereservation() {
                                                                   
        let dreservation = {id : this.state.delreservation.id, name: this.state.delreservation.name, description: this.state.delreservation.name,date_of_birth : this.state.delreservationdate_of_birth, height : this.state.delreservation.height, weight : this.state.delreservation.weight, 
            race_id : this.state.delreservation.race_id, customer_id : this.state.delreservation.customer_id, status : 'INACTIVE'};
            ApiRes.deletereservation(dreservation)
           .then(res => {
               this.setState({message : 'reservation deleted successfully.'});
               this.setState({reservations: this.state.reservations.filter(reservation => reservation.id !== dreservation.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelComplete() {
      this.setState({modalComplete : !this.state.modalComplete});
    }   

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    } 

    cancelInvoice() {
      this.setState({modalInvoice : !this.state.modalInvoice});
    } 
    

    editreservation(id) {
        window.localStorage.setItem("reservationId", id);
        this.props.history.push('/edit-reservation');
    }

    addreservation() {
        window.localStorage.removeItem("reservationId");
        this.props.history.push('/add-reservation');
    }

    getStatusClass(status) {
        const statusMap = {
          Cancelled: "danger",
          Complete: "success",
          Pending: "warning"
        };
    
        return `text-${statusMap[status]}`;
    }

    /**
     * Handles the page size change event.
     */
    handlePageSizeChange(e) {
        this.setState({
        ...this.state,
        pageSize: e.target.value
        });
    }

    /**
     * Handles the global search.
     */
    handleFilterSearch(e) {
        this.setState({
        ...this.state,
        tableData: this.searcher.search(e.target.value)
        });
    }

    /**
     * Mock method for editing transactions.
     */
    handleItemEdit(row) {
        //alert(`Editing transaction "${row.original.id}"!`);
        window.localStorage.setItem("appoiment_id", row.original.id);
        this.props.history.push('/edit-appoiment');


        
    }

    handleItemEvent(row) {
      //alert(`Editing transaction "${row.original.id}"!`);
      window.localStorage.setItem("appoimentId", row.original.id);
      this.props.history.push('/event-appoiment');
  }

    /**
     * Mock method for deleting transactions.
     */
    handleItemDelete(row) {
      this.setState({modalDelete : !this.state.modalDelete});
      //Call Api get Appoiment by id 
      //  alert(`Deleting transaction "${row.original.id}"!`);
    }

    /**
     * Mock method for confirming transactions.
     */
    handleItemConfirm(row) {
        this.setState({modalComplete : !this.state.modalComplete});
        //this.loadreservation(row.id)
       // alert(`TITIRRI BONINO "${row.original.id}"!`);

    }

    confirmComplete(){
      this.setState({completeYes : true});
    }

    processComplete(){
      //Call Api change status appoiment to complete
      //if yes close modal window
      this.setState({modalComplete : false});
    }

        /**
     * Mock method for confirming transactions.
     */
    handleItemViewDetails(row) {
      //alert(`Viewing details for "${row.original.id}"!`);
      this.setState({modalInvoice : !this.state.modalInvoice});
    }

    confirmInvoice(){
      this.setState({invoiceYes : true});
    }

    processInvoice(){
      //Call Api change status appoiment to complete
      //if yes close modal window
      this.setState({modalInvoice : false});
    }    

    getButtonStatusClass(button, row) {
      const statusComplete = {
        Complete: "java",
        Pending: "white",
        Cancelled : "white",
        New : "white"
      };

      const statusInvoice = {
        Complete: "white",
        Pending: "white",
        New : "white",
        Invoiced : "java",
        Cancelled : "white"
      };

      const statusEdit = {
        Complete: "white",
        Pending: "white",
        New : "white",
        Invoiced : "white"
      };  
      
      const statusDelete = {
        Complete: "white",
        Pending: "white",
        New : "white",
        Invoiced : "white",
        Cancelled : "white"
      };        

      if (button === "Complete"){
        console.log(statusComplete[row.original.status]);
        return statusComplete[row.original.status]; 
        //return "white"; 
      }
      
      if (button === "Invoice"){
        return statusInvoice[row.original.status]; 
      }

      if (button === "Edit"){
        return statusEdit[row.original.status]; 
      }

      if (button === "Delete"){
        return statusDelete[row.original.status]; 
      }

  }




    render() {

        const completeMd = this.state.modalComplete;
        const invoiceMd = this.state.modalInvoice;
        
        const { tableData, pageSize, pageSizeOptions } = this.state;
        const deleteMd = this.state.modalDelete;
        const tableColumns = [
            {
              Header: "#",
              accessor: "id",
              maxWidth: 60,
              className: "text-center"
            },
            {
              Header: "Date",
              accessor: "date",
              className: "text-center",
              minWidth: 150,
              Cell: row =>
                dateFormat(new Date(row.original.appointmentDate), "dddd, mmmm dS, yyyy")
            },
            {
              Header: "Customer",
              accessor: "sureName",
              className: "text-center",
              minWidth: 120,
              Cell: row => <span>{row.original.customer.sureName}</span>
            },
            {
              Header: "Pet",
              accessor: "Pet",
              maxWidth: 100,
              className: "text-center",
              Cell: row => <span>{row.original.pet.name}</span>
            },
            {
                Header: "Total",
                accessor: "total",
                maxWidth: 100,
                Cell: row => <span className="text-success">{row.original.totalBaseCost}</span>,
                className: "text-center"
            },
            {
              Header: "Status",
              accessor: "status",
              maxWidth: 150,
              Cell: row => (
                <span className={this.getStatusClass(row.original.status)}>
                  {row.original.status}
                </span>
              ),
              className: "text-center"
            },
            {
              Header: "Actions",
              accessor: "actions",
              maxWidth: 300,
              minWidth: 180,
              sortable: false,
              Cell: row => (
                <ButtonGroup size="sm" className="d-table mx-auto">
                  <Button theme={this.getButtonStatusClass('Complete',row)} onClick={() => this.handleItemConfirm(row)}>
                    <i className="material-icons">&#xE5CA;</i>
                  </Button>
                  <Button
                    theme={this.getButtonStatusClass("Invoice",row)}
                    onClick={() => this.handleItemViewDetails(row)}
                  >
                    <i className="material-icons">receipt</i>
                  </Button>
                  <Button theme="white" onClick={() => this.handleItemEdit(row)}>
                    <i className="material-icons">&#xE254;</i>
                  </Button>
                  <Button theme="white" onClick={() => this.handleItemEvent(row)}>
                    <i className="material-icons">event</i>
                  </Button>                  
                  <Button theme="white" onClick={() => this.handleItemDelete(row)}>
                    <i className="material-icons">&#xE872;</i>
                  </Button>
                </ButtonGroup>
              )
            }
          ];
      
        //console.log('Size cities : ' + this.state.cities.length);
        const vComplete = this.state.completeYes;
        const vInvoice = this.state.invoiceYes;

        return (




            <div>

                 {/* --------------------------- MODAL WINDOW TO MARK APPOIMENT COMPLETE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={completeMd} toggle={this.addService}>
                        <ModalHeader>Complete</ModalHeader>
                        <ModalBody>      
                                   {!vComplete &&      
                                        <div>      
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to Mark this Appoiment as Complete..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.confirmComplete()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelComplete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                                      </div>
                                    }   

                                    {vComplete &&      
                                        <div>      
                                            <Form>
                                                    <Row>
                                                   

                                                    <Col md="12" className="form-group">
                                                        <label htmlFor="feDescription">Notes</label>
                                                        <FormTextarea id="feDescription" rows="5" />
                                                    </Col>            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.processComplete()}>
                                                            Save
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelComplete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                                      </div>
                                    }                                      

                        </ModalBody>
                    </Modal>
                </div> 

                {/* --------------------------- MODAL WINDOW TO SEND INVOICE ---------------------------------------------------------------- */}
                 
                <div>
                    <Modal open={invoiceMd} toggle={this.addService}>
                        <ModalHeader>Send Invoice</ModalHeader>
                        <ModalBody>      
                                   {!vInvoice &&      
                                        <div>      
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to generate the Invoice..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.confirmInvoice()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelInvoice()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                                      </div>
                                    }   

                                    {vInvoice &&      
                                        <div>      
                                            <Form>
                                                    <Row>
                                                    <Col md="9" className="form-group">
                                                        <label htmlFor="cboRace">Select Payment Type</label>
                                                            
                                                            <FormSelect value={this.state.payment_id} name="event_id" onChange={this.onChange}>
                                                                    {
                                                                
                                                                            this.state.paymentTypes.map(
                                                                        ev =>

                                                                                <option key={ev.id} value={ev.id} >{ev.name}</option>

                                                                            )
                                                                            
                                                                    }
                                                            </FormSelect>    
                                                
                                                      </Col>                                                     
                                                      
                                                      <Col md="6" className="form-group">
                                                        <label htmlFor="txtDate">Payment Date</label>
                                                        <FormInput
                                                        id="txtPaymentDate"
                                                        type="date"
                                                        name = "payment_date"
                                                        placeholder=""
                                                        value = {this.state.payment_date}
                                                        onChange={this.onChange}
                                                        />
                                                     </Col>  

                                                      <Col md="12" className="form-group">
                                                          <label htmlFor="feDescription">Notes</label>
                                                          <FormTextarea id="feDescription" rows="5" />
                                                      </Col>            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.processInvoice()}>
                                                            Save
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelInvoice()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                                      </div>
                                    }                                      

                        </ModalBody>
                    </Modal>
                </div> 


                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deletereservation()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div> 


                <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={this.state.reservations}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
              />
            </div>
          </CardBody>
        </Card>


            </div>
        );
    }

}

export default ListreservationComponent;