import React, { Component } from 'react'
import Pet from "../../api/Pet";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect, CardBody,referralData,CardFooter, Card,CardHeader,FormTextarea,
         Modal,ModalHeader , ModalBody } from "shards-react";
import ApiRes from "../../api/Reservation";
import ApiPet from "../../api/Pet";
import ApiZone from "../../api/Zone";
import dateFormat from "dateformat";
import getDataServices from "../../data/servicesAppData";
import NewPet from "../../components/pet/AddPetComponent";
import ListCombo from  "../../components/county/ListComboCounty";

class AppStep3Component extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            address : '',
            blockDesc : '',
            date : '',
            name : '',
            date_of_birth : '',
            race_id : '',
            customer_id : '',
            petRaces : [],
            pets : [],
            services_zone : [],
            service_id : {},
            modalDelService : false,
            modalService : false,
            product_price : '',
            modalPet : false,
            selectservices : [] ,
            item_delete : '',
            successSave : false,
            customerAdd_id : ''

        }
        this.savePet = this.savePet.bind(this);
        this.getAvalilabitlity = this.getAvalilabitlity.bind(this);
        this.getProductPrice = this.getProductPrice.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {
        
        this.setState({date : dateFormat(window.localStorage.getItem("dateSel"),"yyyy-mm-dd"), blockDesc : window.localStorage.getItem("blockSel") });
        this.setState({address : window.localStorage.getItem("appDescCustomerAdd")});
        this.loadServicesbyZone(window.localStorage.getItem('seletedZone'));
        this.setState({customerAdd_id : window.localStorage.getItem("appIdCustomerAdd")});
        //this.loadpetsbyCustomer(window.localStorage.getItem("appCustomerId"));
        this.reloadPetList();
 
    } 
   
    loadpetsbyCustomer(customerId) {
        ApiPet.fetchPetsByCustomer(customerId)
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({pets: res.data.result});

        });

    }


    loadServicesbyZone(zone_id) {
            
           ApiZone.fetchServicesByZone(zone_id)
            .then((res) => {
                console.log('Size SERVICES ZONE RESULT : ',res);
                this.setState({services_zone: res.data.result});

            });
    }  


    reloadPetList() {
        ApiPet.fetchPets()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({pets: res.data.result});

            });
    }

    getProductPrice(id_product) {
        //window.localStorage.setItem('seletedAdd', this.state.addresses.filter(add => add.id == this.state.address_id));
        const addArr = this.state.services_zone;

     /*   for (const [index, value] of addArr.entries()) {
            

            if (value.id == id_product){
                console.log("I Found It ********* " , value.id )
                //this.setState({product_price : value.baseCost})
                
            }
        }  */

    }

    addServiceList() {

        let id_product = this.state.service_id;


        
        
        if ((this.state.service_id !== '') && (this.state.service_id !== 'XX')) {
            
            const addArr = this.state.services_zone;
            //console.log('ARR_VALUE ===> ', addArr);

            for (const [index, value] of addArr.entries()) {
                
    
                if (value.product.id == id_product){
                    //console.log("I Found It ********* " , value )
                    //this.setState({product_price : value.baseCost})

                    this.setState(prevState => ({
                        selectservices: [...prevState.selectservices,value]
                    }))

                   // this.setState({modalService : !this.state.modalService});
                   this.setState({service_id: ''});
                   this.setState({product_price : ''});


                    
                }
            }

        }

    }


    cancelAddService(){
        this.setState({modalService : !this.state.modalService});
    }

    cancelDelService(){
        this.setState({modalDelService : !this.state.modalDelService});
    }    

    loadServiceAvailable() {

        this.setState({modalService : !this.state.modalService});

    }

    cancelAddPet(){
        this.setState({modalPet : !this.state.modalPet});
    }  
    
    
    onContinuePetClick = (e) => {

        this.reloadPetList();
        this.setState({pet_id : ''})
        this.setState({modalPet : !this.state.modalPet});
        this.setState({pet_id : window.localStorage.getItem("newpet_id")})

    }

    loadOneServiceAppoiment(id) {
        
        console.log('SERVICE TO DELETE : ', id);
        this.setState({item_delete : id})
        this.setState({modalDelService : !this.state.modalDelService});
        

    }    


    processDeleteService(){
         
        console.log("Item to Delete", this.state.item_delete); 
        var array = [...this.state.selectservices]; // make a separate copy of the array
        var index = array.indexOf(this.state.item_delete)
        if (index !== -1) {
          console.log("ELIMINADO....")   
          array.splice(index, 1);
          this.setState({selectservices: array});
        }
           
    }    
    
    saveReservation = (e) => {
   
        let reservation = {customer_id : window.localStorage.getItem("appCustomerId"), pet_id : this.state.pet_id , customer_address_id :  window.localStorage.getItem("appCityId"), 
                            product_zone_id : "1", appointment_date : this.state.date, appointment_time : this.state.blockDesc, total_base_cost : "300", products : this.state.selectservices,
                            employee_id : "", truck_id : "", notes : "",status : "PENDING_APPROVAL"}
        
        console.log("NEW RESERVATION *********" , reservation )
                            ApiRes.bookReservation(reservation)
                            .then(res => {
                                this.setState({successSave:true}); 
                               console.log("SI GUARDO ***********************************")
                            });

    
    }    
    

    savePet = (e) => {
        e.preventDefault();
        let pet = {name: this.state.name, date_of_birth : this.state.date_of_birth, height : this.state.height, weight : this.state.weight, race_id : this.state.race_id, customer_id : this.state.customer_id };
        Pet.addPet(pet)
            .then(res => {
                
                this.setState({message : 'Pet Added successfully.'});
                this.props.history.push('/pets');
            });
    }

    getAvalilabitlity() {
        this.props.history.push('/appoiment/step2');
    }

    newAppoiment() {
        this.props.history.push('/list-customers');
    }


    cancelAdd() {
        this.props.history.push('/customer/pets');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        console.log('VALUE CHANGE : ' , e.target.value);
        if (e.target.name === 'service_id' ){
            this.getProductPrice(e.target.value);
        }

        if ((e.target.name === 'pet_id' ) && (e.target.value === 'new' )) {
            
            this.setState({modalPet : !this.state.modalPet});
            window.localStorage.setItem('originRequest', 'Admin');

        }
        
    }    

    render() {
        const referralData = getDataServices();
        const vService =  this.state.modalService;
        const vShowDelService =  this.state.modalDelService;
        const petMd = this.state.modalPet;
        const vshow = this.state.successSave;
        return(
            <div>

                {!vshow && 
                <div>


                 {/* --------------------------- MODAL WINDOW TO ADD NEW PET ------------------------------------------------------------------- */}
                 
                 <div>
                        <Modal open={petMd} toggle={this.onContinuePetClick}>
                        <ModalHeader>Add new customer pet</ModalHeader>
                        <ModalBody>                 <Row>
    

                                                            <NewPet />

                                                    
                                                    
                                                    </Row>
                                                    <Row>
                                                        <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.onContinuePetClick()}>
                                                            <i className="material-icons">add</i> Continue
                                                        </Button> 
                                                    </Row>                                                  


                        </ModalBody>
                    </Modal>
                </div>


                 {/* ---------------------------MODAL WINDOW TO ADD NEW PET  ---------------------------------------------------------------- */}

                {/********************************************** * *****************  ****************************************************/}
                {/********************************************** * MODAL ADD SERVICE  ****************************************************/}
                {/********************************************** * *****************  ****************************************************/}

                <div>
                    <Modal open={vService} toggle={this.addService}>
                        <ModalHeader>Services Details</ModalHeader>
                        <ModalBody>      
     
                                            <Form>
                                                    <Row>
                                                   
                                                    <Col md="9" className="form-group">
                                                        <label htmlFor="cboRace">Services</label>
                                                        
                                                            <FormSelect value={this.state.service_id} name="service_id" onChange={this.onChange}>
                                                                    <option key="XX"  value="" ></option>
                                                                    {
                                                                
                                                                            this.state.services_zone.map(
                                                                        p =>

                                                                                <option key={p.product.id} value={p.product.id} >{p.product.name + ' -   Price : ' +  p.product.baseCost}</option>

                                                                            )
                                                                            
                                                                    }
                                                            </FormSelect>    
                                                
                                                    </Col> 

                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="txtPrice">Price</label>
                                                            <FormInput
                                                            id="txtProductPrice"
                                                            type="text"
                                                            name = "product_price"
                                                            value={this.state.product_price} onChange={this.onChange}
                                                         />
                                                    </Col>                                                    


                                                    <Col md="12" className="form-group">
                                                        <label htmlFor="feDescription">Notes</label>
                                                        <FormTextarea id="feDescription" rows="5" />
                                                    </Col>            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.addServiceList()}>
                                                            Save
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAddService()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                              

                        </ModalBody>
                    </Modal>
                </div> 

                {/********************************************** * *****************  ****************************************************/}
                {/********************************************** * MODAL DELETE SERVICE  ****************************************************/}
                {/********************************************** * *****************  ****************************************************/}

                <div>
                    <Modal open={vShowDelService} toggle={this.processDeleteService}>
                        <ModalHeader>Delete Service</ModalHeader>
                        <ModalBody>      
                    
                                        <div>      
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Ae you you want to delete this service..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.processDeleteService()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelService()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         
                                      </div>
   
                        </ModalBody>
                    </Modal>
                </div> 


                <h6 className="text-center">Select Service Details</h6>

                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>

                        

                        <Col lg="8" md="12" sm="12" className="mb-4">
                            <Card small>
                                <CardHeader className="border-bottom">
                                <h6 className="m-0">Schedule and Address</h6>
                                <div className="block-handle" />
                                </CardHeader>

                                <CardBody className="p-0">
                                     <Col sm="9" md={{ size: 9, offset: 3 }} >
                                            <Form>
                                            <Row form>

                                     
                                            <Col md="3" className="form-group">
                                                <label htmlFor="txtZipCode">Date Selected</label>
                                                <FormInput
                                                id="txtDate"
                                                type="text"
                                                name = "date"
                                                placeholder=""
                                                disabled
                                                value={this.state.date} onChange={this.onChange}
                                                />
                                            </Col> 

                                            
                                            <Col md="6" className="form-group">
                                                <label htmlFor="txtZipCode">Customer Name</label>
                                                <FormInput
                                                id="txtBlock"
                                                type="text"
                                                name = "blockDesc"
                                                placeholder=""
                                                disabled
                                                value={this.state.blockDesc} onChange={this.onChange}
                                                />
                                            </Col>

                                            <Col md="9" className="form-group">
                                                <label htmlFor="txtZipCode">Addres Selected</label>
                                                <FormInput
                                                id="txtAddress"
                                                type="text"
                                                name = "address"
                                                placeholder=""
                                                disabled
                                                value={this.state.address} onChange={this.onChange}
                                                />
                                            </Col>
                                                            

                                            <Col md="9" className="form-group">
                                            <label htmlFor="cboRace">Select Pet</label>
                                              
                                                <FormSelect value={this.state.pet_id} name="pet_id" onChange={this.onChange}>
                                                <option key="XX"  value="" ></option>
                                                        {
                                                    
                                                                this.state.pets.map(
                                                            p =>

                                                                    <option key={p.id} value={p.id} >{p.name}</option>

                                                                )
                                                                
                                                        }
                                                <option key="new"  value="new" > + Add New ... </option>        
                                                </FormSelect>    
                                                

                                            </Col>

                                                   
                            
                                        

                                        </Row>

 
                                        </Form>
                                    </Col>                                 

                                </CardBody>

                                <CardFooter className="border-top">
                                <Row>
                                    {/* Time Span */}
                                    <Col>
                                            <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveReservation}>
                                                Save and Book
                                            </Button>  
                                            <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                                Cancel
                                            </Button> 
                                    </Col>

                                    {/* View Full Report */}
                                    <Col className="text-right view-report">
                                    {/* eslint-disable-next-line */}
                                    <a href="#">Details &rarr;</a>
                                    </Col>
                                </Row>
                                </CardFooter>
                            </Card>                           

                         </Col>

                         <Col lg="4" md="6" sm="6" className="mb-4">

                            <Card small>
                                <CardHeader className="border-bottom">
                                <h6 className="m-0">Services Added</h6>
                                <div className="block-handle" />
                                </CardHeader>

                                <CardBody className="p-0">
                                <ListGroup small flush className="list-group-small">
                                    {this.state.selectservices.map((item, idx) => (
                                    <ListGroupItem key={idx} className="d-flex px-3">
                                        <span className="text-semibold text-fiord-blue">{item.product.name}</span>
                                        <span className="ml-auto text-right text-semibold text-reagent-gray">
                                        {item.value}
                                        </span>
                                        <span className="text-semibold text-fiord-blue">  <Button theme="white" onClick={() => this.loadOneServiceAppoiment(item.id)}  >
                                                    <i className="material-icons">&#xE872;</i>
                                                </Button></span>
                                    </ListGroupItem>
                                    ))}
                                </ListGroup>
                                </CardBody>

                                <CardFooter className="border-top">
                                <Row>
                                    {/* Time Span */}
                                    <Col>

                                        <Col>
                                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.loadServiceAvailable()}>
                                                    Add Service
                                                </Button>  

                                        </Col>

                                    </Col>

                                    {/* View Full Report */}
                                    <Col className="text-right view-report">
                                    {/* eslint-disable-next-line */}
                                    <a href="#">Full report &rarr;</a>
                                    </Col>
                                </Row>
                                </CardFooter>
                            </Card>
                         </Col>



                        </Row>
                        </ListGroupItem>
                    </ListGroup>

   
            </div>
            }


            {vshow && 


                <div>
                <ListGroup flush>
                    <ListGroupItem className="p-3">
                        <Row>
                        <Col>
                                    <h2>Congratulations!</h2>
                                    <h3>The Appoiment has been Reserved</h3>
                                    <p>Customer Will receive a email with appoiment confimation </p>
                                    <Button pill onClick={() => this.newAppoiment()}>&larr; Book New Appoiment</Button>

                        </Col>
                        </Row>  
                        </ListGroupItem>
                </ListGroup>                            


                </div>                 

            }

        </div>    
        );
    }
}

export default AppStep3Component;