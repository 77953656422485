export default () => [
    {
      id: 'default',
      name: ' '
    },
  
    {
      id: '1',
      name: 'Mastercad'
    },
  
    {
      id: '2',
      name: 'Visa'
    },
  
    {
      id: '3',
      name: 'Cash'
    },
  
    
    
  ];