import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/zone';

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}

class Zone {

    fetchZones() {
        return connAxios.get(USER_API_BASE_URL+'/listAll');
    }

    fetchZoneById(zoneId) {
        return connAxios.get(USER_API_BASE_URL + '/' + zoneId);
    }

    deleteZone(zone) {
        return connAxios.put(USER_API_BASE_URL + '/update', zone);
    }

    addZone(zone) {
        return connAxios.post(USER_API_BASE_URL+'/add', zone);
    }

    editZone(zone) {
        return connAxios.put(USER_API_BASE_URL + '/update', zone);
    }

    fetchCitiesByZone(zoneId) {
        return connAxios.get(USER_API_BASE_URL + "/"+ zoneId + '/cities');
    } 
    
    fetchServicesByZone(zoneId) {

        return connAxios.get(USER_API_BASE_URL + "/"+ zoneId + '/products');
    }  
    
    addCityZone(cityZone){
        return connAxios.post(USER_API_BASE_URL + '/addCity', cityZone); 
    }

    deleteCityZone(cityZone){
        return connAxios.put(USER_API_BASE_URL + '/deleteCity', cityZone); 
    }    

    addServiceZone(servZone){
        return connAxios.post(USER_API_BASE_URL + '/addProduct', servZone); 
    }

    deleteServiceZone(servZone){
        return connAxios.put('/product-zone/update',servZone); 
    } 

    getCalendarData(zone_id) {
        return connAxios.get(USER_API_BASE_URL+'/calendar?zoneId=' + zone_id );
    }

    addSch(zone) {
        return connAxios.post(process.env.REACT_APP_API_ENDPOINT  + '/schedules/add', zone);
    }

    fetchSchByZone(zoneId) {
        return connAxios.get(USER_API_BASE_URL + "/"+ zoneId + '/schedules');
    } 

    fetchTimesByZone(zoneId, date) {
        return connAxios.get(USER_API_BASE_URL + "/times?zoneId="+ zoneId + '&date='+date);
    } 


}

export default new Zone();