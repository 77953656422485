import React, { Component } from 'react'
import { Button, Col,  Modal, ModalBody, ModalHeader, Row, Form } from "shards-react";
import Zone from "../../api/Zone";
import dateFormat from "dateformat";

class ListZoneComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            zones: [],
            message: null,
            modalDelete : false,
            delZone : {}
        }
        this.deleteZone = this.deleteZone.bind(this);
        this.editZone = this.editZone.bind(this);
        this.addZone = this.addZone.bind(this);
        this.reloadZoneList = this.reloadZoneList.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    componentDidMount() {
        this.reloadZoneList();
    }

    loadZone(zoneId) {
        this.setState({modalDelete : !this.state.modalDelete});
        Zone.fetchZoneById(zoneId)
            .then((res) => {
                let zone = res.data.zone;
                   this.setState({delzone : zone})
                   console.log('ZONE TO DELETE ' , zone)
            });
    }

    reloadZoneList() {
        Zone.fetchZones()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({zones: res.data.zones});

            });
    }


    deleteZone() {
        let dzone = {id : this.state.delzone.id, name : this.state.delzone.description, description: this.state.delzone.description, location_id: 1, status : 'INACTIVE'};
        Zone.deleteZone(dzone)
           .then(res => {
               this.setState({message : 'Zone deleted successfully.'});
               this.setState({zones: this.state.zones.filter(zone => zone.id !== dzone.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    }

    editZone(id) {
        window.localStorage.setItem("zoneId", id);
        this.props.history.push('/edit-zone');
    }

    addZone() {
        window.localStorage.removeItem("zoneId");
        this.props.history.push('/add-zone');
    }

    loadSetupZone(id) {
        window.localStorage.setItem("zoneId", id);
        this.props.history.push('/zonesetup');
    }    

    render() {
        //console.log('Size Zones : ' + this.state.zones.length);
        const deleteMd = this.state.modalDelete;
        return (
            <div>


                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addZone}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deleteZone()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div>  
                
                

              <div>

                <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addZone()}>
                    <i className="material-icons">add</i> New Zone
                    </Button>
                </Col>                 
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">Zone Description</th>
                            <th scope="col" className="border-0">Created Date</th>
                            <th scope="col" className="border-0">Action</th>
                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        {
                            this.state.zones.map(
                        zone =>
                                    <tr key={zone.id}>
                                        <td>{zone.description}</td>
                                        <td>{dateFormat(new Date(zone.createdDate), "dddd, mmmm dS, yyyy")}</td>
                                        
                                         <td>

                                            <Button outline size="sm" theme="success" className="mb-2 mr-1" onClick={() => this.loadSetupZone(zone.id)}>
                                                <i className="material-icons">settings;</i>
                                            </Button>                                           
                                            
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.loadZone(zone.id)}>
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>                                            
                                            
                                            <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editZone(zone.id)}>
                                                <i className="material-icons">&#xE254;</i>
                                            </Button>                                           
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                  
                </table>
                </div>

            </div>
        );
    }

}

export default ListZoneComponent;