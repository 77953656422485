export default function () {
  console.log('Global ROL ' , process.env.REACT_APP_USER);
  console.log('****** ROLE  MENU ====> : ' , sessionStorage.getItem('Role'));
  if (process.env.REACT_APP_USER !== 'User'){ 
  return [{
    title: 'Dashboards',
    items: [{
      title: 'Admin Dashboard',
      to: '/analytics',
      htmlBefore: '<i class="material-icons">&#xE917;</i>',
      htmlAfter: '',
    }],
  },
  {
    title: 'Reservations',
    items: [{ 
      title: 'Customers',
      htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
      open: false,
      items: [{
        title: 'List',
        to: '/list-customers',
      }, {
        title: 'Add New',
        to: '/create-customer',
      }, 
      ],
    },  {
      title: 'Pending Appoiments',
      htmlBefore: '<i class="material-icons">&#xE889;</i>',
      to: '/pending-appoiments',
    }],
  }, {
    title: 'Inqueries',
    items: [{
      title: 'Top Customers',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/components-overview',
    }, {
      title: 'Users By City',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/tables',
    }, {
      title: 'Appoiments Status',
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: '/blog-posts',
    }],
  }, {
    title: 'Accounting',
    items: [{
      title: 'Sales',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/sales',
    }, {
      title: 'List Bills',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/bills',
    },  {
      title: 'Sales Group by Employee',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/bills',
    } ,
    {
      title: 'Sales Group by Service',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/bills',
    }],
  },{
    title: 'Utils',
    items: [{
      title: 'Send SMS',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/components-overview',
    }, {
      title: 'Send Emails',
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: '/tables',
    }],
  },{
    title: 'Setup',
    items: [{
      title: 'Zones',
      htmlBefore: '<i class="material-icons">place</i>',
      to: '/zones',
    },
 {
      title: 'Citys',
      htmlBefore: '<i class="material-icons">business</i>',
      to: '/cities',
    }, {
      title: 'Services',
      htmlBefore: '<i class="material-icons">menu_open</i>',
      to: '/services',
    }, {
      title: 'Employes',
      htmlBefore: '<i class="material-icons">group</i>',
      to: '/employees',
    },
    {
      title: 'Pet Race',
      htmlBefore: '<i class="material-icons">pets</i>',
      to: '/petrace',
    },
    {
      title: 'Branch',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/branch',
    },
    {
      title: 'Trucks',
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: '/truck',
    }],
  },{
    title: 'Layouts',
    items: [{
      title: 'Header Nav',
      htmlBefore: '<i class="material-icons">view_day</i>',
      to: '/header-navigation',
    }, {
      title: 'Icon Sidebar',
      htmlBefore: '<i class="material-icons">&#xE251;</i>',
      to: '/icon-sidebar-nav',
    }],
  },
  ];
  } else {
    return [{
      title: 'Dashboards',
      items: [{
        title: 'User Dashboard',
        to: '/blog-overview',
        htmlBefore: '<i class="material-icons">edit</i>',
        htmlAfter: '',
      }],
    },{
      title: 'Profile',
      items: [{
        title: 'Edit My Profile',
        htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
        to: '/edit-user-profile',
      }, {
        title: 'My Pets',
        htmlBefore: '<i class="material-icons">pets</i>',
        to: '/customer/pets',
      },
      {
        title: 'Manage Address',
        htmlBefore: '<i class="material-icons">business</i>',
        to: '/customer/address',
      }],
    },
    {
      title: 'Reservation',
      items: [{
        title: 'Book Appoiment',
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: '/appoiment/step1',
      }, 
      {
        title: 'My Reservations',
        htmlBefore: '<i class="material-icons">&#xE889;</i>',
        to: '/customer/my-reservations',
      },
      {
        title: 'My Bills',
        htmlBefore: '<i class="material-icons">&#xE2C7;</i>',
        to: '/customer/my-bills',
      }],
    }, {
      title: 'Setup',
      items: [{
        title: 'Change Password',
        htmlBefore: '<i class="material-icons">settings_applications</i>',
        to: '/change-password',
      }],
    },
    ];    
  }
}
