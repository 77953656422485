import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PageTitle from "../components/common/PageTitle";
import ListPetRaceComponent from "../components/petRace/ListPetRaceComponent";
import AddPetRaceComponent from "../components/petRace/AddPetRaceComponent";
import EditPetRaceComponent from "../components/petRace/EditPetRaceComponent";

const PetRaceView = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Pet Race" subtitle="Pet Race Details" className="text-sm-left" />
    </Row>

    <Row>
      {/* Editor */}
      <Col>
        
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Details</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <Router>
                    <Switch>
                        
                        <Route path="/petrace" component={ListPetRaceComponent} />
                        <Route path="/add-petRace" component={AddPetRaceComponent} />
                        <Route path="/edit-petRace" component={EditPetRaceComponent} />
    
                    </Switch>

            </Router>
          </CardBody>
        </Card>


      </Col>

      {/* Sidebar Widgets */}

    </Row>
  </Container>
);

export default PetRaceView;