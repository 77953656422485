import React, { Component } from 'react'
import { Button, Col, Row, FormInput, Form, Card,CardBody,CardFooter,FormGroup,FormCheckbox } from "shards-react";
import { Link } from "react-router-dom";
import ApiRegister from "../../api/Register";


class VerifyAccountComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
            code: '',
            res : false,
            errorCode : false 
          
        }
        this.verifyAccount = this.verifyAccount.bind(this);
    }

    verifyAccount() {
        let emailget = window.localStorage.getItem("username");
        let rCode = {email : emailget , username : emailget,  verification_code : this.state.code}
        console.log("CODE TO SEND " , rCode);
        ApiRegister.verifyAccount(rCode)
        .then(res => {
            console.log("Reposnd " , res);
            if (res.data.result){
                this.setState({res : true});
            }else
            {
                this.setState({errorCode : true})
            }


        });

    }   


    gotoLogin() {
        this.props.history.push('/login');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({errorCode : false})
        
    }    

    render() {
        const vshow = this.state.res;
        //const vshow = true;
        return(
            <div>

                {!vshow && 
                <div>
                <Row noGutters className="h-100">
                <Col lg="3" md="5" className="auth-form mx-auto my-auto">
                    <Card>
                    <CardBody>
                        {/* Logo */}
                        <img
                        className="auth-form__logo d-table mx-auto mb-3"
                        src = {require("./../../images/Logo.jpeg")}
                        alt="Shards Dashboards - Register Template"
                        />

                        {/* Title */}
                        <h5 className="auth-form__title text-center mb-4">
                        Verify Email
                        </h5>

                        {/* Form Fields */}
                        <div>
                        <Form>
                        <FormGroup>
                            <label htmlFor="exampleInputEmail1">Verification Code</label>
                            <FormInput
                                type="number"
                                id="exampleInputEmail1"
                                placeholder="Verification Code"
                                invalid = {this.state.errorCode}
                                name = "code"
                                value = {this.state.code}
                                onChange={this.onChange}
                            />
                        </FormGroup>

                        


                        <Button
                            pill
                            theme="accent"
                            className="d-table mx-auto"
                            type="button"
                            onClick={() => this.verifyAccount()}
                        >    
                            Submit
                        </Button>
                        </Form>

                        </div>


                    </CardBody>

                    {/* Social Icons */}

                    </Card>

                    {/* Meta Details */}
                    <div className="auth-form__meta d-flex mt-4">
                    <Link to="/forgot-password">Forgot your password?</Link>
                    <Link to="/login" className="ml-auto">
                        Sign In?
                    </Link>
                    </div>
                </Col>
                </Row>
                </div>

                }

                {vshow && 
                <div>
                    <Row>
                    <Col lg="3">
                        <div className="error">
                                <div className="error__content">
                                    <h2>Congratulations!</h2>
                                    <h3>Your Account has been verified</h3>
                                    <p>Click button login to init your session and complete your profile</p>
                                    <Button pill onClick={() => this.gotoLogin()}>&larr; Login</Button>
                                </div>
                        </div>
                    </Col>
                    </Row>                   
                    
                </div> 

                }
            
            </div>



            
        );
    }
}

export default VerifyAccountComponent;