import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form } from "shards-react";
import ApiBranch from "../../api/Branch";

class ListBranchComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Branchs: [],
            message: null,
            modalDelete : false,
            delBranch : {}
        }
        this.deleteBranch = this.deleteBranch.bind(this);
        this.editBranch = this.editBranch.bind(this);
        this.addBranch = this.addBranch.bind(this);
        this.reloadBranchList = this.reloadBranchList.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    componentDidMount() {
        this.reloadBranchList();
    }



    reloadBranchList() {
        ApiBranch.fetchBranchs()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({Branchs: res.data.result});

            });
    }

    loadBranch(Branchid) {
        this.setState({modalDelete : !this.state.modalDelete});
        ApiBranch.fetchBranchById(Branchid)
            .then((res) => {
                let Branch = res.data.result;
                console.log("Branch TO EDIT " , res);
                this.setState({delBranch : Branch})
            });

            console.log("Branch STATE : " , this.state )
    }    


    deleteBranch() {
                                                                   
        let dBranch = {id : this.state.delBranch.id, name: this.state.delBranch.description, description: this.state.delBranch.name,  status : 'DELETE'};
        ApiBranch.deleteBranch(dBranch)
           .then(res => {
               this.setState({message : 'Branch deleted successfully.'});
               this.setState({Branchs: this.state.Branchs.filter(Branch => Branch.id !== dBranch.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    }    

    editBranch(id) {
        window.localStorage.setItem("BranchId", id);
        this.props.history.push('/edit-Branch');
    }

    addBranch() {
        window.localStorage.removeItem("BranchId");
        this.props.history.push('/add-Branch');
    }

    render() {
        const deleteMd = this.state.modalDelete;
        //console.log('Size cities : ' + this.state.cities.length);
        return (
            <div>

                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deleteBranch()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div> 



              <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addBranch()}>
                    <i className="material-icons">add</i> New Branch
                    </Button>
              </Col> 


              <div>
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">Name</th>
                            <th scope="col" className="border-0">Description</th>
                            <th scope="col" className="border-0">Action</th>

                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        {
                            this.state.Branchs.map(
                        Branch =>
                                    <tr key={Branch.id}>
                                        <td>{Branch.name}</td>
                                        <td>{Branch.description}</td>
                                         <td>
                                            
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.loadBranch(Branch.id)}>
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>                                            
                                            
                                            <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editBranch(Branch.id)}>
                                                <i className="material-icons">&#xE254;</i>
                                            </Button>                                           
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                  
                </table>
                </div>

            </div>
        );
    }

}

export default ListBranchComponent;