import axios from 'axios';
import connAxios from "./Conn"

//const USER_API_BASE_URL = 'http://localhost:8080';
const USER_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/reservation';

let config = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      }
}


class Truck {

    bookReservation(res) {
        return connAxios.post(USER_API_BASE_URL+"/add", res);
    }

    fetchAllAppoiments(status) {
        return connAxios.get(USER_API_BASE_URL+'/listAll?status='+status);
    }

    findOnebyid(id) {
        return connAxios.get(USER_API_BASE_URL + '/'+ id)
    }

    fecthServicesAppoiment(id){
        return connAxios.get(USER_API_BASE_URL + '/'+ id + '/products')
    }

    addServiceReservation(idRes, idServ){
        return connAxios.post(USER_API_BASE_URL +  '/'  + idRes + '/products/' + idServ );
    }

    deleteServiceReservation(idRes, idServ){
        return connAxios.delete(USER_API_BASE_URL +  '/'  + idRes + '/products/' + idServ);
    }


}

export default new Truck();