import React, { Component } from 'react'
import { Button, Col, Row, Modal, ModalBody, ModalHeader, FormInput, FormSelect, Container, Card, CardHeader, CardBody} from "shards-react";
import ApiZone from "../../api/Zone";
import ApiCity from "../../api/City";
import ApiService from "../../api/Service";
import getTableBlocks from "../../data/blocks";
import getTableDays from "../../data/days";

class SetupZoneComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            zones: [],
            zonesel_id : '',
            cityselect_id : '',
            citieszone : [],
            cities : [],
            serviceselect_id : '',
            serviceszone : [],
            services : [],
            serviceselect : [],
            priceselect : '',
            message: null,
            modalCity : false,
            modalService : false,
            modalSch : false,
            day_id : '',
            hour_id : ''

        };

        this.reloadZoneList = this.reloadZoneList.bind(this);
        this.reloadCityList = this.reloadCityList.bind(this);
        this.reloadServiceList = this.reloadServiceList.bind(this);
        this.addCity = this.addCity.bind(this);
        this.addService = this.addService.bind(this);
        this.addSchedule = this.addSchedule.bind(this);
        this.toggle = this.toggle.bind(this);
        this.insertCity = this.insertCity.bind(this);
        this.insertService = this.insertService.bind(this);
        this.getSelectService = this.getSelectService.bind(this);
        this.insertSchedule = this.insertSchedule.bind(this);
        
        
    }

    componentDidMount() {
        this.reloadZoneList();
        this.reloadCityList();
        this.reloadServiceList();
        this.setState({zonesel_id : localStorage.getItem("zoneId")});
        this.setState({ t_city: false });
        this.setState({ t_service: false });
        this.getCitiesByZone(localStorage.getItem("zoneId"));
        this.getServicesByZone(localStorage.getItem("zoneId"));
    }

    reloadCityList() {
        ApiCity.fetchCities()
            .then((res) => {
               console.log('Cities Available : ',res);
               this.setState({cities: res.data.result});

            });
    }

    reloadServiceList() {
        console.log('LOADING SERVICES...')
        ApiService.fetchServices()
            .then((res) => {
                console.log('Services Available : ',res);
                  this.setState({services: res.data.result});

            });
    }   

  

    reloadZoneList() {
        ApiZone.fetchZones()
            .then((res) => {
                console.log('Zones List : ',res);
                this.setState({zones: res.data.zones});

            });
    }

    getCitiesByZone(zoneId) {
        console.log('Cities --> ID ZONE : ' + zoneId);
        ApiZone.fetchCitiesByZone(zoneId)
            .then((res) => {
                console.log('Cities by Zone : ',res);
                this.setState({citieszone: res.data.result});
             

            });
    }  
    

    getServicesByZone(zoneId) {
        
        console.log('Services by Zone , Entering'); 
        ApiZone.fetchServicesByZone(zoneId)
            .then((res) => {
                console.log('Services by Zone : ', res);

               this.setState({serviceszone: res.data.result});
                
        });
    } 

    getSchedulesByZone(zoneId) {
        
        console.log('Services by Zone , Entering'); 
        ApiZone.fetchServicesByZone(zoneId)
            .then((res) => {
                console.log('Services by Zone : ', res);

               // this.setState({serviceszone: res.data.result});
                
        });
    }    
    
    getSelectService(serviceId) {
        
        console.log('SERVICE SELECTED : ', serviceId );
        //this.setState({serviceselect: this.state.services.filter(service => service.id == serviceId)});
        //this.setState({serviceselect: this.state.services});
        this.setState({serviceselect_id : serviceId });
        this.setState({message : 'THIS IS THE FUCKING SERVICE : ' + serviceId});
        console.log('SERVICE SELECTED OBJECT : ', this.state);
    
        
        this.setState({priceselect : 0});

    }


    deleteZone(zoneId) {
        ApiZone.deleteZone(zoneId)
           .then(res => {
               this.setState({message : 'Zone deleted successfully.'});
               this.setState({zones: this.state.zones.filter(zone => zone.id !== zoneId)});
           })

    }

    editZone(id) {
        window.localStorage.setItem("zoneId", id);
        this.props.history.push('/edit-zone');
    }

    addZone() {
        window.localStorage.removeItem("zoneId");
        this.props.history.push('/add-zone');
    }

    addCity() {
        this.setState({modalCity : !this.state.modalCity});
        console.log(this.state);
    }

    addService() {
        this.setState({modalService : !this.state.modalService});

    }  
    
    addSchedule() {
        this.setState({modalSch : !this.state.modalSch});

    }     

    insertCity() {
        
        let cityZone ={city_id : this.state.cityselect_id, zone_id : this.state.zonesel_id}
        console.log('CITY TO INSERT ', cityZone); 
        ApiZone.addCityZone(cityZone)
            .then(res => {
                this.setState({message : 'City Added successfully.'});
                this.reloadCityList();
                this.getCitiesByZone(this.state.zonesel_id);
                this.setState({modalCity : false});
            });
    }

    insertService() {
        console.log('SERVICE SELECTED OBJECT INSERT : ', this.state);
        console.log('SERVICE TO INSERT, ', this.state.serviceselect_id);
        
        let serviceZone ={product_id : this.state.serviceselect_id, zone_id : this.state.zonesel_id, price : this.state.priceselect}
        ApiZone.addServiceZone(serviceZone)
            .then(res => {
                this.setState({message : 'Service Added successfully.'});
                this.reloadServiceList();
                this.getServicesByZone(this.state.zonesel_id);
                this.setState({modalService: false});
            });
    }

    insertSchedule() {


         console.log('*** ACTUAL STATE ****', this.state);
        
        let schZone ={zone_id : this.state.zonesel_id, day : this.state.day_id, time : this.state.hour_id, quote : this.state.spaces}
        console.log('SCH TO INSERT', schZone );
        ApiZone.addSch(schZone)
            .then(res => {
                this.setState({message : 'Schedule Added successfully.'});
                //this.reloadServiceList();
                //this.getServicesByZone(this.state.zonesel_id);
                this.setState({modalSch: false});
        });



    }

    
    deleteCityZone(cityId){
        let cityZone ={city_id : cityId, zone_id : this.state.zonesel_id, status : 'DELETE'}
        ApiZone.deleteCityZone(cityZone)
           .then(res => {
               this.setState({message : 'City Deleted Successfully.'});
               this.setState({citieszone: this.state.citieszone.filter(city => city.id !== cityId)});
           })

    }

    deleteServiceZone(serviceId){
        let serviceZone ={product_id : serviceId, zone_id : this.state.zonesel_id, price : 0.00}
        ApiZone.deleteServiceZone(serviceZone)
           .then(res => {
               this.setState({message : 'Service Deleted Successfully.'});
               this.setState({citieszone: this.state.serviceszone.filter(zone => zone.id !== serviceId)});
           })

    }


    toggle() {
        this.setState({modalCity : !this.state.modalCity});
    }   

    goBack() {
        this.props.history.push('/zones');
    }  

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        console.log('ACTUAL STATE ON CHANGE ', this.state);
        if (e.target.name == 'zonesel_id') {
            this.setState({ t_city: false });
            this.setState({ t_service: false });
             this.getCitiesByZone(e.target.value);
             this.getServicesByZone(e.target.value);
        } 
        
        if (e.target.name == 'serviceselect_id') {
            
            this.getSelectService(e.target.value);

            //console.log('SERVICES : ' ,  this.state.services);
            //this.setState({serviceselect: this.state.services.filter(service => service.id == e.target.value)});
            //console.log('SERVICE SELECT FILTERED : ',  this.state.serviceselect);
            //this.setState({priceselect : this.state.serviceselect.product.baseCost})
        }   
         

    }

    
    render() {
        const cityMd = this.state.modalCity;
        const serviceMd = this.state.modalService;
        const schMd = this.state.modalSch;
        const v_city = this.state.t_city;
        const v_days = getTableDays();
        const v_hours = getTableBlocks();
        return (
            <div>
                 
                 {/* --------------------------- MODAL WINDOW TO INSERT CITY ---------------------------------------------------------------- */}
                 
                 <div>
                        <Modal open={cityMd} toggle={this.addCity}>
                        <ModalHeader>Add City</ModalHeader>
                        <ModalBody>                 <Row>
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Select City</label>     
                                                    <FormSelect value={this.state.cityselect_id} name="cityselect_id" onChange={this.onChange}>
                                                            <option key="0" value="0" ></option>
                                                            {                                                      
                                                                    this.state.cities.map(city =>

                                                                        <option key={city.id} value={city.id} >{city.name}</option>

                                                                    )
                                                             }
                                                    </FormSelect>
                                                    </Col>
                                                    </Row>
                                                    <Row>
                                                        <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={this.insertCity}>
                                                            <i className="material-icons">add</i> Insert
                                                        </Button> 
                                                    </Row>                                                  


                        </ModalBody>
                    </Modal>
                </div>


                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={serviceMd} toggle={this.addService}>
                        <ModalHeader>Add Service</ModalHeader>
                        <ModalBody>                 <Row>
                                                    
                                                    <Col md="6" className="form-group">  
                                                    <label htmlFor="cboCounty">Select Service</label>   
                                                        
                                                        <FormSelect value={this.state.serviceselect_id} name="serviceselect_id" onChange={this.onChange}>
                                                                <option key="0" value="0" ></option>
                                                                {                                                      
                                                                        this.state.services.map(service =>

                                                                            <option key={service.id} value={service.id} >{service.name}</option>

                                                                        )
                                                                }
                                                        </FormSelect>
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="baseCost">Base Cost</label>
                                                         <FormInput
                                                         id="txtCost"
                                                         type="number"
                                                         name = "priceselect"
                                                         placeholder="Price"
                                                         value={this.state.priceselect} onChange={this.onChange}
                                                    />
                                                    </Col>          


                                                    </Row>
                                                    <Row>
                                                        <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={this.insertService}>
                                                            <i className="material-icons">add</i> Insert
                                                        </Button> 
                                                    </Row>                                                  


                        </ModalBody>
                    </Modal>
                </div>   


                 {/* --------------------------- MODAL WINDOW TO ADD SCHEDIULES ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={schMd} toggle={this.addSchedule}>
                        <ModalHeader>Add Schedule</ModalHeader>
                        <ModalBody>                 <Row>
                                                    
                                                    <Col md="6" className="form-group">  
                                                    <label htmlFor="cboDay">Select Day</label>   
                                                        <option key="0" value="0" ></option>
                                                        <FormSelect value={this.state.day_id} name="day_id" onChange={this.onChange}>
                                                                {                                                      
                                                                        v_days.map(d =>

                                                                            <option key={d.id} value={d.name} >{d.name}</option>

                                                                        )
                                                                }
                                                        </FormSelect>
                                                    </Col>
                                                    
                                                    <Col md="6" className="form-group">  
                                                    <label htmlFor="cboHours">Select Hours</label>   
                                                        <option key="0" value="0" ></option>
                                                        <FormSelect value={this.state.hour_id} name="hour_id" onChange={this.onChange}>
                                                                {                                                      
                                                                        v_hours.map(h =>

                                                                            <option key={h.id} value={h.start} >{h.start}</option>

                                                                        )
                                                                }
                                                        </FormSelect>
                                                    </Col>

                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="spaces">quote</label>
                                                         <FormInput
                                                         id="spaces"
                                                         type="number"
                                                         name = "spaces"
                                                         placeholder="quote"
                                                         value={this.state.spaces} onChange={this.onChange}
                                                    />
                                                    </Col>          


                                                    </Row>
                                                    <Row>
                                                        <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={this.insertSchedule}>
                                                            <i className="material-icons">add</i> Insert
                                                        </Button> 
                                                    </Row>                                                  


                        </ModalBody>
                    </Modal>
                </div>    

                
                                           


                <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }} className="form-group">
                                                    <label htmlFor="cboCounty">Select Zone</label>
                                                    <FormSelect value={this.state.zonesel_id} name="zonesel_id" onChange={this.onChange} disabled>
                                                           <option key="0" value="0" ></option>
                                                            {
                                                        
                                                                    this.state.zones.map(
                                                                zone =>

                                                                        <option key={zone.id} value={zone.id} >{zone.description}</option>

                                                                    )
                                                                    
                                                            }
                                                    </FormSelect>  
                                                                                
                        </Col>
                </Row>

                <Row>
                    <Col lg="8" md={{ size: 9, offset: 2 }}>




                        <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Cities</h6>
                            <Col sm="4" className="d-flex ml-auto my-auto">
                                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={this.addCity}>
                                    <i className="material-icons">add</i> Add City
                                    </Button>
                            </Col> 
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                <th scope="col" className="border-0">
                                    #
                                </th>
                                <th scope="col" className="border-0">
                                    City Name
                                </th>
                                <th scope="col" className="border-0">
                                    County
                                </th>
                                <th scope="col" className="border-0">
                                    Action
                                </th>
                                </tr>
                            </thead>

                            <tbody>
                                {  
                                this.state.citieszone.map(
                                    c =>   
                                <tr key={c.city.id}>
                                
                                        <td></td>
                                        <td>{c.city.name}</td>
                                        <td>{c.city.county.name}</td>
                                        <td>
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.deleteCityZone(c.city.id)}>
                                                                        <i className="material-icons">&#xE872;</i>
                                            </Button> 

                                        </td>
                                
                                </tr>
                                )
                                }   
                            </tbody>
 
                            </table>
                        </CardBody>
                        </Card>
                    </Col>


                </Row>

                <Row>
                    <Col lg="8" md={{ size: 9, offset: 2 }}>
                                <Card small className="mb-4 overflow-hidden">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Services</h6>
                                    <Col sm="4" className="d-flex ml-auto my-auto">
                                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addService()}>
                                    <i className="material-icons">add</i> Add Service
                                    </Button>
                                </Col> 
                                </CardHeader>
                                <CardBody className="p-0 pb-3">
                                    <table className="table mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                        <th scope="col" className="border-0">
                                            #
                                        </th>
                                        <th scope="col" className="border-0">
                                            Service Name
                                        </th>
                                        <th scope="col" className="border-0">
                                            Price
                                        </th>
                                        <th scope="col" className="border-0">
                                            Action
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {  
                                                this.state.serviceszone.map(
                                                    c =>   
                                                <tr key={c.product.id}>
                                                
                                                        <td></td>
                                                        <td>{c.product.name}</td>
                                                        <td>{c.product.baseCost}</td>
                                                        <td>
                                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.deleteServiceZone(c.product.id)}>
                                                                                        <i className="material-icons">&#xE872;</i>
                                                            </Button> 

                                                        </td>
                                                
                                                </tr>
                                                )
                                            } 
                                    </tbody>
                                    </table>
                                </CardBody>
                                </Card>
                            </Col>
                </Row>

                <Row>
                    <Col lg="8" md={{ size: 9, offset: 2 }}>
                                <Card small className="mb-4 overflow-hidden">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Schedules</h6>
                                    <Col sm="4" className="d-flex ml-auto my-auto">
                                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addSchedule()}>
                                    <i className="material-icons">add</i> Add Schedule
                                    </Button>
                                </Col> 
                                </CardHeader>
                                <CardBody className="p-0 pb-3">
                                    <table className="table mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                        <th scope="col" className="border-0">
                                            #
                                        </th>
                                        <th scope="col" className="border-0">
                                            Day
                                        </th>
                                        <th scope="col" className="border-0">
                                            Hours
                                        </th>
                                        <th scope="col" className="border-0">
                                            Quote
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {  
                                                this.state.serviceszone.map(
                                                    c =>   
                                                <tr key={c.product.id}>
                                                
                                                        <td></td>
                                                        <td>{c.product.name}</td>
                                                        <td>{c.product.baseCost}</td>
                                                        <td>
                                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.deleteServiceZone(c.product.id)}>
                                                                                        <i className="material-icons">&#xE872;</i>
                                                            </Button> 

                                                        </td>
                                                
                                                </tr>
                                                )
                                            } 
                                    </tbody>
                                    </table>
                                </CardBody>
                                </Card>
                            </Col>


                </Row>  

                            <Row>
                                <Col lg="8" md={{ size: 9, offset: 2 }}>
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.goBack()}>
                                    Go Back
                                </Button>  
                                </Col>
                            </Row>              

            </div>
        );
    }

}

export default SetupZoneComponent;