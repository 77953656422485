import React, { Component } from 'react'
import Pet from "../../api/Pet";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect, Card, CardBody} from "shards-react";
import ApiPetRace from "../../api/PetRace";
import getPetTypes from "../../data/tableValues";

class AddPetComponent extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            name : '',
            date_of_birth : '',
            height : '',
            weight : '',
            race_id : '',
            pet_type : '',
            customer_id : '',
            petRaces : [],
            petTypes : [],
            errors : {name : false, dob_blank : false, dob_g : false, h : false, w : false, pet_type : false, race : false}, 
            sucessSaved : false,
            vshow : false,
            
        }
        this.savePet = this.savePet.bind(this);
        this.loadpetTypes = this.loadpetTypes.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {
        this.racesList();
        this.loadpetTypes();
        this.setState({customer_id : sessionStorage.getItem("idCustomer")})
        
        let originRe =  window.localStorage.getItem("originRequest");
       
        if (originRe !== 'Admin') {
            this.setState({customer_id : window.localStorage.getItem("idCustomer")})
        }    

        
    } 

    loadpetTypes() {
        const types = getPetTypes();
        this.state.petTypes = types;

    }
   

    racesList() {
        ApiPetRace.fetchPetRaces()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({petRaces: res.data.result});

            });
    }  
    

    savePet = (e) => {

        e.preventDefault();
        let d = new Date().getDate();
        let formIsValid = true; 
        let name_f = this.state.name;
        let dob_f = this.state.date_of_birth;
        let height_f = this.state.height;
        let weight_f = this.state.weight;
        let pettype_f = this.state.pet_type;

        if (typeof name_f === "undefined"){
            formIsValid = false;
            this.setState.errors({name : true})
        }    

        if (typeof dob_f === "undefined") {
            formIsValid = false;
            this.setState.errors({dob_blank : true})
        } else {
            if (dob_f > dob_f) {
                formIsValid = false;
                this.setState.errors({dob_blank : true})
            }
        }


        if (formIsValid){
                
            let pet = {name: this.state.name, date_of_birth : this.state.date_of_birth, height : this.state.height, weight : this.state.weight, race_id : this.state.race_id, pet_type : this.state.petType_id, customer_id : this.state.customer_id };
            console.log("PET TO SAVE : " ,  pet);
            Pet.addPet(pet)
                .then(res => {
                    let originRe =  window.localStorage.getItem("originRequest");
                    this.setState({message : 'Pet Added successfully.'});
                    if (originRe !== 'Admin') {
                        this.props.history.push('/customer/pets');
                    }else {
                        this.setState({vshow : true}); 
                        console.log('NEW PET :' ,res )
                        window.localStorage.setItem('newpet_id', res.data.result.id)
                    }
                });

        }    
    }

    cancelAdd() {
        let originRe =  window.localStorage.getItem("originRequest");
        if ( originRe !== 'Admin') {
            this.props.history.push('/customer/pets');
        }
        
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        if  (e.target.name === 'petType_id'){
             this.racesList();
             this.setState({ petRaces : this.state.petRaces.filter(t => t.petType === e.target.value)})
        }
        

    }   

    render() {
        const vshow = this.state.vshow;
        return(

            <div>


            {!vshow &&
            <div>
                <h6 className="text-center">Add New Pet</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Pet Name</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    required
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="9" className="form-group">
                                    <label htmlFor="txtState">Date of Birth</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="date"
                                    name = "date_of_birth"
                                    placeholder=""
                                    value = {this.state.date_of_birth}
                                    onChange={this.onChange}
                                    />
                                </Col>    

                                <Col md="4" className="form-group">
                                    <label htmlFor="txtZipCode">Height</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "height"
                                    placeholder=""
                                    value={this.state.height} onChange={this.onChange}
                                    />
                                </Col> 

                                
                                <Col md="4" className="form-group">
                                    <label htmlFor="txtZipCode">Weight</label>
                                    <FormInput
                                    id="txtWeight"
                                    type="text"
                                    name = "weight"
                                    placeholder=""
                                    value={this.state.weight} onChange={this.onChange}
                                    />
                                </Col>      

                                <Col md="9" className="form-group">
                                    <label htmlFor="cboRace">Pet Type</label>
                                                                                   
                                    <FormSelect value={this.state.petType_id} name="petType_id" onChange={this.onChange}>
                                    
                                            {
                                        
                                        this.state.petTypes.map(
                                              
                                                petType =>

                                                        <option key={petType.id} value={petType.name} >{petType.name}</option>

                                                    )
                                                    
                                            }
                                    </FormSelect>  

                                </Col>                                                                                       
                                
                                <Col md="9" className="form-group">
                                   <label htmlFor="cboRace">Select Race</label> 
                                    
                                   
                                    <FormSelect value={this.state.race_id} name="race_id" onChange={this.onChange} required>
                                    <option key="XX"  value="" ></option>
                                            {
                                        
                                                    this.state.petRaces.map(
                                                race =>

                                                        <option key={race.id} value={race.id} >{race.name}</option>

                                                    )
                                                    
                                            }
                                    </FormSelect>    
                                     
                                </Col>

                             
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.savePet}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
            }


            {vshow && 
                <div>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                            <Col>

                                            <h3>Pet successfully Added</h3>
                                            <p>Please click continue to continue booking the appoiment </p>

                            </Col>
                            </Row>  
                            </ListGroupItem>
                    </ListGroup>                            

                    
                </div> 

            }

        </div>      

        );
    }
}

export default AddPetComponent;