import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SetupZoneComponent from "../components/zone/SetupZoneComponent";

const Tables = () => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Setup Zones" subtitle="Add Services and Cities" className="text-sm-left" />
    </Row>

    {/* Default Light Table */}
        <SetupZoneComponent />

  </Container>
);

export default Tables;
