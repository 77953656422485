import React, { Component } from 'react'
import ApiTruck from "../../api/Truck";
import ListCombo from  "../county/ListCombo"
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect  } from "shards-react";
import ApiBranch from "../../api/Branch";

class EditTruckComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            id : '',
            name : '',
            description : '',
            branches : [],
            branch_id : ''
        }
        this.saveTruck = this.saveTruck.bind(this);
        this.loadTruck = this.loadTruck.bind(this);
    }

    componentDidMount() {
        this.loadTruck();
        this.branchList();
    }

    
    branchList() {
        ApiBranch.fetchBranchs()
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({branches: res.data.result});

            });
    } 
    

    loadTruck() {
        
        ApiTruck.fetchTruckById(window.localStorage.getItem("TruckId"))
            .then((res) => {
                let Truck = res.data.result;
                console.log("Truck TO EDIT " , res);
                this.setState({
                    id : Truck.id,
                    name : Truck.name,
                    description : Truck.description,         
                })
            });

            console.log("Truck STATE : " , this.state )
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    saveTruck = (e) => {
        e.preventDefault();
        let Truck = {id : this.state.id , name: this.state.name, description : this.state.description };
        ApiTruck.editTruck(Truck)
            .then(res => {
                  this.props.history.push('/truck');
            });


    }

    cancelAdd() {
        this.props.history.push('/truck');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit Truck</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Truck Name</label>
                                    <FormInput
                                    id="txtName"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="9" className="form-group">
                                    <label htmlFor="txtZipCode">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder=""
                                    value={this.state.description} onChange={this.onChange}
                                    />
                                </Col> 

                                                    
                                
                                <Col md="9" className="form-group">
                                   <label htmlFor="cboRace">Select Branch</label>                            
 
                                <FormSelect value={this.state.branch_id} name="branch_id" onChange={this.onChange}>
                                        {
                                    
                                                this.state.branches.map(
                                            branch =>

                                                    <option key={branch.id} value={branch.id} >{branch.name}</option>

                                                )
                                                
                                        }
                                </FormSelect>   
                                </Col>                              
                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveTruck}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditTruckComponent;