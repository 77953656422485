import React from "react";
import ReactTable from "react-table";
import { NavLink } from "react-router-dom";
import FuzzySearch from "fuzzy-search";


import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  FormSelect,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardHeader,
  CardBody
} from "shards-react";

import PageTitle from "../common/PageTitle";
import getTableBlocks from "../../data/blocks";


class schedulesComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      tableData: []
    };

    this.searcher = null;

    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentWillMount() {
    const tableData = getTableBlocks();

    this.setState({
        ...this.state,
        tableData
      });



      this.searcher = new FuzzySearch(tableData, ["start", "end"], {
        caseSensitive: false
      });
   //const bl = getTableBlocks()

   //this.setState({blocks : bl});
  // console.log('BLOCKS', this.state.blocks)
   
 /*   this.setState({
      ...this.state,
      tableData
    });*/

    // Initialize the fuzzy searcher.
   /* this.searcher = new FuzzySearch(
      this.state.listCustomers ,
      ["name", "address", "city", "lastOpened"],
      { caseSensitive: false }
    ); */
  }


  /**
   * Mock method for editing items.
   * 
   * 
   */
  handlegetAppoiment(row) {
    this.props.history.push('/appoiment/step1');
    window.localStorage.setItem("appCustomerId", row.original.id);
    window.localStorage.setItem("appOrigin", '1');   // 1 = Admin   2 = Customer Side
 }

  handleItemView(row) {
     this.props.history.push('/view-customer-profile');
  }

  handleItemEdit(row) {
    this.props.history.push('/edit-user-profile');
    
  }

  /**
   * Mock method for deleting items.
   */
  handleItemDelete(row) {
    alert(`Deleting "${row.original.name}"!`);
  }

  /**
   * Changes the page size.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    this.setState({
      ...this.state,
      tableData: this.searcher.search(e.target.value)
    });
  }

  render() {
    const { tableData, pageSize, pageSizeOptions } = this.state;
    const tableColumns = [
      {
        Header: "",
        accessor: "icon",
        maxWidth: 60,
        sortable: false,
        Cell: row => (
          <div className="file-manager__item-icon" tabIndex="0" href="" onClick={() => this.handlegetAppoiment(row)} >
            <div>
              {row.original.type === "File" ? (
                <i className="material-icons">&#xE24D;</i>
              ) : (
                <i className="material-icons">&#xE2C7;</i>
              )}
            </div>
          </div>
        )
      },
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 500,
        Cell: row => (
          <div>
            <h5 className="file-manager__item-title">{row.original.start + ' ' +  row.original.end}</h5>
            <span className="file-manager__item-meta">
              Last Appoiment {row.original.lastOpened} ago
            </span>
          </div>
        )
      },
      {
        Header: "Home Phone",
        accessor: "cellPhone",
        maxWidth: 500,
        className: "text-center d-flex align-items-center"
      },
      {
        Header: "Cell Phone",
        accessor: "mobilPhone",
        maxWidth: 180,
        className: "text-center d-flex align-items-center"
      },
      {
        Header: "Entered Date",
        accessor: "createdAt",
        maxWidth: 180,
        className: "text-center d-flex align-items-center"
      },      
      {
        Header: "Actions",
        accessor: "actions",
        maxWidth: 300,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table ml-auto">
            <Button theme="white" onClick={() => this.handleItemView(row)}>
              <i className="material-icons">zoom_in</i>
            </Button>
            <Button theme="white" onClick={() => this.handleItemEdit(row)}>
              <i className="material-icons">&#xE254;</i>
            </Button>
            <Button theme="danger" onClick={() => this.handleItemDelete(row)}>
              <i className="material-icons">&#xE872;</i>
            </Button>
          </ButtonGroup>
        )
      }
    ];

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">

        </Row>

        <Card className="file-manager file-manager-list p-0">
          <CardHeader className="text-center p-0">
            {/* Filters */}
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    onChange={this.handlePageSizeChange}
                    value={this.state.pageSize}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>

            {/* Dropzone */}
            
          </CardHeader>

          {/* Data Table */}
          <CardBody className="p-0">
            <ReactTable
              columns={tableColumns}
              data={this.state.customers}
              pageSize={pageSize}
              showPageSizeOptions={false}
              resizable={false}
            />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default schedulesComponent;
