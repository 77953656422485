import React, { Component } from 'react'
import City from "../../api/City";
import ListComboCounty from  "../county/ListComboCounty"
import ListCombo from  "../county/ListCombo"
import CountyService from "../../service/CountyService"
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect, FormFeedback} from "shards-react";
import County from "../../api/County";

class AddCityComponent extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        this.state ={
            name:'',
            name_fail : false,
            description:'',
            zipCode:'',
            county_id:'',
            county_id_fail : false,
            zone_id: 1,
            counties : []
        }
        this.saveCity = this.saveCity.bind(this);
        //this.countiesList = this.countiesList.bind(this);
    }

    componentDidMount() {
        this.countiesList();
    } 
   

    countiesList() {
        County.fetchCounties()
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({counties: res.data.counties});

            });
    }  
    

    saveCity = (e) => {
        e.preventDefault();
        let city = {name: this.state.name, description : this.state.name, zip_code : this.state.zipCode, county_id: this.state.county_id, zone_id : this.state.zone_id};
        console.log("Render County Value Get: ", document.getElementById('r_county'));
        //console.log("Render County Value : ", this.form1.current.value );
        let valid = true;

        if (this.state.county_id === ''){
            valid = false;
            this.setState({county_id_fail : true});
            console.log("COUNTY FAIl")
        }

        if (this.state.name === ''){
            valid = false;
            this.setState({name_fail : true});
        }       
        
        
        if (valid) {

            console.log('City Body To save : ',city);
            City.addCity(city)
                .then(res => {
                    
                    this.setState({message : 'City Added successfully.'});
                    this.props.history.push('/cities');
                });

            
        
        }
    }

    cancelAdd() {
        this.props.history.push('/cities');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({[e.target.name + '_fail'] : false});


    }    

    render() {
        return(
            <div>
                <h6 className="text-center">Create New City</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">City Name</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    invalid = {this.state.name_fail}
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                    <FormFeedback>Please, Fill this Field</FormFeedback>
                                </Col>
                                

                                <Col md="6" className="form-group">
                                    <label htmlFor="txtState">State</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = ""
                                    placeholder=""
                                    disabled
                                    value="Florida" onChange={this.onChange}
                                    />
                                </Col>    

                                <Col md="3" className="form-group">
                                    <label htmlFor="txtZipCode">Zip Code</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "zipCode"
                                    placeholder=""
                                    value={this.state.zipCode} onChange={this.onChange}
                                    />
                                </Col>                                                            
                                
                             {/*   <Col md="9" className="form-group">
                                   <label htmlFor="cboCounty">Select County</label>
                                    <ListCombo id="r_county" name="form1" ref={this.form1} />                              
        </Col> */}
                             <Col md="9" className="form-group">
                                <label htmlFor="cboRace">Select County</label>
                                <FormSelect value={this.state.county_id} name="county_id" onChange={this.onChange} invalid = {this.state.county_id_fail}>
                                       <option key="xx" value="xx" ></option>
                                        {

                                    
                                                this.state.counties.map(
                                            county =>

                                                    <option key={county.id} value={county.id} >{county.name}</option>

                                                )
                                                
                                        }
                                </FormSelect> 
                                <FormFeedback>Please, Fill this Field</FormFeedback> 

                                </Col>                               
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveCity}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddCityComponent;