import County from "../api/County";

class CountyService {

    constructor(props) {
        
        this.state = {
            counties: [],
            message: null
        }
    }


    fetchCountiesList() {
        County.fetchCounties()
            .then((res) => {
                console.log('Size REsult Service County : ',res);
                this.setState({counties: res.data.counties});
                
            });  
    }

    getCountiesList(){
        return this.state.counties;
    }




}

export default new CountyService();