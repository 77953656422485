import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form } from "shards-react";
import City from "../../api/City";

class ListCityComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cities: [],
            message: null,
            modalDelete : false,
            delcity : {}
        }
        this.delete = this.deleteCity.bind(this);
        this.editCity = this.editCity.bind(this);
        this.addCity = this.addCity.bind(this);
        this.reloadCityList = this.reloadCityList.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    componentDidMount() {
        this.reloadCityList();
    }



    reloadCityList() {
        City.fetchCities()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({cities: res.data.result});

            });
    }

    loadCity(cityid) {
        this.setState({modalDelete : !this.state.modalDelete});
        City.fetchCityById(cityid)
            .then((res) => {
                let city = res.data.result;
                console.log("City TO EDIT " , res);
                this.setState({delcity : city})
            });

            console.log("CITY STATE : " , this.state )
    }    


    deleteCity() {
        let dcity = {id : this.state.delcity.id, name: this.state.delcity.name, description: this.state.delcity.name,zip_code: this.state.delcity.zipCode, county_id: this.state.delcity.county.id, status : 'DELETE'};
        City.deleteCity(dcity)
           .then(res => {
               this.setState({message : 'city deleted successfully.'});
               this.setState({cities: this.state.cities.filter(city => city.id !== dcity.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    }    

    editCity(id) {
        window.localStorage.setItem("cityId", id);
        this.props.history.push('/edit-city');
    }

    addCity() {
        window.localStorage.removeItem("cityId");
        this.props.history.push('/add-city');
    }

    render() {
        const deleteMd = this.state.modalDelete;
        //console.log('Size cities : ' + this.state.cities.length);
        return (
            <div>

                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deleteCity()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div> 



              <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addCity()}>
                    <i className="material-icons">add</i> New City
                    </Button>
              </Col> 


              <div>
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">City Description</th>
                            <th scope="col" className="border-0">Zip Code</th>
                            <th scope="col" className="border-0">County</th>
                            <th scope="col" className="border-0">Action</th>
                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        {
                            this.state.cities.map(
                        city =>
                                    <tr key={city.id}>
                                        <td>{city.name}</td>
                                        <td>{city.zipCode}</td>
                                        <td>{city.county.name}</td>
                                         <td>
                                            
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.loadCity(city.id)}>
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>                                            
                                            
                                            <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editCity(city.id)}>
                                                <i className="material-icons">&#xE254;</i>
                                            </Button>                                           
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                  
                </table>
                </div>

            </div>
        );
    }

}

export default ListCityComponent;