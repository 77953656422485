import React, { Component } from 'react'
import { Button, Col, Row, FormInput, Form, Card,CardBody,CardFooter,FormGroup,FormCheckbox, FormFeedback } from "shards-react";
import { Link } from "react-router-dom";
import ApiRegister from "../../api/Register";
import AlertDanger from "../alert/Danger";


class RegisterComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
            description: '',
            email : '',
            errors : {email : false, pass1 : false, pass2 : false}, 
            goodFields : {email : false, pass1 : false, pass2 : false},
            pass1 : '',
            pass2 : '',
            error : false,
            errMsg : '' 
          
        }
        this.registerAccount = this.registerAccount.bind(this);
    }


    registerAccount() {
        let emailField = this.state.email;
        let pass1Field = this.state.pass1;
        let pass2Field = this.state.pass2;
        let formIsValid = true;
        if(typeof emailField !== "undefined"){
            let lastAtPos = emailField.lastIndexOf('@');
            let lastDotPos = emailField.lastIndexOf('.');
 
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && emailField.indexOf('@@') == -1 && lastDotPos > 2 && (emailField.length - lastDotPos) > 2)) {
               formIsValid = false;
               this.setState({errors : {email : true }});
            }else
            {
               this.setState({goodFields : {email : true }});               
            }
        } 


        if (pass1Field.length < 6){
            formIsValid = false;
            this.setState({errors : {pass1 : true }});
            //this.setState({errors : {pass2 : true }});
        }

        
        if (formIsValid){

            if (pass1Field !== pass2Field){
                this.setState({errors : {pass1 : true }});
                this.setState({errors : {pass2 : true }});
                window.localStorage.setItem("errorMsg","Passwords must be the same");
            // this.setState({error : true});  Show just cuando retorne respuesta de la API si hay algun error registrando
                formIsValid = false;
            }else{
                this.setState({goodFields : {pass1 : true }});   
                this.setState({goodFields : {pass2 : true }});   
                                
            }

        }




        if (formIsValid) {

            let user = {username : this.state.email, email : this.state.email, password : this.state.pass2};
            ApiRegister.addUser(user)
            .then(res => {
                this.props.history.push('/verify-account');
                window.localStorage.setItem("username",this.state.email);
            });
            
        }
        
        
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({error : false});

        if (e.target.name === 'email') {
            this.setState({errors : {email : false }});
            this.setState({goodFields : {email : false }});
        }  
        
        if (e.target.name === 'pass1' || e.target.name === 'pass2') {
            this.setState({errors : {pass1 : false }});
            this.setState({errors : {pass2 : false }});
            this.setState({goodFields : {pass1 : false }});
            this.setState({goodFields : {pass2 : false }});
        }
        

    }    
        

    render() {
        const vshow = this.state.error;
        return(

            <div>

                    { vshow &&
                        <div>
                           <AlertDanger open={this.state.error} />
                        </div>
                    }

               

                    <Row noGutters className="h-100">
                    <Col lg="3" md="5" className="auth-form mx-auto my-auto">
                        <Card>
                        <CardBody>
                            {/* Logo */}
                            <img
                            className="auth-form__logo d-table mx-auto mb-3"
                            src = {require("./../../images/Logo.jpeg")}
                            alt="Pet Care - Register Account"
                            />

                            {/* Title */}
                            <h5 className="auth-form__title text-center mb-4">
                            Create New Account
                            </h5>

                            {/* Form Fields */}
                            <Form validate>
                            <FormGroup>
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <FormInput
                                type="email"
                                id="exampleInputEmail1"
                                placeholder="Enter email"
                                name = "email"
                                valid = {this.state.goodFields.email}
                                invalid = {this.state.errors.email}
                                value = {this.state.email} onChange={this.onChange}
                                autoComplete="email"
                                />
                                <FormFeedback valid>The Email looks good!</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="exampleInputPassword1">Password</label>
                                <FormInput
                                type="password"
                                id="exampleInputPassword1"
                                placeholder="Password"
                                value = {this.state.pass1}
                                name = "pass1"
                                valid = {this.state.goodFields.pass1}
                                invalid = {this.state.errors.pass1}
                                autoComplete="new-password" onChange={this.onChange}
                                />
                                <FormFeedback>Password length must be greater than 5 Characters</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="exampleInputPassword2">Repeat Password</label>
                                <FormInput
                                type="password"
                                id="exampleInputPassword2"
                                placeholder="Repeat Password"
                                value = {this.state.pass2}
                                name = "pass2"
                                valid = {this.state.goodFields.pass2}
                                invalid = {this.state.errors.pass2}
                                autoComplete="new-password" onChange={this.onChange}
                                />
                                <FormFeedback>Password does not match</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <FormCheckbox>
                                I agree with the <a href="#">Terms & Conditions</a>.
                                </FormCheckbox>
                            </FormGroup>
                            <Button
                                pill
                                theme="accent"
                                className="d-table mx-auto"
                                type="button"
                                onClick={() => this.registerAccount()}
                            >
                                Create Account
                            </Button>
                            </Form>
                        </CardBody>

                        {/* Social Icons */}
                        <CardFooter>
                            <ul className="auth-form__social-icons d-table mx-auto">
                            <li>
                                <a href="#">
                                <i className="fab fa-facebook-f" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <i className="fab fa-twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <i className="fab fa-github" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                <i className="fab fa-google-plus-g" />
                                </a>
                            </li>
                            </ul>
                        </CardFooter>
                        </Card>

                        {/* Meta Details */}
                        <div className="auth-form__meta d-flex mt-4">
                        <Link to="/forgot-password">Forgot your password?</Link>
                        <Link to="/login" className="ml-auto">
                            Sign In?
                        </Link>
                        </div>
                    </Col>
                    </Row>




   
            </div>
        );
    }
}

export default RegisterComponent;