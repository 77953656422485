import React, { Component } from 'react'
import ApiEmployee from "../../api/Employee";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form , FormSelect} from "shards-react";

class AddEmpComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
            sureName: '',
            lasName: '',
            admissionDate : '',
            employeeType_id : '',
            employeeTypes : []

        }
        this.saveEmployee = this.saveEmployee.bind(this);
    }


    componentDidMount() {
        this.reloadTypeEmployeeList();
    }

    reloadTypeEmployeeList() {
    
        ApiEmployee.fetchTypeEmployees()
            .then((res) => {
                console.log('Size REsult Type Employee : ', res);
                this.setState({employeeTypes: res.data.result});
                 

            });
    }  



    saveEmployee = (e) => {
        e.preventDefault();    
        //let adDate =    this.state.admissionDate.getFullYear();
        //console.log('YEAR : ',adDate );                               
        let employee = {sure_name: this.state.sureName, last_name: this.state.lastName, admission_date : this.state.admissionDate,  employee_type_id: this.state.employeeType_id,
                        status : 'ACTIVE' };
        console.log('EMPLOYEE TO SAVE : ', employee);
        ApiEmployee.addEmployee(employee)
            .then(res => {
                this.setState({message : 'employee Added successfully.'});
                this.props.history.push('/employees');
            });
    }

    cancelAdd() {
        this.props.history.push('/employees');
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    render() {
        return(
            <div>
                <h6 className="text-center">New employee</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="4" className="form-group">
                                    <label htmlFor="sureName">Sure Name</label>
                                    <FormInput
                                    id="txtSureName"
                                    type="text"
                                    name = "sureName"
                                    placeholder="Sure Name"
                                    value={this.state.sureName} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="4" className="form-group">
                                    <label htmlFor="lastName">Sure Name</label>
                                    <FormInput
                                    id="txtlastName"
                                    type="text"
                                    name = "lastName"
                                    placeholder="Last Name"
                                    value={this.state.lastName} onChange={this.onChange}
                                    />
                                </Col>  

                                <Col md="4" className="form-group">
                                    <label htmlFor="admissionDate">Admission Date</label>
                                    <FormInput
                                    id="txtadmissionDate"
                                    type="date"
                                    name = "admissionDate"
                                    placeholder="Admission Date"
                                    value={this.state.admissionDate} onChange={this.onChange}
                                    />
                                </Col>


                                <Col md="4" className="form-group">
                                    <label htmlFor="cboRace">Employee Type</label>
                                    <FormSelect value={this.state.employeeType_id} name="employeeType_id" onChange={this.onChange}>
                                           <option key="xx" value="xx" ></option>
                                            {
                                        
                                                this.state.employeeTypes.map(
                                                empType =>

                                                        <option key={empType.id} value={empType.id} >{empType.description}</option>

                                                    )
                                                    
                                            }
                                    </FormSelect> 
                                </Col>                                
                                                                                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveEmployee}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddEmpComponent;