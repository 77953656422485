import React, { Component } from 'react'
import ApiCity from "../../api/City";
import ListCombo from  "../county/ListCombo"
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect  } from "shards-react";
import ApiCounty from "../../api/County";

class EditCityComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            id: '',
            name:'',
            description:'',
            zipCode:'',
            county_id:'',
            counties:[]
        }
        this.saveCity = this.saveCity.bind(this);
        this.loadCity = this.loadCity.bind(this);
    }

    componentDidMount() {
        this.loadCity();
        this.countiesList();
    }

    
    countiesList() {
        ApiCounty.fetchCounties()
            .then((res) => {
                //console.log('Size REsult : ',res);
                this.setState({counties: res.data.counties});

            });
    }  
    

    loadCity() {
        
        ApiCity.fetchCityById(window.localStorage.getItem("cityId"))
            .then((res) => {
                let city = res.data.result;
                console.log("City TO EDIT " , res);
                this.setState({
                id: city.id,
                name: city.name,
                description: city.description,
                zipCode: city.zipCode,
                county_id: city.county.id              
                })
            });

            console.log("CITY STATE : " , this.state )
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    saveCity = (e) => {
        e.preventDefault();
        let City = {id : this.state.id, name: this.state.name, description: this.state.name,zip_code: this.state.zipCode, county_id: this.state.county_id, status : 'active'};
        ApiCity.editCity(City)
            .then(res => {
                  this.props.history.push('/Cities');
            });


    }

    cancelAdd() {
        this.props.history.push('/Cities');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit City</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">City Name</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="6" className="form-group">
                                    <label htmlFor="txtState">State</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = ""
                                    placeholder=""
                                    disabled
                                    value="Florida" onChange={this.onChange}
                                    />
                                </Col>    

                                <Col md="3" className="form-group">
                                    <label htmlFor="txtZipCode">Zip Code</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "zipCode"
                                    placeholder=""
                                    value={this.state.zipCode} onChange={this.onChange}
                                    />
                                </Col>                                                            
                                


                                <Col md="9" className="form-group">
 
                                <FormSelect value={this.state.county_id} name="county_id" onChange={this.onChange}>
                                        {
                                    
                                                this.state.counties.map(
                                            county =>

                                                    <option key={county.id} value={county.id} >{county.name}</option>

                                                )
                                                
                                        }
                                </FormSelect>   

                                </Col>                              
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveCity}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditCityComponent;