import React, { Component } from 'react'
import ApiEmployee from "../../api/Employee";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form , FormSelect} from "shards-react";

class EditEmpComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            id : '',
            sureName: '',
            lasName: '',
            admissionDate : '',
            employeeType_id : '',
            employeeTypes : []
        }
        this.saveEmployee = this.saveEmployee.bind(this);
        this.loadEmployee = this.loadEmployee.bind(this);
    }

    componentDidMount() {
        this.loadEmployee();
        this.reloadTypeEmployeeList();
    }

    reloadTypeEmployeeList() {
    
        ApiEmployee.fetchTypeEmployees()
            .then((res) => {
                console.log('Size REsult Type Employee : ', res);
                this.setState({employeeTypes: res.data.result});
                 

            });
    }    

    loadEmployee() {
        ApiEmployee.fetchEmployeeById(window.localStorage.getItem("employeeId"))
            .then((res) => {
                let employee = res.data.result;
                console.log('EMPLOYEE : ' ,res);
                this.setState({
                id: employee.id,
                sureName: employee.sureName,
                lastName: employee.lastName,
                admissionDate : '2019-12-01',
                employeeType_id: employee.employeeType_id
                })
            });
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    saveEmployee = (e) => {
        e.preventDefault();
        let employee = {sureName: this.state.sureName, lastName: this.state.lastName, admissionDate : this.state.admissionDate,  employeeType_id: this.state.employeeType_id};
        ApiEmployee.editEmployee(employee)
            .then(res => {
                  this.props.history.push('/employees');
            });


    }

    cancelAdd() {
        this.props.history.push('/employees');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit employee</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="4" className="form-group">
                                    <label htmlFor="sureName">Sure Name</label>
                                    <FormInput
                                    id="txtSureName"
                                    type="text"
                                    name = "sureName"
                                    placeholder="Sure Name"
                                    value={this.state.sureName} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="4" className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <FormInput
                                    id="txtlastName"
                                    type="text"
                                    name = "sureName"
                                    placeholder="Last Name"
                                    value={this.state.lastName} onChange={this.onChange}
                                    />
                                </Col>  

                                <Col md="4" className="form-group">
                                    <label htmlFor="admissionDate">Admission Date</label>
                                    <FormInput
                                    id="txtadmissionDate"
                                    type="date"
                                    name = "admissionDate"
                                    placeholder="Admission Date"
                                    value={this.state.admissionDate} onChange={this.onChange}
                                    />
                                </Col>

                                <Col md="4" className="form-group">
                                    <label htmlFor="lastName">Admission Date</label>
                                    <FormSelect value={this.state.employeeType_id} name="employeeType_id" onChange={this.onChange}>
                                            {
                                        
                                                this.state.employeeTypes.map(
                                                empType =>

                                                        <option key={empType.id} value={empType.id} >{empType.description}</option>

                                                    )
                                                    
                                            }
                                    </FormSelect> 
                                </Col>


                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveemployee}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditEmpComponent;