import React, { Component } from 'react'
import { Button, Col , Modal, ModalBody, ModalHeader, Row, Form} from "shards-react";
import ApiPetRace from "../../api/PetRace";
import dateFormat from "dateformat";

class ListPetRaceComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            petRaces: [],
            message: null,
            delpetRace : [],
            modalDelete : false
        }
        this.deletePetRace = this.deletePetRace.bind(this);
        this.editPetRace = this.editPetRace.bind(this);
        this.addPetRace = this.addPetRace.bind(this);
        this.reloadPetRaceList = this.reloadPetRaceList.bind(this);
        this.loadPetRace = this.loadPetRace.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
    }

    componentDidMount() {
        this.reloadPetRaceList();
    }

    loadPetRace(raceId) {
        this.setState({modalDelete : !this.state.modalDelete});
        ApiPetRace.fetchPetRaceById(raceId)
            .then((res) => {
                let petRace = res.data.result;
                console.log("petRace TO EDIT " , petRace);
                this.setState({delpetRace : petRace})
            });
    }



    reloadPetRaceList() {
        console.log('Serching for pet races');
        ApiPetRace.fetchPetRaces()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({petRaces: res.data.result});

            });
    }


    deletePetRace() {
        
        console.log('DELETE : ' , this.state)
        let race = {id : this.state.delpetRace.id, name : this.state.delpetRace.name, description : this.state.delpetRace.description, pet_type : this.state.delpetRace.pet_type, status : 'delete'}
        ApiPetRace.deletePetRace(race)
           .then(res => {
               this.setState({message : 'petRace deleted successfully.'});
               this.setState({petRaces: this.state.petRaces.filter(petRace => petRace.id !== race.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    }

    editPetRace(id) {
        window.localStorage.setItem("petRaceId", id);
        this.props.history.push('/edit-petRace');
    }

    addPetRace() {
        window.localStorage.removeItem("petRaceId");
        this.props.history.push('/add-petRace');
    }

    render() {
        //console.log('Size petRaces : ' + this.state.petRaces.length);
        const deleteMd = this.state.modalDelete;
        return (
            <div>




                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deletePetRace()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div>                 
                

              <div>

                <Col sm="4" className="d-flex ml-auto my-auto">
                    <Button className="mx-auto ml-sm-auto mr-sm-0"  onClick={() => this.addPetRace()}>
                    <i className="material-icons">add</i> New petRace
                    </Button>
                </Col>                 
                <table className="table mb-0">
                    <thead className="bg-light"> 
                        <tr>
                            
                            <th scope="col" className="border-0">Description</th>
                            <th scope="col" className="border-0">Created Date</th>
                            <th scope="col" className="border-0">Pet Type</th>
                            <th scope="col" className="border-0">Action</th>
           
                        </tr>
                    </thead>
                    
                   
                    <tbody>
                        {
                            this.state.petRaces.map(
                        petRace =>
                                    <tr key={petRace.id}>
                                        <td>{petRace.description}</td>
                                        <td>{dateFormat(new Date(petRace.createdAt), "dddd, mmmm dS, yyyy")}</td>
                                        
                                        <td>{petRace.petType}</td>
     
                                         <td>
                                            
                                            <Button outline size="sm" theme="danger" className="mb-2 mr-1" onClick={() => this.loadPetRace(petRace.id)}>
                                                <i className="material-icons">&#xE872;</i>
                                            </Button>                                            
                                            
                                            <Button outline size="sm" theme="info" className="mb-2 mr-1" onClick={() => this.editPetRace(petRace.id)}>
                                                <i className="material-icons">&#xE254;</i>
                                            </Button>                                           
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                  
                </table>
                </div>

            </div>
        );
    }

}

export default ListPetRaceComponent;