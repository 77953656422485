export default () => [
 {
        id: 0,
        name: ""
 },
 {
    id: 1,
    name: "MONDAY"
  },

  {
    id: 2,
    name: "TUESDAY"
  },

  {
    id: 3,
    name: "Wednesday"
  },

  {
    id: 4,
    name: "Thursday"
  },

  {
    id: 5,
    name: "Friday"
  },
  {
    id: 6,
    name: "Saturday"
  }
];
