import axios from 'axios'
import AuthenticationService from "../components/authentication/AuthenticationService"


const Conn = axios.create({ baseUrl : "http://localhost:8080"})

    Conn.interceptors.request.use(
        (config) => {
            console.log('1 - ENter to Interceptor')
            if (AuthenticationService.isUserLoggedIn()) {
                let token = sessionStorage.getItem("authToken");
                console.log('Getting Interceptor', token)
                config.headers.authorization = token
            }
            return config
        }
    )

export default Conn


