import React, { Component } from 'react'
import FuzzySearch from "fuzzy-search";
import dateFormat from "dateformat";
import { Button, Col, Row, FormInput, Form, Card,CardBody,Nav,NavItem,FormTextarea,
         InputGroupAddon, InputGroupText, FormCheckbox, CardFooter, NavLink, InputGroup,ButtonGroup } from "shards-react";
import Script from 'react-load-script';
import { Link } from "react-router-dom";
import FormSectionTitle from "../edit-user-profile/FormSectionTitle";
import ProfileBackgroundPhoto from "../edit-user-profile/ProfileBackgroundPhoto";
import ApiCustomer from "../../api/Customer";



class EditProfileClientComponent extends Component{

    constructor(props){
        super(props);
        //this.autocomplete = null;
        this.state ={
            description: '',
            sure_name : '',
            last_name : '',
            customer_number : '',
            cell_phone : '',
            mobil_phome : '',
            email : '',
            pageSizeOptions: [5, 10, 15, 20, 25, 30],
            tableData: [],
            userDetails: {
                name: "",
                avatar: require("./../../images/avatars/user.png"),
                jobTitle: "",
                performanceReportTitle: "",
                performanceReportValue: 0,
                metaTitle: "Description",
                metaValue:
                  "Welcome to Classic Dog Pet Care - Information Contat us to Phone: +1 786 602 74 48 / +1 786 505 04 71 "
            },
            address : {
                name: '',
                street_address: '',
                city: '',
                state: '',
                zip_code: '',
                googleMapLink: '',
                aptsuite : ''
            }

          
        }
        this.registerAccount = this.registerAccount.bind(this);
    }

    componentWillMount() {

    }    


    getCurrentCustomer() {

      ApiCustomer.fetchCustomerById(sessionStorage.getItem("idCustomer"))
          .then((res) => {
            let cust = res.data;
            console.log("CUSTOMER TO EDIT " , res);
            this.setState({
            id: cust.id,
            sure_name : cust.sure_name,
            last_name : cust.last_name,
            cell_phone : cust.cell_phone,
            mobil_phome : cust.mobil_phone        

            })
       });

    }


    registerAccount() {
        this.props.history.push('/verify-account');
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });


    handleScriptLoad = () => { 
            // Declare Options For Autocomplete 
            var options = { types: '(address)' , administrative_area_level_1 : '(FL)' }; 
            
            // Initialize Google Autocomplete 
            /*global google*/
            this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'),options );
            // Avoid paying for data that you don't need by restricting the 
            // set of place fields that are returned to just the address
            // components and formatted address
            this.autocomplete.setFields(['address_components',   
                                         'formatted_address']);
            // Fire Event when a suggested name is selected
            this.autocomplete.addListener('place_changed',
                                          this.handlePlaceSelect); 
            
            console.log('SCRIPT LOADED');                              
    }

   handlePlaceSelect = () => {

    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      console.log('ADDRESS SELECT  : ' , address )  
      // Set State
       let postal = null;
       if (address[6].types[0] === 'postal_code') {
            postal =  address[6].short_name
       }

       if (address[7].types[0] === 'postal_code') {
            postal =  address[7].short_name
       }

      this.setState({ address : {
        name: addressObject.name,
        street_address: address[0].long_name + ' ' + address[1].long_name,
        city: address[3].long_name,
        state: address[5].short_name,
        zip_code: postal 
        }
      });
    }
   }           

    render() {
          return(
            
            <div>
                
            <Script url="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDbrusHU90vO41gh6AJiiFb4LNxzpPQDJk" onLoad={this.handleScriptLoad} />
            <Row>
            <Col lg="8" className="mx-auto mt-4">
              <Card small className="edit-user-details mb-4">
                <ProfileBackgroundPhoto />

                <CardBody className="p-0">
                  <div className="border-bottom clearfix d-flex">
                    <Nav tabs className="border-0 mt-auto mx-4 pt-2">
                      <NavItem>
                        <NavLink active>General</NavLink>
                      </NavItem>

                    </Nav>
                  </div>

                  {/* Form Section Title :: General */}
                  <Form className="py-4" onSubmit={this.handleFormSubmit}>
                    <FormSectionTitle
                      title="General"
                      description="Setup your general profile details."
                    />

                    <Row form className="mx-4">
                      <Col lg="8">
                        <Row form>
                          {/* First Name */}
                          <Col md="6" className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <FormInput
                                      id="feFirstName"
                                      placeholder="First Name"
                                      name = "sure_name"
                                      value={this.state.sure_name}
                                      onChange={this.onChange}
                            />
                          </Col>

                          {/* Last Name */}
                          <Col md="6" className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <FormInput
                                        id="feLastName"
                                        placeholder="Last Name"
                                        value={this.state.last_name}
                                        name = "last_name"
                                        onChange={this.onChange}
                            />
                          </Col>

                          {/* Location */}
                          <Col md="6" className="form-group">
                            <label htmlFor="phoneNumber1">Phone Number</label>
                            <InputGroup seamless>
                              <InputGroupAddon type="prepend">
                                <InputGroupText>
                                  <i className="material-icons">&#xE0CD;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormInput
                                        type="cellphone"
                                        id="cellphone"
                                        placeholder="Cell Phone"
                                        value={this.state.cell_phone}
                                        name = "cell_phone"
                                        onChange={this.onChange}
                                        autoComplete="cell phone"
                              />
                            </InputGroup>
                          </Col>

                          {/* Phone Number */}
                          <Col md="6" className="form-group">
                            <label htmlFor="phoneNumber">Mobile Number</label>
                            <InputGroup seamless>
                              <InputGroupAddon type="prepend">
                                <InputGroupText>
                                  <i className="material-icons">&#xE0CD;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormInput
                                    type="homephone"
                                    id="homephone"
                                    placeholder="Home Phone"
                                    value={this.state.mobil_phone}
                                    name = "mobil_phone"
                                    onChange={this.onChange}
                                    autoComplete="Home Phone"
                              />
                            </InputGroup>
                          </Col>

                          {/* Email Address */}
                          <Col md="6" className="form-group">
                            <label htmlFor="emailAddress">Alternative Email</label>
                            <InputGroup seamless>
                              <InputGroupAddon type="prepend">
                                <InputGroupText>
                                  <i className="material-icons">&#xE0BE;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormInput
                                id="emailAddress"
                                value={this.state.email}
                                name = "email"
                                onChange={this.onChange}
                                onChange={() => {}}
                              />
                            </InputGroup>
                          </Col>


                        </Row>
                      </Col>

                      {/* User Profile Picture */}
                      <Col lg="4">
                        <label
                          htmlFor="userProfilePicture"
                          className="text-center w-100 mb-4"
                        >
                          Profile Picture
                        </label>
                        <div className="edit-user-details__avatar m-auto">
                          <img
                            src={require("./../../images/avatars/user.png")}
                            alt="User Avatar"
                          />
                          <label className="edit-user-details__avatar__change">
                            <i className="material-icons mr-1">&#xE439;</i>
                            <FormInput
                              id="userProfilePicture"
                              className="d-none"
                            />
                          </label>
                        </div>
                        <Button
                          size="sm"
                          theme="white"
                          className="d-table mx-auto mt-4"
                        >
                          <i className="material-icons">&#xE2C3;</i> Upload
                          Image
                        </Button>
                      </Col>
                    </Row>

                    <Row form className="mx-4">
                      {/* User Bio */}
                      <Col md="6" className="form-group">
                        <label htmlFor="userBio">Bio</label>
                        <FormTextarea
                          style={{ minHeight: "87px" }}
                          id="userBio"
                          value="I'm a design focused engineer."
                          onChange={() => {}}
                        />
                      </Col>


                    </Row>

                    <hr />

                    {/* Address Section */}

             



                    <hr />

                    {/* Form Section Title :: Notifications */}
                    <FormSectionTitle
                      title="Notifications"
                      description="Setup which notifications would you like to receive."
                    />

                    {/* Notifications :: Conversations */}
                    <Row form className="mx-4">
                      <Col
                        tag="label"
                        htmlFor="conversationsEmailsToggle"
                        className="col-form-label"
                      >
                        Text Message
                        <small className="text-muted form-text">
                          Sends notification text with updates for the
                          reservations, promotions..etc
                        </small>
                      </Col>
                      <Col className="d-flex">
                        <FormCheckbox
                          toggle
                          checked
                          className="ml-auto my-auto"
                          id="conversationsEmailsToggle"
                          onChange={() => {}}
                        />
                      </Col>
                    </Row>

                    {/* Notifications :: New Projects */}
                    <Row form className="mx-4">
                      <Col
                        tag="label"
                        htmlFor="newProjectsEmailsToggle"
                        className="col-form-label"
                      >
                        Emails
                        <small className="text-muted form-text">
                          Sends notification emails when you are received a new bill.
                        </small>
                      </Col>
                      <Col className="d-flex">
                        <FormCheckbox
                          toggle
                          className="ml-auto my-auto"
                          id="newProjectsEmailsToggle"
                          onChange={() => {}}
                        />
                      </Col>
                    </Row>



                  




                  </Form>
                </CardBody>
                <CardFooter className="border-top">
                  <Button
                    size="sm"
                    theme="accent"
                    className="ml-auto d-table mr-3"
                  >
                    Save Changes
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
            
            </div>
        );
    }
}

export default EditProfileClientComponent;