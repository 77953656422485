import React, { Component } from 'react'
import ApiPetRace from "../../api/PetRace";
import getPetTypes from "../../data/tableValues";
import { Button, Col, Row,ListGroupItem, ListGroup, FormSelect, FormInput, Form } from "shards-react";

class EditPetRaceComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
            description: '',
            petType_id : '',
            petTypes : [], 
            status : ''
        }
        this.savePetRace = this.savePetRace.bind(this);
        this.loadPetRace = this.loadPetRace.bind(this);
    }

    componentDidMount() {
        this.loadPetRace();
        const types = getPetTypes();
        
        this.state.petTypes = types;
        console.log('MOUNTING ' , this.state);
    }

    loadPetRace() {
        
        ApiPetRace.fetchPetRaceById(window.localStorage.getItem("petRaceId"))
            .then((res) => {
                let petRace = res.data.result;
                console.log("petRace TO EDIT " , res);
                this.setState({
                id: petRace.id,
                description: petRace.description,
                petType_id: petRace.petType
                })
            });
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    savePetRace = (e) => {
        e.preventDefault();
        let petRace = {id : this.state.id, description: this.state.description, pet_type: this.state.petType_id, status : 'active' };
        console.log('Record to Save : ', petRace);
        ApiPetRace.editpetRace(petRace)
            .then(res => {
                  this.props.history.push('/petrace');
            });


    }

    cancelAdd() {
        this.props.history.push('/petrace');
    }   

    render() {
        return (
            <div>
                <h2 className="text-center">Edit Pet Races</h2>
                <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="txtDescription">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder="petRace Description"
                                    value={this.state.description} onChange={this.onChange}
                                    />
                                </Col>


                                <Col md="9" className="form-group">

                                    <FormSelect value={this.state.petType_id} name="petType_id" onChange={this.onChange}>
                                            {
                                        
                                        this.state.petTypes.map(
                                                petType =>

                                                        <option key={petType.id} value={petType.id} >{petType.name}</option>

                                                    )
                                                    
                                            }
                                    </FormSelect>   

                                </Col>                                

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.savePetRace}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
            </div>
        );
    }
}

export default EditPetRaceComponent;