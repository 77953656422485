export default () => [{
    id: 0,
    start: "",
    end: "",
  },{
    id: 7,
    start: "7:00",
    end: "9:30  AM",
  },

  {
    id: 8,
    start: "8:00",
    end: "11:30  AM",
  },

  {
    id: 9,
    start: "9:00",
    end: "1:30  PM",
  },

  {
    id: 10,
    start: "10:00",
    end: "3:30  PM",
  },

  {
    id: 11,
    start: "11:00",
    end: "5:30  PM",
  },
  {
    id: 12,
    start: "12:00",
    end: "7:30  PM",
  },
  {
    id: 13,
    start: "13:00",
    end: "7:30  PM",
  },
  {
    id: 14,
    start: "14:00",
    end: "7:30  PM",
  },
  {
    id: 15,
    start: "15:00",
    end: "7:30  PM",
  },
  {
    id: 16,
    start: "16:00",
    end: "7:30  PM",
  },
  {
    id: 17,
    start: "17:00",
    end: "7:30  PM",
  },
  {
    id: 18,
    start: "18:00",
    end: "7:30  PM",
  },
  {
    id: 19,
    start: "19:00",
    end: "7:30  PM",
  },
  {
    id: 20,
    start: "20:00",
    end: "7:30  PM",
  },
  {
    id: 21,
    start: "21:00",
    end: "7:30  PM",
  },
  {
    id: 22,
    start: "22:00",
    end: "7:30  PM",
  },
  {
    id: 23,
    start: "23:00",
    end: "7:30  PM",
  }    
];
