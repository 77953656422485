import React, { Component } from 'react'
import ApiService from "../../api/Service";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form ,FormFeedback } from "shards-react";

class AddServiceComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
            descService     : '',
            descServie_fail : false,
            name : '',
            location_id: '',
            baseCost : '',
            baseCost_fail : false
        }
        this.saveService = this.saveService.bind(this);
    }

    saveService = (e) => {
        e.preventDefault();
        let loc = {id: 1, descLocation: null, createdDate: "2019-09-30T00:00:00", status: "delete"};  //Traer de la Api
        let service = {name: this.state.descService, description : this.state.descService,  base_cost : this.state.baseCost};
        console.log("TO SAVE : " ,  service )
        let valid = true;

        if (this.state.descService === ''){
                valid = false;
                this.setState({descService_fail : true })
        }

        if (this.state.baseCost == ''){
            valid = false;
            this.setState({baseCost_fail : true})
        }

        if (valid) {
            ApiService.addService(service)
            .then(res => {
                this.setState({message : 'Service Added successfully.'});
                this.props.history.push('/Services');
            });
        }
        


    }

    cancelAdd() {
        this.props.history.push('/Services');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({[e.target.name + '_fail'] : false});
    }    

    render() {
        return(
            <div>
                <h6 className="text-center">New Service</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "descService"
                                    placeholder="Service Description"
                                    value={this.state.descService} onChange={this.onChange}
                                    invalid = {this.state.descService_fail}
                                    />
                                    <FormFeedback>Please, Fill this Field</FormFeedback>
                                </Col>

                                <Col md="6" className="form-group">
                                    <label htmlFor="baseCost">Base Cost</label>
                                    <FormInput
                                    id="txtCost"
                                    type="number"
                                    name = "baseCost"
                                    placeholder="Base Cost"
                                    value={this.state.baseCost} onChange={this.onChange}
                                    invalid = {this.state.baseCost_fail}
                                    />
                                    <FormFeedback>Please, Fill this Field</FormFeedback>
                                </Col>                               

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveService}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddServiceComponent;