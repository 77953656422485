import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Form, InputGroupText,CardBody,InputGroup,InputGroupAddon,FormInput,Container ,
    CardHeader,FormSelect, Card, ButtonGroup} from "shards-react";
import FuzzySearch from "fuzzy-search";
import ApiAppoiment from "../../api/Appoiment";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import getTransactionHistoryData from "../../data/transaction-history-data";

class CreateBillComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reservations: [],
            message: null,
            modalDelete : false,
            delreservation : {},
            pageSizeOptions: [5, 10, 15, 20, 25, 30],
            pageSize: 10,
            tableData: []
        }
        this.delete = this.deletereservation.bind(this);
        this.editreservation = this.editreservation.bind(this);
        this.addreservation = this.addreservation.bind(this);
        this.reloadreservationList = this.reloadreservationList.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);

        this.searcher = null;

        this.getStatusClass = this.getStatusClass.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleFilterSearch = this.handleFilterSearch.bind(this);
        this.handleItemEdit = this.handleItemEdit.bind(this);
        this.handleItemDelete = this.handleItemDelete.bind(this);
        this.handleItemConfirm = this.handleItemConfirm.bind(this);
        this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
    }

    componentDidMount() {
        this.reloadreservationList();
        const tableData_1 = getTransactionHistoryData();
        const tableData =  tableData_1.filter(app => app.status === 'Complete');
        //this.setState({cities: this.state.cities.filter(city => city.id !== dcity.id)});

        this.setState({
          ...this.state,
          tableData
        });
    
        // Initialize the fuzzy searcher.
        this.searcher = new FuzzySearch(tableData, ["customer", "status"], {
          caseSensitive: false
        });
    }



    reloadreservationList() {
        ApiAppoiment.fetchAppoimentsByCustomer()
            .then((res) => {
                console.log('Size REsult : ',res);
                this.setState({tableData: res.data.result});

            });
    }

    loadreservation(reservationid) {
        this.setState({modalDelete : !this.state.modalDelete});
        ApiAppoiment.fetchreservationById(reservationid)
            .then((res) => {
                let reservation = res.data.result;
                console.log("reservation TO EDIT " , res);
                this.setState({delreservation : reservation})
            });

            console.log("reservation STATE : " , this.state )
    }    


    deletereservation() {
                                                                   
        let dreservation = {id : this.state.delreservation.id, name: this.state.delreservation.name, description: this.state.delreservation.name,date_of_birth : this.state.delreservationdate_of_birth, height : this.state.delreservation.height, weight : this.state.delreservation.weight, 
            race_id : this.state.delreservation.race_id, customer_id : this.state.delreservation.customer_id, status : 'INACTIVE'};
            ApiAppoiment.deletereservation(dreservation)
           .then(res => {
               this.setState({message : 'reservation deleted successfully.'});
               this.setState({reservations: this.state.reservations.filter(reservation => reservation.id !== dreservation.id)});
               this.setState({modalDelete : !this.state.modalDelete});
           })

    }

    cancelDelete() {
        this.setState({modalDelete : !this.state.modalDelete});
    }    

    editreservation(id) {
        window.localStorage.setItem("reservationId", id);
        this.props.history.push('/edit-reservation');
    }

    addreservation() {
        window.localStorage.removeItem("reservationId");
        this.props.history.push('/add-reservation');
    }

    getStatusClass(status) {
        const statusMap = {
          Cancelled: "danger",
          Complete: "success",
          Pending: "warning"
        };
    
        return `text-${statusMap[status]}`;
    }

    /**
     * Handles the page size change event.
     */
    handlePageSizeChange(e) {
        this.setState({
        ...this.state,
        pageSize: e.target.value
        });
    }

    /**
     * Handles the global search.
     */
    handleFilterSearch(e) {
        this.setState({
        ...this.state,
        tableData: this.searcher.search(e.target.value)
        });
    }

    /**
     * Mock method for editing transactions.
     */
    handleItemEdit(row) {
        alert(`Editing transaction "${row.original.id}"!`);
    }

    /**
     * Mock method for deleting transactions.
     */
    handleItemDelete(row) {
        alert(`Deleting transaction "${row.original.id}"!`);
    }

    /**
     * Mock method for confirming transactions.
     */
    handleItemConfirm(row) {
        alert(`Confirming transaction "${row.original.id}"!`);
    }

    /**
     * Mock method for confirming transactions.
     */
    handleItemViewDetails(row) {
        alert(`Viewing details for "${row.original.id}"!`);
    }


    render() {
        const { tableData, pageSize, pageSizeOptions } = this.state;
        const deleteMd = this.state.modalDelete;
        const tableColumns = [
            {
              Header: "#",
              accessor: "id",
              maxWidth: 60,
              className: "text-center"
            },
            {
              Header: "Date",
              accessor: "date",
              className: "text-center",
              minWidth: 200,
              Cell: row =>
                dateFormat(new Date(row.original.date), "dddd, mmmm dS, yyyy")
            },
            {
              Header: "Customer",
              accessor: "customer",
              className: "text-center"
            },
            {
              Header: "Order Number",
              accessor: "products",
              maxWidth: 100,
              className: "text-center"
            },
            {
                Header: "Total",
                accessor: "total",
                maxWidth: 100,
                Cell: row => <span className="text-success">{row.original.total}</span>,
                className: "text-center"
            },
            {
              Header: "Status",
              accessor: "status",
              maxWidth: 100,
              Cell: row => (
                <span className={this.getStatusClass(row.original.status)}>
                  {row.original.status}
                </span>
              ),
              className: "text-center"
            },
            {
              Header: "Actions",
              accessor: "actions",
              maxWidth: 300,
              minWidth: 180,
              sortable: false,
              Cell: row => (
                <ButtonGroup size="sm" className="d-table mx-auto">
                  <Button theme="success" onClick={() => this.handleItemConfirm(row)}>
                    <i className="material-icons">&#xE5CA;</i>
                  </Button>
                </ButtonGroup>
              )
            }
          ];
      
        //console.log('Size cities : ' + this.state.cities.length);
        return (
            <div>

                 {/* --------------------------- MODAL WINDOW TO INSERT SERVICE ---------------------------------------------------------------- */}
                 
                 <div>
                    <Modal open={deleteMd} toggle={this.addService}>
                        <ModalHeader>Delete</ModalHeader>
                        <ModalBody>                 
                                            <Form>
                                                    <Row>
                                                    
                                                    <Col md="9" className="form-group">  
                                                    <label htmlFor="cboCounty">Are you sure you want to delete this record..?</label>   
        
                                                    </Col>
            


                                                    </Row>
                                                      
                                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => this.deletereservation()}>
                                                            Confirm
                                                        </Button>  
                                                        <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelDelete()}>
                                                            Cancel
                                                        </Button>  

                                            </Form>                                                         


                        </ModalBody>
                    </Modal>
                </div> 


                <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
              />
            </div>
          </CardBody>
        </Card>


            </div>
        );
    }

}

export default CreateBillComponent;