import React, { Component } from 'react'
import ApiBranch from "../../api/Branch";
import { Button, Col, Row,ListGroupItem, ListGroup, FormInput, Form, FormSelect} from "shards-react";


class AddBranchComponent extends Component{

    constructor(props){
        super(props);
        this.form1 = React.createRef();
        
        this.state ={
            name : '',
            description : '',
            errors : {name : false, description : false}, 
            
        }
        this.saveBranch = this.saveBranch.bind(this);

    }

    componentDidMount() {

        
    } 
  


    saveBranch = (e) => {

        e.preventDefault();
        let d = new Date().getDate();
        let formIsValid = true; 
        let name_f = this.state.name;
        let description_f = this.state.description;


        if (typeof name_f === "undefined"){
            formIsValid = false;
            this.setState.errors({name : true})
        }  
        
        
        if (typeof description_f === "undefined"){
            formIsValid = false;
            this.setState.errors({name : true})
        }    


        if (formIsValid){
                
            let Branch = {name: this.state.name,  description: this.state.description };
            console.log("Branch TO SAVE : " ,  Branch);
            ApiBranch.addBranch(Branch)
                .then(res => {
                    
                    this.setState({message : 'Branch Added successfully.'});
                    this.props.history.push('/branch');
                });

        }    
    }

    cancelAdd() {
        this.props.history.push('/branch');
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });


    }   

    render() {
        return(
            <div>
                <h6 className="text-center">Add New Branch</h6>

                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Row form>
                                <Col md="9" className="form-group">
                                    <label htmlFor="feEmailAddress">Branch Name</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "name"
                                    placeholder=""
                                    required
                                    value={this.state.name} onChange={this.onChange}
                                    />
                                </Col>


                                <Col md="6" className="form-group">
                                    <label htmlFor="txtZipCode">Description</label>
                                    <FormInput
                                    id="txtDesc"
                                    type="text"
                                    name = "description"
                                    placeholder=""
                                    value={this.state.description} onChange={this.onChange}
                                    />
                                </Col> 
                                                                            
                                
                         
                            

                               </Row>

                                <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={this.saveBranch}>
                                    Save
                                </Button>  
                                <Button outline size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => this.cancelAdd()}>
                                    Cancel
                                </Button>  
                            </Form>
                            </Col>
                        </Row>
                        </ListGroupItem>
                    </ListGroup>
              

   
            </div>
        );
    }
}

export default AddBranchComponent;