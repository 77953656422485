import React, { Component } from 'react'
import { Button, Col, Row, FormInput, Form, Card,FormGroup,ListGroup,ListGroupItem,CardHeader,Progress,FormSelect,FormTextarea} from "shards-react";
import PageTitle from "../common/PageTitle";
import Script from 'react-load-script';
import { Route, Redirect } from 'react-router-dom'
import ApiCustomer from "../../api/Customer";
import AlertSuccess from "../alert/Success";
import ApiCity from "../../api/City";


class ProfileClientComponent extends Component{

    constructor(props){
        super(props);
        //this.autocomplete = null;
        this.state ={
            test : '',
            description: '',
            sure_name : '',
            last_name : '',
            customer_number : '',
            cell_phone : '',
            mobil_phome : '',
            status : '',
            aptsuite : '',
            userDetails: {
                name: "",
                avatar: require("./../../images/avatars/user.png"),
                jobTitle: "",
                performanceReportTitle: "",
                performanceReportValue: 0,
                metaTitle: "Description",
                metaValue:
                  "Welcome to Classic Dog Pet Care - Information Contat us to Phone: +1 786 602 74 48 / +1 786 505 04 71 "
            },
            name: '',
            street_address: '',
            route: '',
            city: '',
            state: '',
            zip_code: '',
            googleMapLink: '', 
            successMsg :  false,
            cities : []
            
         
        }
        this.saveChanges = this.saveChanges.bind(this); 
        this.reloadCityList = this.reloadCityList.bind(this);
    }

    componentDidMount() {

        this.reloadCityList();
        

    }


    reloadCityList() {
        ApiCity.fetchCities()
            .then((res) => {
               console.log('Cities Available : ',res);
               this.setState({cities: res.data.result});

            });
    }


    saveChanges() {
        
        const citiesArr = this.state.cities;
        let formIsValid = false; 
        console.log('THIS STATE CITY', this.state.city);
        this.setState({cities : this.state.cities.filter(c => c.name == this.state.city)});

        for (const [index, value] of citiesArr.entries()) {
            
            console.log('ARRAY ' , value);
            if (value.name === this.state.city){
                console.log("CITY FOUND : ", value.id) ;
                formIsValid = true; 

            }
        }

        
        console.log("CITY SELECT , " , this.state.cities)

        if (formIsValid){

            let customerAdd = {
                sure_name : this.state.sure_name,
                last_name : this.state.last_name,    
                customer_number : "XXX",   
                cell_phone : this.state.cell_phone,
                mobil_phone : this.state.mobil_phone,
                google_id : 'ZZXXXXX' ,    // This email must be deleted
                address : this.state.name,
                city_id : 1,
                zip_code: this.state.zip_code,  
                user_register_id : sessionStorage.getItem("idUSer"),  // Este Id lo tengo que capturar en el momento de login
                status : 'ACTIVE'               

            }
            

            let cust = {
                sure_name : this.state.sure_name,
                last_name : this.state.last_name,
                customer_number : "XXX",
                cell_phone : this.state.cell_phone,
                mobil_phone : this.state.mobil_phone,
                city_id : 1,
                google_id : 'ZZXXXXX' ,    // This email must be deleted
                address : this.state.name,
                zip_code: this.state.zip_code,
                status : "ACTIVE"}
            console.log("CUSTOMER TO SAVE : ", cust);
            
            let add = {
                name: this.state.name,
                street_address: this.state.street_address,
                city: this.state.city,
                state: this.state.state,
                zip_code: this.state.zip_code,  
                aptsuite : this.state.aptsuite             
            }
            console.log("THIS ADDRES TO SAVE :" , customerAdd);

            ApiCustomer.addCustomer(customerAdd)
            .then(res => {
                this.setState({successMsg : true});
                window.localStorage.setItem("successMsg","Register Completed Sucesfully");
                //this.props.history.push('/blog-overview');
            });
            

        }

        
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    gotoLogin() {
            //this.props.history.push('/appoiment/step1');
            return <Redirect to="/analytics" />
        }    


    handleScriptLoad = () => { 
            // Declare Options For Autocomplete 
            var options = { types: '(address)' , administrative_area_level_1 : '(FL)' }; 
            
            // Initialize Google Autocomplete 
            /*global google*/
            this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'),options );
            // Avoid paying for data that you don't need by restricting the 
            // set of place fields that are returned to just the address
            // components and formatted address
            this.autocomplete.setFields(['address_components',   
                                         'formatted_address']);
            // Fire Event when a suggested name is selected
            this.autocomplete.addListener('place_changed',
                                          this.handlePlaceSelect); 
            
        
                                          console.log('SCRIPT LOADED');                              
    }

   getAddressComp(addressObj) {

        let street_number = null;
        let route = null;
        let locality = null;
        let state = null;
        let postal_code = null;

        for(let i = 0; i < addressObj.length; i++){ 

           if (addressObj[i].types[0] === 'street_number') {
                street_number =  addressObj[i].short_name
           } 
 
           if (addressObj[i].types[0] === 'route') {
                route =  addressObj[i].short_name
           }    
           
           if (addressObj[i].types[0] === 'locality') {
                locality =  addressObj[i].short_name
           }  
           
           
           if (addressObj[i].types[0] === 'administrative_area_level_1') {
                state =  addressObj[i].short_name
           }   
           
           if (addressObj[i].types[0] === 'postal_code') {
                postal_code =  addressObj[i].short_name
           } 

        }

        this.setState({
            name: street_number + ' ' + route +  '  ' +  locality + ', ' +  state + ' ' + postal_code ,
            street_address: street_number + ' ' + route,
            city: locality,
            state: state,
            zip_code: postal_code 
            
          });        


   }

   handlePlaceSelect = () => {

    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      console.log('ADDRESS SELECT  : ' , address )  
      // Set State
     /*  let postal = null;
       if (address[6].types[0] === 'postal_code') {
            postal =  address[6].short_name
       }

       if (address.length > 7){

            if (address[7].types[0] === 'postal_code') {
                    postal =  address[7].short_name
            }
        }

      this.setState({
        name: address[0].long_name + ' ' + address[1].long_name +  '  ' +  address[3].long_name + ', ' +  address[5].short_name + ' ' + postal ,
        street_address: address[0].long_name + ' ' + address[1].long_name,
        city: address[3].long_name,
        state: address[5].short_name,
        zip_code: postal 
        
      });
      */
     this.getAddressComp(address);
    }
   }           

    render() {
        const vshow = this.state.successMsg;
        //const vshow = true;
        const valert = false;
        return(
            <div>

            { valert &&
                <div>
                    <AlertSuccess open={this.state.successMsg} />
                </div>
            }  

            {!vshow &&
            <div>  
                
            <Script url="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDbrusHU90vO41gh6AJiiFb4LNxzpPQDJk" onLoad={this.handleScriptLoad} />
            <Row noGutters className="page-header py-12">
      
                </Row>
                <Row>
                <Col lg="2">  {/* -----------------   User Account Details -----------------------------------------*/}
                <Card small className="mb-4 pt-3">
                        <CardHeader className="border-bottom text-center">
                        <div className="mb-3 mx-auto">
                            <img
                            className="rounded-circle"
                            src={this.state.userDetails.avatar}
                            alt={this.state.userDetails.name}
                            width="110"
                            />
                        </div>
                        <h4 className="mb-0">{this.state.userDetails.name}</h4>
                        <span className="text-muted d-block mb-2">{this.state.userDetails.jobTitle}</span>
                        <Button pill outline size="sm" className="mb-2">
                            <i className="material-icons mr-1">person_add</i> Change Picture
                        </Button>
                        </CardHeader>
                        <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <div className="progress-wrapper">
                            <strong className="text-muted d-block mb-2">
                                {this.state.userDetails.performanceReportTitle}
                            </strong>
                            <Progress
                                className="progress-sm"
                                value={this.state.userDetails.performanceReportValue}
                            >
                                <span className="progress-value">
                                {this.state.userDetails.performanceReportValue}%
                                </span>
                            </Progress>
                            </div>
                        </ListGroupItem>
                        <ListGroupItem className="p-4">
                            <strong className="text-muted d-block mb-2">
                            {this.state.userDetails.metaTitle}
                            </strong>
                            <span>{this.state.userDetails.metaValue}</span>
                        </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>


                <Col lg="8"> {/* -----------------   User Details -----------------------------------------*/}
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                        <h6 className="m-0">Account Details</h6>
                        </CardHeader>
                        <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                            <Col>
                                <Form>
                                <Row form>
                                    {/* First Name */}
                                    <Col md="6" className="form-group">
                                    <label htmlFor="feFirstName">First Name</label>
                                    <FormInput
                                        id="feFirstName"
                                        placeholder="First Name"
                                        name = "sure_name"
                                        value={this.state.sure_name}
                                        onChange={this.onChange}
                                    />
                                    </Col>
                                    {/* Last Name */}
                                    <Col md="6" className="form-group">
                                    <label htmlFor="feLastName">Last Name</label>
                                    <FormInput
                                        id="feLastName"
                                        placeholder="Last Name"
                                        value={this.state.last_name}
                                        name = "last_name"
                                        onChange={this.onChange}
                                    />
                                    </Col>
                                </Row>
                                <Row form>
                                    {/* Email */}
                                    <Col md="6" className="form-group">
                                    <label htmlFor="feEmail">Cell Phone</label>
                                    <FormInput
                                        type="cellphone"
                                        id="cellphone"
                                        placeholder="Cell Phone"
                                        value={this.state.cell_phone}
                                        name = "cell_phone"
                                        onChange={this.onChange}
                                        autoComplete="cell phone"
                                    />
                                    </Col>
                                    {/* Password */}
                                    <Col md="6" className="form-group">
                                    <label htmlFor="homephone">Home Phone</label>
                                    <FormInput
                                        type="homephone"
                                        id="homephone"
                                        placeholder="Home Phone"
                                        value={this.state.mobil_phone}
                                        name = "mobil_phone"
                                        onChange={this.onChange}
                                        autoComplete="Home Phone"
                                    />
                                    </Col>
                                </Row>
                                <FormGroup>
                                <input id="autocomplete" 
                                    placeholder="Search Address" 
                                    className="form-control" 
                                    name = "name"
                                    value={this.state.name}  
                                    onChange={this.onChange}
                                />

                                 </FormGroup>
                                <Row form>
                                    <Col md="10" className="form-group">
                                    <label htmlFor="stName">Street Name</label>
                                        <FormInput
                                        id="stName"
                                        placeholder="Street Name"
                                        name = "address.street_address"
                                        value = {this.state.street_address}
                                        disabled
                                        onChange={this.onChange}
                                        />  
                                    </Col> 
                                    <Col md="2" className="form-group">
                                    <label htmlFor="aptsuite">Apt / Suite</label>
                                        <FormInput
                                        id="sptsuite"
                                        placeholder="Apt / Suite"
                                        value = {this.state.aptsuite}
                                        name = "aptsuite"
                                        onChange={this.onChange}
                                        />  
                                    </Col>                                                                              
                                </Row>
                                <Row form>
                                    {/* City */}
                                    <Col md="6" className="form-group">
                                    <label htmlFor="feCity">City</label>
                                    <FormInput
                                        id="feCity"
                                        placeholder="City"
                                        name = "address.city"
                                        value = {this.state.city}
                                        onChange={this.onChange}
                                    />
                                    </Col>
                                    {/* State */}
                                    <Col md="4" className="form-group">
                                    <label htmlFor="feInputState">State</label>
                                    <FormInput
                                        id="feState"
                                        placeholder="State"
                                        name = "address.state"
                                        value = {this.state.state}
                                        onChange={this.onChange}
                                    />  
                                    </Col>
                                    {/* Zip Code */}
                                    <Col md="2" className="form-group">
                                    <label htmlFor="feZipCode">Zip</label>
                                    <FormInput
                                        id="feZipCode"
                                        name = "address.zip_code"
                                        placeholder="Zip"
                                        value = {this.state.zip_code}
                                        disabled
                                        onChange={this.onChange}
                                    />
                                    </Col>
                                </Row>
                                <Row form>
                                    {/* Description */}
                                    <Col md="12" className="form-group">
                                    <label htmlFor="feDescription">Notes</label>
                                    <FormTextarea id="feDescription" rows="5" />
                                    </Col>
                                </Row>
                                <Button theme="accent" type="button" onClick={() => this.saveChanges()}>Save</Button>
                                </Form>
                            </Col>
                            </Row>
                        </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>

            </div>
            }
            
            {vshow && 
                <div>
                    <Row noGutters className="page-header py-12">
                    <Col lg="12" md={{ size: 6, offset: 9 }}>
                        <div className="error">
                                <div className="error__content">
                                    <h2>Congratulations!</h2>
                                    <h3>Your Profile has been Completed</h3>
                                    <p>Now you can book you first appoiment, just click the button below </p>
                                    <Button pill onClick={() => this.gotoLogin()}>&larr; Book Appoiment</Button>
                                </div>
                        </div>
                    </Col>
                    </Row>                   
                    
                </div> 

            }
            
        </div>
        );
    }
}

export default ProfileClientComponent;