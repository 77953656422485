/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Button
} from "shards-react";

const UserActivity = ({ title }) => (
  <Card small className="user-activity mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
      <div className="block-handle" />
    </CardHeader>
    <CardBody className="p-0">
      {/* User Activity Icon */}
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          <i className="material-icons">&#xE7FE;</i>
        </div>
        <div className="user-activity__item__content">
          <span className="text-light">23 Minutes ago</span>
          <p>
            Update his picture <a href="#">Shards Dashboards</a> profile.
          </p>
        </div>
        <div className="user-activity__item__action ml-auto">
          <button className="ml-auto btn btn-sm btn-white">View Picture</button>
        </div>
      </div>
      {/* User Activity Icon */}
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          <i className="material-icons">&#xE5CA;</i>
        </div>
        <div className="user-activity__item__content">
          <span className="text-light">1 Day Ago</span>
          <p>
            Request 1 <a href="#">Appoiment</a> as{" "}
            <Badge outline pill theme="success">
              Reserve
            </Badge>{" "}
            inside the <a href="#">reservation module</a>.
          </p>
        </div>
      </div>
      {/* User Activity Icon */}
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          <i className="material-icons">&#xE7FE;</i>
        </div>
        <div className="user-activity__item__content">
          <span className="text-light">3 Hours and 10 minutes ago</span>
          <p>
            Added <a href="#">Cachirulo</a> to
            the <a href="#">Pets </a> List.
          </p>
        </div>
        <div className="user-activity__item__action ml-auto">
          <button className="ml-auto btn btn-sm btn-white">View Pet</button>
        </div>
      </div>
      {/* User Activity Icon */}
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          <i className="material-icons">&#xE868;</i>
        </div>
        <div className="user-activity__item__content">
          <span className="text-light">2 Days ago</span>
          <p>
            Report <a href="#">1 issues</a> in <a href="#">appoiment</a>.
          </p>
        </div>
      </div>

      {/* User Activity Icon */}
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          <i className="material-icons">&#xE5CD;</i>
        </div>
        <div className="user-activity__item__content">
          <span className="text-light">2 Days ago</span>
          <p>
            Marked <a href="#">appoiment</a> as{" "}
            <Badge outline pill theme="danger">
              Cancelled
            </Badge>{" "}
            inside the <a href="#">Reservation</a> module.
          </p>
        </div>
      </div>
    </CardBody>
    <CardFooter className="border-top">
      <Button size="sm" theme="white" className="d-table mx-auto">
        Load More
      </Button>
    </CardFooter>
  </Card>
);

UserActivity.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserActivity.defaultProps = {
  title: "User Activity"
};

export default UserActivity;
